import React from "react";
import styles from "./Modal.module.scss";
import CloseIcon from "assets/svg/close.svg";

interface Props {
  onClose: () => void;
}

const Modal: React.FC<Props> = props => {
  const { onClose, children } = props;

  return (
    <div className={styles.wrapper} onClick={() => onClose()}>
      <div
        className={styles.content}
        onClick={event => {
          event.stopPropagation();
        }}
      >
        <img
          className={styles.close}
          alt="Close"
          src={CloseIcon}
          onClick={() => onClose()}
        />
        <div className={styles.innerContent}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
