import React from "react";
import styles from "./Table.module.scss";
import ReactTable, { TableProps } from "react-table";
import "react-table/react-table.css";

const Table: React.FC<Partial<TableProps>> = props => {
  return (
    <div className={styles.wrapper}>
      <ReactTable {...props} showPageJump={false} />
    </div>
  );
};

export default Table;
