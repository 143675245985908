import { Action, ActionType } from "./types";
import { getSocketIOClient, getWebSocketClient } from "services/webSocket";
import { hotelApi } from "services/api";
import { Customer, Reservation } from "store/reservation";
import { analyticsLinkWifiBox, analyticsUnlinkWifiBox } from "services/google";

export const actionTypes = {
  WIFI_BOX_SUBSCRIBE: "wifiBox/WIFI_BOX_SUBSCRIBE",
  WIFI_BOX_UNSUBSCRIBE: "wifiBox/WIFI_BOX_UNSUBSCRIBE",
  WIFI_BOX_SET_FORM_RESERVATION: "wifiBox/WIFI_BOX_SET_FORM_RESERVATION",
  WIFI_BOX_SET_FORM_WIFI_BOX: "wifiBox/WIFI_BOX_SET_FORM_WIFI_BOX",
  WIFI_BOX_SET_FORM_BOX_NUMBER: "wifiBox/WIFI_BOX_SET_FORM_BOX_NUMBER",
  WIFI_BOX_SET_RESERVATION: "wifiBox/WIFI_BOX_SET_RESERVATION",
  WIFI_BOX_SET_WIFI_BOX: "wifiBox/WIFI_BOX_SET_WIFI_BOX",
  WIFI_BOX_LINK_PROCESSING: "wifiBox/WIFI_BOX_LINK_PROCESSING",
  WIFI_BOX_LINK_PROCESSED: "wifiBox/WIFI_BOX_LINK_PROCESSED",
  WIFI_BOX_LINK_ERROR: "wifiBox/WIFI_BOX_LINK_ERROR",
  WIFI_BOX_UNLINK_PROCESSING: "wifiBox/WIFI_BOX_UNLINK_PROCESSING",
  WIFI_BOX_UNLINK_PROCESSED: "wifiBox/WIFI_BOX_UNLINK_PROCESSED",
  WIFI_BOX_UNLINK_ERROR: "wifiBox/WIFI_BOX_UNLINK_ERROR",
  WIFI_BOX_ADD_PROCESSING: "wifiBox/WIFI_BOX_ADD_PROCESSING",
  WIFI_BOX_ADD_PROCESSED: "wifiBox/WIFI_BOX_ADD_PROCESSED",
  WIFI_BOX_ADD_ERROR: "wifiBox/WIFI_BOX_ADD_ERROR",
  WIFI_BOX_LOADING_TOKEN_INFO_PROCESSING:
    "wifiBox/WIFI_BOX_LOADING_TOKEN_INFO_PROCESSING",
  WIFI_BOX_LOADING_TOKEN_INFO_PROCESSED:
    "wifiBox/WIFI_BOX_LOADING_TOKEN_INFO_PROCESSED",
  WIFI_BOX_LOADING_TOKEN_INFO_ERROR:
    "wifiBox/WIFI_BOX_LOADING_TOKEN_INFO_ERROR",
  WIFI_BOX_GET_LIST: "wifiBox/WIFI_BOX_GET_LIST",
  WIFI_BOX_GET_LIST_ERROR: "wifiBox/WIFI_BOX_GET_LIST_ERROR",
  WIFI_BOX_SET_LIST: "wifiBox/WIFI_BOX_SET_LIST",
  WIFI_BOX_RESET: "wifiBox/WIFI_BOX_RESET",
  WIFI_BOX_RESET_FORM: "wifiBox/WIFI_BOX_RESET_FORM",
  WIFI_BOX_OPEN_MODAL: "wifiBox/WIFI_BOX_OPEN_MODAL",
  WIFI_BOX_CLOSE_MODAL: "wifiBox/WIFI_BOX_CLOSE_MODAL",
};

export interface WifiBoxActionTypes {
  subscribeToScanner: subscribeToScanner;
  unsubscribeFromScanner: unsubscribeFromScanner;
  getTokenInformation: getTokenInformation;
  setFormReservation: setFormReservation;
  setFormReservationById: setFormReservationById;
  setFormBoxNumber: setFormBoxNumber;
  setFormWifiBox: setFormWifiBox;
  link: link;
  unlink: unlink;
  add: add;
  getList: getList;
  reset: reset;
  resetForm: resetForm;
  openWifiBoxModal: openWifiBoxModal;
  closeWifiBoxModal: closeWifiBoxModal;
}

interface subscribeToScanner {
  (
    hotelCode: string,
    tokenIsNewWifiBox?: boolean,
    onTokenScanned?: null | (() => void)
  ): void;
}

interface unsubscribeFromScanner {
  (hotelCode: string): void;
}

interface getTokenInformation {
  (tokenId: string): void;
}

interface setFormReservation {
  (data: {
    value: string;
    reservation: Reservation;
    hub: string;
    customer: Customer;
  }): void;
}

interface setFormReservationById {
  (reservationId: string): void;
}

interface setFormBoxNumber {
  (value: string): void;
}

interface setFormWifiBox {
  (data: {
    value: string;
    boxNumber?: string;
    isLinked?: boolean;
    hub?: string;
  }): void;
}

interface link {
  (
    hostId: string,
    reservationId: string,
    boxNumber: string,
    boxValue: string
  ): void;
}

interface unlink {
  (wifiBox: string, boxValue: string, hostId: string): void;
}

interface add {
  (hostId: string, wifiBox: string, boxNumber: string): void;
}

interface getList {
  (): void;
}

interface reset {
  (): void;
}

interface resetForm {
  (): void;
}

interface openWifiBoxModal {
  (reservation: Reservation, customers: Customer[], hub: string): void;
}

interface closeWifiBoxModal {
  (): void;
}

export const subscribeToScanner: Action =
  (dispatch): subscribeToScanner =>
  (hotelCode, tokenIsNewWifiBox = false, onTokenScanned = null) => {
    const onMessage = (message: { TokenId?: string }) => {
      console.info("Scanned token", message);
      if (!("TokenId" in message)) {
        return;
      }

      if (typeof onTokenScanned === "function") {
        onTokenScanned();
      }

      if (tokenIsNewWifiBox) {
        setFormWifiBox(dispatch)({
          value: message.TokenId,
          boxNumber: null,
          isLinked: false,
        });
      } else {
        getTokenInformation(dispatch)(message.TokenId);
      }
    };
    // if (hotelCode === "CPH") {
    const socket = getWebSocketClient(hotelCode);
    socket.onmessage = (event) => {
      console.log("Received message from websocket", event);
      const message = JSON.parse(event.data);
      console.log("Passing message", message);
      onMessage(message);
    };
    // } else {
    //   const socket = getSocketIOClient();
    //   socket.on("Token", onMessage);
    // }
  };

export const unsubscribeFromScanner: Action =
  (dispatch): unsubscribeFromScanner =>
  (hotelCode) => {
    // if (hotelCode === "CPH") {
    const socket = getWebSocketClient(hotelCode);
    socket.close();
    // } else {
    //   const socket = getSocketIOClient();
    //   socket.off("Token");
    // }
  };

export const getTokenInformation: Action =
  (dispatch): getTokenInformation =>
  (tokenId) => {
    dispatch({
      type: actionTypes.WIFI_BOX_LOADING_TOKEN_INFO_PROCESSING,
    });

    hotelApi({
      path: `/reservation/token/?TokenId=${encodeURIComponent(tokenId)}`,
    })
      .then((response) => {
        if ("WifiBox" in response) {
          setFormWifiBox(dispatch)({
            value: response.WifiBox.TokenId,
            boxNumber: response.WifiBox.BoxNumber,
            isLinked: !!response.WifiBox.ReservationId,
            hub: response.WifiBox.Hub,
          });

          setFormBoxNumber(dispatch)(response.WifiBox.BoxNumber);

          if (response.WifiBox.Reservation && response.Customers) {
            setFormReservation(dispatch)({
              value: response.WifiBox.Reservation.Id,
              reservation: response.WifiBox.Reservation,
              hub: response.Hub,
              customer: response.Customers[0],
            });
          }
        } else if (
          "Customers" in response &&
          response.Customers.length > 0 &&
          "Reservations" in response &&
          response.Reservations.length > 0
        ) {
          setFormReservation(dispatch)({
            value: response.Reservations[0].Id,
            reservation: response.Reservations[0],
            hub: response.Hub,
            customer: response.Customers[0],
          });
        }

        dispatch({
          type: actionTypes.WIFI_BOX_LOADING_TOKEN_INFO_PROCESSED,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.WIFI_BOX_LOADING_TOKEN_INFO_ERROR,
          error,
        });
      });
  };

export const setFormReservationById: Action =
  (dispatch): setFormReservationById =>
  (reservationId) => {};

export const setFormReservation: Action =
  (dispatch): setFormReservation =>
  ({ value, reservation, hub, customer }) => {
    dispatch({
      type: actionTypes.WIFI_BOX_SET_FORM_RESERVATION,
      value,
    });

    dispatch({
      type: actionTypes.WIFI_BOX_SET_RESERVATION,
      reservation: {
        ...reservation,
        Hub: {
          Number: hub,
        },
      },
      customer: customer,
    });
  };

export const setFormBoxNumber: Action =
  (dispatch): setFormBoxNumber =>
  (value) => {
    dispatch({
      type: actionTypes.WIFI_BOX_SET_FORM_BOX_NUMBER,
      value,
    });
  };

export const setFormWifiBox: Action =
  (dispatch): setFormWifiBox =>
  ({ value, boxNumber, isLinked, hub }) => {
    dispatch({
      type: actionTypes.WIFI_BOX_SET_FORM_WIFI_BOX,
      value,
      isLinked,
    });

    const tags = [
      {
        key: "box-number",
        label: `WifiBox: ${boxNumber}`,
      },
    ];

    if (hub) {
      tags.push({
        key: "hub",
        label: `HUB: ${hub}`,
      });
    }

    dispatch({
      type: actionTypes.WIFI_BOX_SET_WIFI_BOX,
      value,
      boxNumber,
      hub,
      isLinked,
    });
  };

export const link: Action =
  (dispatch): link =>
  (hostId, reservationId, boxNumber, boxValue) => {
    dispatch({
      type: actionTypes.WIFI_BOX_LINK_PROCESSING,
    });

    hotelApi({
      path: `/wifibox/link?TokenId=${encodeURIComponent(
        boxNumber
      )}&ReservationId=${encodeURIComponent(
        reservationId
      )}&HostId=${encodeURIComponent(hostId)}`,
      method: "post",
    })
      .then((response) => {
        analyticsLinkWifiBox(reservationId, boxValue);

        dispatch({
          type: actionTypes.WIFI_BOX_LINK_PROCESSED,
        });

        getList(dispatch)();
      })
      .catch((e) => {
        console.error(e);

        dispatch({
          type: actionTypes.WIFI_BOX_LINK_ERROR,
          error: e,
        });
      });
  };

export const unlink: Action =
  (dispatch): unlink =>
  (wifiBox, boxValue, hostId) => {
    dispatch({
      type: actionTypes.WIFI_BOX_UNLINK_PROCESSING,
    });

    hotelApi({
      path: `/wifibox/unlink?TokenId=${encodeURIComponent(
        wifiBox
      )}&HostId=${encodeURIComponent(hostId)}`,
      method: "post",
    })
      .then((response) => {
        // @TODO analytics wifibox unlink. Should not be here. Probably in the component
        // dispatch({
        //   type: actionTypes.ANALYTICS_UNLINK_WIFI_BOX,
        //   wifiBoxNumber: boxNumber
        // });

        analyticsUnlinkWifiBox(boxValue);

        dispatch({
          type: actionTypes.WIFI_BOX_UNLINK_PROCESSED,
        });

        getList(dispatch)();
      })
      .catch((e) => {
        console.error(e);

        dispatch({
          type: actionTypes.WIFI_BOX_UNLINK_ERROR,
          error: e,
        });
      });
  };

export const add: Action =
  (dispatch): add =>
  (hostId, wifiBox, boxNumber) => {
    dispatch({
      type: actionTypes.WIFI_BOX_ADD_PROCESSING,
    });

    hotelApi({
      path: `/wifibox/add?TokenId=${encodeURIComponent(
        wifiBox
      )}&BoxNumber=${encodeURIComponent(boxNumber)}&HostId=${encodeURIComponent(
        hostId
      )}`,
      method: "post",
    })
      .then((response) => {
        dispatch({
          type: actionTypes.WIFI_BOX_ADD_PROCESSED,
        });

        getList(dispatch)();
      })
      .catch((e) => {
        console.error(e);

        dispatch({
          type: actionTypes.WIFI_BOX_ADD_ERROR,
          error: e,
        });
      });
  };

export const getList: Action =
  (dispatch): getList =>
  () => {
    dispatch({
      type: actionTypes.WIFI_BOX_GET_LIST,
    });

    hotelApi({ path: "/wifibox/all" })
      .then((response) => {
        dispatch({
          type: actionTypes.WIFI_BOX_SET_LIST,
          list: response.Boxes,
        });
      })
      .catch((e) => {
        console.error(e);

        dispatch({
          type: actionTypes.WIFI_BOX_GET_LIST_ERROR,
          error: e,
        });
      });
  };

export const reset: Action =
  (dispatch): reset =>
  () => {
    dispatch({
      type: actionTypes.WIFI_BOX_RESET,
    });
  };

export const resetForm: Action =
  (dispatch): resetForm =>
  () => {
    dispatch({
      type: actionTypes.WIFI_BOX_RESET_FORM,
    });
  };

export const openWifiBoxModal: Action =
  (dispatch): openWifiBoxModal =>
  (reservation, customers, hub) => {
    dispatch({
      type: actionTypes.WIFI_BOX_OPEN_MODAL,
    });

    setFormReservation(dispatch)({
      id: reservation.Id,
      reservation,
      hub,
      customer: customers[0],
    });
  };

export const closeWifiBoxModal: Action =
  (dispatch): closeWifiBoxModal =>
  () => {
    dispatch({
      type: actionTypes.WIFI_BOX_CLOSE_MODAL,
    });
  };

const actions = (dispatch: React.Dispatch<ActionType>): WifiBoxActionTypes => ({
  subscribeToScanner: subscribeToScanner(dispatch),
  unsubscribeFromScanner: unsubscribeFromScanner(dispatch),
  getTokenInformation: getTokenInformation(dispatch),
  setFormReservation: setFormReservation(dispatch),
  setFormReservationById: setFormReservationById(dispatch),
  setFormBoxNumber: setFormBoxNumber(dispatch),
  setFormWifiBox: setFormWifiBox(dispatch),
  link: link(dispatch),
  unlink: unlink(dispatch),
  add: add(dispatch),
  getList: getList(dispatch),
  reset: reset(dispatch),
  resetForm: resetForm(dispatch),
  openWifiBoxModal: openWifiBoxModal(dispatch),
  closeWifiBoxModal: closeWifiBoxModal(dispatch),
});

export default actions;
