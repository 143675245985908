import React, { useState, TextareaHTMLAttributes } from "react";
import styles from "./TextArea.module.scss";
import SmileyIcon from "assets/svg/smiley.svg";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import AutoResizeTextArea from "react-textarea-autosize";

const pickerStyle = {
  width: "100%",
  height: "250px",
  overflow: "hidden"
};

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  type: "message" | "default";
  value: string;
  onSelectEmoji?: (emoji: string) => void;
}

const TextArea: React.FC<Props> = props => {
  const wrapperClasses = [styles.wrapper];
  const textAreaClasses = [styles.textArea];
  const [showPicker, setShowPicker] = useState<boolean>(false);
  const textAreaRef = React.createRef<HTMLTextAreaElement>();

  const { type, onSelectEmoji, value, ...otherProps } = props;

  if (type === "message") {
    wrapperClasses.push(styles.message);
  }

  return (
    <div>
      {type === "message" && showPicker && (
        <Picker
          autoFocus={showPicker}
          native={true}
          style={pickerStyle}
          onSelect={emoji => {
            setShowPicker(false);
            if (onSelectEmoji && "native" in emoji) {
              onSelectEmoji(emoji.native);
            }
            if (textAreaRef.current) {
              textAreaRef.current.focus();
            }
          }}
        />
      )}
      <div className={wrapperClasses.join(" ")}>
        {type === "message" && (
          <>
            <AutoResizeTextArea
              inputRef={textAreaRef}
              className={textAreaClasses.join(" ")}
              value={value}
              {...otherProps}
            />
            <img
              alt="Find Emoji's"
              src={SmileyIcon}
              onClick={() => setShowPicker(!showPicker)}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default TextArea;
