import React, { useContext, useEffect } from "react";
import styles from "./ChatWifiBox.module.scss";
import Modal from "components/elements/modal";
import { Context as WifiBoxContext } from "../../../../store/wifiBox";
import { Context as AuthorizationContext } from "../../../../store/authorization";
import { Context as NotificationContext } from "../../../../store/notification";
import H1 from "components/elements/H1";
import Button from "components/elements/Button";
import Paragraph from "components/elements/Paragraph";
import LoadingIcon from "assets/img/loading.gif";

interface Props {}

const ChatWifiBox: React.FC<Props> = (props) => {
  const {
    state: wifiBoxState,
    closeWifiBoxModal,
    subscribeToScanner,
    unsubscribeFromScanner,
    link,
    reset,
  } = useContext(WifiBoxContext);
  const { state: authorizationState } = useContext(AuthorizationContext);
  const {
    wifiBoxModalOpen,
    reservation,
    customer,
    form,
    loadingTokenInfoError,
    loadingTokenInfoProcessing,
    linkProcessing,
    linkProcessed,
    linkError,
  } = wifiBoxState;
  const { authorizedHost, authorizedHotelCode } = authorizationState;
  const { showNotification } = useContext(NotificationContext);

  useEffect(() => {
    return () => {
      reset();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!authorizedHotelCode) {
      return;
    }
    if (wifiBoxModalOpen) {
      subscribeToScanner(authorizedHotelCode);
    } else {
      reset();
    }
  }, [wifiBoxModalOpen, authorizedHotelCode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (linkProcessed) {
      showNotification({
        title: `Wifi box ${form.boxNumber} successfully linked`,
        type: "success",
      });
      closeWifiBoxModal();
    }
  }, [linkProcessed]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!wifiBoxModalOpen || !reservation || !authorizedHost) {
    return null;
  }

  return (
    <Modal onClose={closeWifiBoxModal}>
      <div className={styles.contentWrapper}>
        <H1>Connect wifi box</H1>
        <div className={styles.row}>
          <div>
            <Paragraph>HUB</Paragraph>
            <Paragraph medium strong>
              {reservation.Hub ? reservation.Hub.Number : "-"}
            </Paragraph>
          </div>
          <div>
            <Paragraph>WIFI BOX</Paragraph>
            <Paragraph medium strong>
              {form.boxNumber ? form.boxNumber : "-"}
            </Paragraph>
          </div>
        </div>
        {loadingTokenInfoProcessing && (
          <Paragraph>
            <img alt="Loading" width="60" src={LoadingIcon} />
            <br />
            Loading token info...
          </Paragraph>
        )}
        {loadingTokenInfoError && (
          <Paragraph error>Error loading token info</Paragraph>
        )}
        {linkError && <Paragraph error>Failed to link, try again.</Paragraph>}
        {linkProcessing && (
          <Paragraph>
            <img alt="Linking" width="60" src={LoadingIcon} />
            <br />
            Linking...
          </Paragraph>
        )}
        {form.boxNumber && !linkProcessing && (
          <Button
            className={styles.submitButton}
            onClick={() =>
              link(
                authorizedHost.id,
                reservation.Id,
                form.wifiBox,
                form.boxNumber
              )
            }
          >
            {linkError ? "Try again" : "Connect"}
          </Button>
        )}
        {!form.boxNumber && !linkProcessing && !loadingTokenInfoProcessing && (
          <>
            <Paragraph>
              <img width="60" src={LoadingIcon} alt="Loading" />
              <br />
              Please scan the wifi box
            </Paragraph>
            <Button
              link
              to={{
                pathname: `/wifiBox`,
                state: {
                  reservation: {
                    ...reservation,
                    Hub: reservation.Hub ? reservation.Hub.Number : null,
                  },
                  customers: [customer],
                },
              }}
            >
              Add wifi box manually
            </Button>
          </>
        )}
      </div>
    </Modal>
  );
};

export default ChatWifiBox;
