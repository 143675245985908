import { Action, ActionType } from "./types";
import { getSocketIOClient, getWebSocketClient } from "services/webSocket";
import { mobileApi, hotelApi } from "services/api";

export const actionTypes = {
  HOST_REQUEST: "host/MESSAGES_REQUEST",
  HOST_REQUEST_FAILED: "host/MESSAGES_REQUEST_FAILED",
  HOST_REQUEST_SUCCESS: "host/MESSAGES_REQUEST_SUCCESS",
  HOST_FETCH: "host/HOST_FETCH",
  HOST_FETCH_SUCCESS: "host/HOST_FETCH_SUCCESS",
  HOST_FETCH_FAILED: "host/HOST_FETCH_FAILED",
  HOST_SET_FORM_VALUE: "host/HOST_SET_FORM_VALUE",
  HOST_SET_FORM_ERROR: "host/HOST_SET_FORM_ERROR",
  HOST_RESET_FORM: "host/HOST_RESET_FORM",
  HOST_SUBMIT_FORM: "host/HOST_SUBMIT_FORM",
  HOST_SUBMIT_SUCCESS: "host/HOST_SUBMIT_SUCCESS",
  HOST_SUBMIT_ERROR: "host/HOST_SUBMIT_ERROR",
};

export interface HostActionTypes {
  subscribeToScanner: subscribeToScanner;
  unsubscribeFromScanner: unsubscribeFromScanner;
  load: load;
  setFormValue: setFormValue;
  setFormError: setFormError;
  setCurrentHost: setCurrentHost;
  saveForm: saveForm;
  resetForm: resetForm;
  deleteHost: deleteHost;
  removeHostToken: removeHostToken;
}

interface subscribeToScanner {
  (hotelCode: string): void;
}

interface unsubscribeFromScanner {
  (hotelCode: string): void;
}

interface load {
  (isAdmin: boolean): void;
}

interface setFormValue {
  (key: string, value: any): void;
}

interface setFormError {
  (key: string, value: any): void;
}

export interface setCurrentHost {
  (host: {
    hostId: string;
    firstName: string;
    mewsID: string;
    avatar: string;
    gif: string;
  }): void;
}

interface saveForm {
  (host: any): void;
}

interface resetForm {
  (): void;
}

export interface deleteHost {
  (id: string): void;
}

interface removeHostToken {
  (id: string): void;
}

export const subscribeToScanner: Action =
  (dispatch): subscribeToScanner =>
  (hotelCode) => {
    const onMessage = (message: { TokenId?: string }) => {
      console.info("Scanned token", message);
      if (!("TokenId" in message)) {
        return;
      }

      setFormValue(dispatch)("token", message.TokenId);
      setFormValue(dispatch)("tokenInput", "scanner");
    };

    // if (hotelCode === "CPH") {
    const socket = getWebSocketClient(hotelCode);
    socket.onmessage = (event) => {
      console.log("Received message from websocket", event);
      const message = JSON.parse(event.data);
      console.log("Passing message", message);
      onMessage(message);
    };
    // } else {
    //   const socket = getSocketIOClient();
    //   socket.on("Token", onMessage);
    // }
  };

export const unsubscribeFromScanner: Action =
  (dispatch): unsubscribeFromScanner =>
  (hotelCode) => {
    const socket = getWebSocketClient(hotelCode);
    socket.close();

    // if (hotelCode === "CPH") {
    //   const socket = getWebSocketClient("CPH");
    //   socket.close();
    // } else {
    //   const socket = getSocketIOClient();
    //   socket.off("Token");
    // }
  };

export const load: Action =
  (dispatch): load =>
  (isAdmin) => {
    dispatch({ type: actionTypes.HOST_REQUEST });

    const path = isAdmin ? `/host/allForAdmin` : "/host/all";

    mobileApi({ path })
      .then((response) => {
        dispatch({
          type: actionTypes.HOST_REQUEST_SUCCESS,
          hosts: response,
        });
      })
      .catch((error) => {
        dispatch({ type: actionTypes.HOST_REQUEST_FAILED });
      });
  };

export const setFormValue: Action =
  (dispatch): setFormValue =>
  (key, value) => {
    dispatch({
      type: actionTypes.HOST_SET_FORM_VALUE,
      key,
      value,
    });
  };

export const setFormError: Action =
  (dispatch): setFormError =>
  (key, value) => {
    dispatch({
      type: actionTypes.HOST_SET_FORM_ERROR,
      key,
      value,
    });
  };

export const setCurrentHost: Action =
  (dispatch): setCurrentHost =>
  async (host) => {
    const { hostId, firstName, mewsID, avatar, gif } = host;

    resetForm(dispatch)();
    setFormValue(dispatch)("hostId", hostId);
    setFormValue(dispatch)("firstName", firstName);
    setFormValue(dispatch)("mewsID", mewsID);
    setFormValue(dispatch)("avatar", avatar);
    setFormValue(dispatch)("gif", gif);

    if (mewsID) {
      try {
        const response = await hotelApi({
          path: "/reservation/CustomerTokens",
          params: {
            CustomerId: mewsID,
          },
        });

        const token = response.find(
          (item: { TokenType: string; TokenId: string }) =>
            item.TokenType === "STAFF"
        );

        if (token) {
          setFormValue(dispatch)("token", token.TokenId);
          setFormValue(dispatch)("tokenInput", "api");
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

export const saveForm: Action =
  (dispatch): saveForm =>
  async (values) => {
    if (values.firstName.length === 0) {
      setFormError(dispatch)("firstName", "This field is required");
      return;
    }

    if (
      values.mewsID &&
      values.mewsID.match(
        /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/i
      ) === null
    ) {
      setFormError(dispatch)("mewsID", "This is not a valid Mews ID");
      return;
    }

    setFormError(dispatch)("firstName", null);
    setFormError(dispatch)("mewsID", null);

    dispatch({
      type: actionTypes.HOST_SUBMIT_FORM,
    });

    let params = {
      FirstName: values.firstName.trim(),
      MewsId: undefined,
      HostId: undefined,
    };
    if (values.mewsID) {
      params.MewsId = values.mewsID;
    }

    if (values.hostId) {
      params.HostId = values.hostId;
    }

    const path = values.hostId ? "/host/editHost" : "/host/addHost";
    try {
      const response = await mobileApi({
        path,
        params,
      });

      if (response.errors) {
        throw new Error(response.errors);
      }

      const hostId = values.hostId ? values.hostId : response.id;
      if (values.avatar instanceof Blob) {
        const avatarResponse = await mobileApi({
          path: `/host/getSignedImageUploadUrl`,
          params: {
            HostId: hostId,
            Type: "jpg",
          },
        });

        await fetch(avatarResponse.signedRequest, {
          method: "PUT",
          headers: {
            "Content-Type": "image/jpeg",
          },
          body: values.avatar,
        });
      }

      if (values.gif instanceof Blob) {
        const gifResponse = await mobileApi({
          path: "/host/getSignedImageUploadUrl",
          params: {
            HostId: hostId,
            Type: "gif",
          },
        });

        await fetch(gifResponse.signedRequest, {
          method: "PUT",
          headers: {
            "Content-Type": "image/gif",
          },
          body: values.gif,
        });
      }

      if (values.token && values.mewsID) {
        await hotelApi({
          path: "/reservation/addHostToken",
          params: {
            CustomerId: values.mewsID,
            TokenId: values.token,
          },
        });
      }
    } catch (e) {
      console.error(e);
      dispatch({
        type: actionTypes.HOST_SUBMIT_ERROR,
      });

      return;
    }

    dispatch({
      type: actionTypes.HOST_SUBMIT_SUCCESS,
    });

    resetForm(dispatch)();
    load(dispatch)(true);
  };

export const resetForm: Action =
  (dispatch): resetForm =>
  () => {
    dispatch({
      type: actionTypes.HOST_RESET_FORM,
    });
  };

export const deleteHost: Action =
  (dispatch): deleteHost =>
  async (id) => {
    if (!id) {
      throw new Error("ID is empty, cannot delete");
    }

    try {
      await mobileApi({
        path: "/host/deleteHost",
        params: {
          HostId: id,
        },
      });
    } catch (e) {
      console.error(e);
    }

    load(dispatch)(true);
  };

export const removeHostToken: Action =
  (dispatch): removeHostToken =>
  async (id) => {
    try {
      await hotelApi({
        path: "/reservation/removeHostToken",
        params: {
          CustomerId: id,
        },
      });

      setFormValue(dispatch)("token", "");
    } catch (e) {
      console.error(e);
    }
  };

const actions = (dispatch: React.Dispatch<ActionType>): HostActionTypes => ({
  subscribeToScanner: subscribeToScanner(dispatch),
  unsubscribeFromScanner: unsubscribeFromScanner(dispatch),
  load: load(dispatch),
  setFormValue: setFormValue(dispatch),
  setFormError: setFormError(dispatch),
  setCurrentHost: setCurrentHost(dispatch),
  saveForm: saveForm(dispatch),
  resetForm: resetForm(dispatch),
  deleteHost: deleteHost(dispatch),
  removeHostToken: removeHostToken(dispatch),
});

export default actions;
