import React, { useContext, useState, useEffect, useCallback } from "react";
import styles from "./Map.module.scss";
import { Context as AuthorizationContext } from "../../../store/authorization";
import CityHubMarkerIcon from "assets/svg/marker-cityhub.svg";
import RedMarkerIcon from "assets/svg/marker-red.svg";
import DefaultMarkerIcon from "assets/svg/marker.svg";

import {
  GoogleMap,
  LoadScript,
  Marker,
  Autocomplete,
  OverlayView,
  DirectionsRenderer,
  DirectionsService,
  Data,
} from "@react-google-maps/api";
import Input from "../Input";
import SelectedPlace from "../SelectedPlace";
import { Place, Message } from "store/interface/chat";
import { mapToPlace } from "helpers/map";
import Avatar from "../Avatar";
import config from "helpers/config";
import Region from "../Locations/Region/Region";

interface Props {
  onSelectPlace?: (selectedPlace: Place) => void;
  onChangeSelectedRegion?: (selectedRegion: Region | null) => void;
  onChangeSelectedPlace?: (selectedPlace: Place | null) => void;
  places: Place[];
  userLocation: Message["location"] | null;
  userPicture: string | null;
  showSelectedPlaceOverlay?: boolean;
  selectedPlaceId?: string;
  selectedRegionId?: string;
  regions?: {
    features: Region[];
  };
  showDirections?: boolean;
  setMapRef?: (mapRef: google.maps.Map | undefined) => void;
  initialCenter?: {
    lat: number;
    lng: number;
  };
  initialZoom?: number;
}

let map: google.maps.Map | null = null;
let autoComplete: any = null;

const getCoordinatesFromPlaceResult = (
  placeResult: google.maps.places.PlaceResult
) => {
  if (!placeResult.geometry) {
    return {
      lat: 0,
      lng: 0,
    };
  }
  return {
    lat: placeResult.geometry.location.lat(),
    lng: placeResult.geometry.location.lng(),
  };
};

const Map: React.FC<Props> = (props) => {
  const {
    onSelectPlace,
    places,
    userLocation,
    userPicture,
    showSelectedPlaceOverlay = true,
    selectedPlaceId,
    selectedRegionId,
    onChangeSelectedPlace,
    regions,
    onChangeSelectedRegion,
    showDirections,
    setMapRef,
    initialZoom,
    initialCenter,
  } = props;
  const zoom = initialZoom ? initialZoom : 13;
  const { state: authorizationState } = useContext(AuthorizationContext);
  const { authorizedHotelCode, hotelProperties } = authorizationState;
  const [selectedPlace, setSelectedPlace] = useState<Place | null>(null);
  const [selectedRegion, setSelectedRegion] = useState<Region | null>(null);
  const [searchValue, setSearchValue] = useState<string>("");
  const [directions, setDirections] = useState<null | any>(null);

  useEffect(() => {
    if (!selectedPlaceId) {
      setSelectedPlace(null);
      return;
    }

    const place = places.find((place) => place.id === selectedPlaceId);

    if (place) {
      setSelectedPlace(place);
    }
  }, [selectedPlaceId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedPlace && !showDirections) {
      map && map.panTo({ lat: selectedPlace.location.lat, lng: selectedPlace.location.lng });
    }
    onChangeSelectedPlace && onChangeSelectedPlace(selectedPlace);
    setDirections(null);
  }, [selectedPlace, hotelProperties, authorizedHotelCode, showDirections]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!regions) {
      return;
    }

    const region = regions.features.find(
      (region) => region.properties.id === selectedRegionId
    );

    setSelectedRegion(region ? region : null);
  }, [selectedRegionId, regions]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    map &&
      map.data.forEach((feature) => {
        const isSelectedFeature =
          selectedRegion &&
          feature.getProperty("id") === selectedRegion.properties.id;
        map &&
          map.data.overrideStyle(feature, {
            strokeColor: isSelectedFeature ? "#e4bb02" : "#44bfb4",
            fillColor: isSelectedFeature ? "#e4bb02" : "#44bfb4",
          });
      });

    if (selectedRegion) {
      onChangeSelectedRegion && onChangeSelectedRegion(selectedRegion);
      setSelectedPlace(null);
    }
  }, [selectedRegion, hotelProperties, authorizedHotelCode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!selectedPlace && !selectedRegion && map && authorizedHotelCode) {
      map.setZoom(zoom);
      map.panTo(hotelProperties[authorizedHotelCode].coordinates);
    }
  }, [
    selectedPlaceId,
    selectedPlace,
    selectedRegion,
    hotelProperties,
    authorizedHotelCode,
    zoom,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  const onLoad = useCallback((mapInstance: google.maps.Map) => {
    map = mapInstance;

    if (setMapRef) {
      setMapRef(map);
    }

    if (regions) {
      // Adding style/click events though native library because it's not working properly though the components
      map.data.setStyle({
        strokeColor: "#44bfb4",
        strokeWeight: 2,
        fillColor: "#44bfb4",
        clickable: true,
        fillOpacity: 0.2,
      } as google.maps.Data.StyleOptions);

      map.data.addGeoJson(regions);

      map.data.addListener("click", (event) => {
        if (map) {
          map.setZoom(15);
          map.panTo(event.latLng);

          event.feature.toGeoJson((region: Region) => {
            setSelectedRegion(region);
          });
        }
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!authorizedHotelCode) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <LoadScript
        id="script-loader"
        googleMapsApiKey={config.REACT_APP_GOOGLE_API_KEY}
        libraries={["places"]}
        //  {libraries}
        region={hotelProperties[authorizedHotelCode].googleMapsRegion}
      >
        <GoogleMap
          mapContainerClassName={styles.mapContainer}
          zoom={zoom}
          center={
            initialCenter
              ? initialCenter
              : hotelProperties[authorizedHotelCode].coordinates
          }
          onLoad={onLoad}
          options={{
            disableDefaultUI: true,
            gestureHandling: "greedy",
            clickableIcons: false,
          }}
        >
          <Autocomplete
            onPlaceChanged={async () => {
              if (autoComplete && map) {
                const place: google.maps.places.PlaceResult =
                  autoComplete.getPlace();
                const mappedPlace = await mapToPlace(place);

                setSelectedPlace(mappedPlace);
                setSearchValue("");

                map.panTo(getCoordinatesFromPlaceResult(place));
              }
            }}
            onLoad={(autocompleteInstance) => {
              autoComplete = autocompleteInstance;
            }}
          >
            <div className={styles.searchInputWrapper}>
              <Input
                type="search"
                placeholder="Search"
                value={searchValue}
                onChange={(event) => setSearchValue(event.target.value)}
              />
            </div>
          </Autocomplete>
          <Marker
            position={hotelProperties[authorizedHotelCode].coordinates}
            icon={CityHubMarkerIcon}
            onClick={() => {
              if (map) {
                map.panTo(hotelProperties[authorizedHotelCode].coordinates);
              }
            }}
          />
          {userLocation && (
            <OverlayView
              //@ts-ignore
              position={userLocation}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              getPixelPositionOffset={(width, height) => ({
                x: -(width / 2),
                y: -(height / 2),
              })}
            >
              <div
                onClick={() => {
                  if (map) {
                    map.panTo(userLocation);
                  }
                }}
              >
                <Avatar
                  size="medium"
                  alt="Guest"
                  src={userPicture ? userPicture : undefined}
                />
              </div>
            </OverlayView>
          )}
          {places.map((place) =>
            !selectedPlace || selectedPlace.id !== place.id ? (
              <Marker
                key={`${place.id}-${place.location.lat}-${place.location.lng}`}
                position={{ lat: place.location.lat, lng: place.location.lng }}
                icon={DefaultMarkerIcon}
                onClick={() => {
                  setSelectedPlace(place);
                }}
              />
            ) : null
          )}
          {selectedPlace && (
            <>
              <Marker
                position={{ lat: selectedPlace.location.lat, lng: selectedPlace.location.lng }}
                icon={RedMarkerIcon}
              />
              {showSelectedPlaceOverlay && (
                <div className={styles.selectedPlaceWrapper}>
                  <SelectedPlace
                    type="map"
                    imgSrc={selectedPlace.picture ? selectedPlace.picture : ""}
                    title={selectedPlace.name}
                    address={selectedPlace.streetAddress}
                    rating={selectedPlace.rating}
                    onClose={() => setSelectedPlace(null)}
                    onSelect={() => {
                      onSelectPlace && onSelectPlace(selectedPlace);
                      setSelectedPlace(null);
                      if (map) {
                        map.panTo(
                          hotelProperties[authorizedHotelCode].coordinates
                        );
                      }
                    }}
                  />
                </div>
              )}
              {showDirections && !directions && (
                <DirectionsService
                  options={
                    {
                      destination: {
                        lat: selectedPlace.location.lat,
                        lng: selectedPlace.location.lng,
                      },
                      origin: hotelProperties[authorizedHotelCode].coordinates,
                      travelMode: "WALKING",
                    } as any
                  }
                  callback={setDirections}
                />
              )}
              {directions && (
                <DirectionsRenderer
                  directions={directions}
                  options={{
                    suppressMarkers: true,
                  }}
                />
              )}
            </>
          )}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default Map;
