import { Action, ActionType } from "./types";
import { Notification } from "store/notification";

interface showNotification {
  (notification: Notification): void;
}

interface hideNotification {
  (): void;
}

export interface NotificationActionTypes {
  showNotification: showNotification;
  hideNotification: hideNotification;
}

export const actionTypes = {
  SHOW_NOTIFICATION: "notification/SHOW_NOTIFICATION",
  HIDE_NOTIFICATION: "notification/HIDE_NOTIFICATION"
};

const showNotification: Action = (
  dispatch
): showNotification => notification => {
  dispatch({
    type: actionTypes.SHOW_NOTIFICATION,
    notification
  });
};

const hideNotification: Action = (dispatch): hideNotification => () => {
  dispatch({
    type: actionTypes.HIDE_NOTIFICATION
  });
};

const actions = (
  dispatch: React.Dispatch<ActionType>
): NotificationActionTypes => ({
  showNotification: showNotification(dispatch),
  hideNotification: hideNotification(dispatch)
});

export default actions;
