import moment from "moment";

export const fromNowOrNow = (momentInput: any) => {
  if (Math.abs(moment().diff(momentInput)) < 25000) {
    // 25 seconds before or after now
    return "just now";
  }
  return moment(momentInput).fromNow();
};

export const timestamp = () => {
  return moment().toDate().getTime();
}

export const timestampMinusDays = (days: number) => {
  return moment().subtract(days, "days").toDate().getTime();
}

export const dateMinusDays = (days: number) => {
  return moment().subtract(days, "days").format('YYYY-MM-DDTHH:mm:ss[Z]');
}

export const datePlusDays = (days: number) => {
  return moment().add(days, "days").format('YYYY-MM-DDTHH:mm:ss[Z]');
}
