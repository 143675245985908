import { hotelApi } from "services/api";

export const getMewsCustomerByTokenId = async (TokenId: string) => {
  let getCustomer = {
    FirstName: "",
    LastName: "",
    ReservationId: "",
    Number: "",
    Hub: "",
    ItemsResult: "",
  };

  // Get token
  const token = await hotelApi({
    path: "/reservation/token/" + TokenId,
    params: {},
  }).catch((error) => {
    console.error(error);
  });

  if (!token) {
    return getCustomer;
  }

  const reservations = await hotelApi({
    path: "/reservation/byId",
    params: {
      ReservationId: token.Token.ReservationId,
      Encoder: token.Token.Encoder,
    },
  }).catch((error) => {
    console.error(error);
  });

  if (!reservations) {
    return getCustomer;
  }

  const reservation = reservations.Reservations.find(
    (r: any) => r.Id === token.Token.ReservationId
  );

  // Update get customer
  getCustomer = {
    ...getCustomer,
    ReservationId: reservation.Id ?? "",
    Number: reservation.Number ?? "",
    Hub: reservations.Hub.Number ?? "",
    ItemsResult: reservations.ItemsResult ?? "",
  };

  // Get customer info
  const customerInfo = await hotelApi({
    path: "/reservation/customer/info",
    params: {
      CustomerId: token.Token.CustomerId,
    },
  });

  if (!customerInfo) {
    return getCustomer;
  }

  const customer = customerInfo.Customers.find(
    (c: any) => c.Id === token.Token.CustomerId
  );

  // Update get customer
  getCustomer = {
    ...getCustomer,
    FirstName: customer.FirstName ?? "",
    LastName: customer.LastName ?? "",
  };

  return getCustomer;
};
