import React, { useContext, useEffect } from "react";
import styles from "./WifiBoxList.module.scss";
import { RowRenderProps } from "react-table";
import "react-table/react-table.css";
import {
  Context as WifiBoxContext,
  WifiBoxItem
} from "../../../../store/wifiBox";
import { Context as AuthorizationContext } from "../../../../store/authorization";
import { Context as NotificationContext } from "../../../../store/notification";
import moment from "moment";
import Button from "components/elements/Button";
import Paragraph from "components/elements/Paragraph";
import H1 from "components/elements/H1";
import Table from "components/elements/Table";
import config from "helpers/config";

interface Props {}

type onClickUnlink = (wifiBox: string, boxNumber: string) => void;
type onClickWifiBox = (wifiBox: string) => void;

const getColumns = (
  onClickUnlink: onClickUnlink,
  onClickWifiBox: onClickWifiBox
) => {
  return [
    {
      Header: "Hub",
      accessor: "Hub",
      id: "Hub",
      width: 60,
      Cell: (props: RowRenderProps) => {
        return (
          <Button
            link
            fat
            onClick={() => onClickWifiBox(props.row._original.TokenId)}
          >
            {props.value}
          </Button>
        );
      },
      sortMethod: (a: WifiBoxItem["Hub"], b: WifiBoxItem["Hub"]) => {
        //Custom sort: Numeric, but make 0 at the end of the list, to show unlinked boxes (hubs) last
        if (a === null || a === 0) {
          return 1;
        } else if (b === null || b === 0) {
          return -1;
        } else if (a === b) {
          return 0;
        }
        return parseInt(a.toString()) > parseInt(b.toString()) ? 1 : -1;
      }
    },
    {
      Header: "Wifi box",
      accessor: "BoxNumber",
      Cell: (props: RowRenderProps) => {
        return (
          <Button
            link
            onClick={() => onClickWifiBox(props.row._original.TokenId)}
          >
            {props.value}
          </Button>
        );
      }
    },
    {
      Header: "Reservation",
      accessor: "ReservationId",
      minWidth: 150,
      Cell: (props: RowRenderProps) => {
        if (!props.value) {
          return null;
        }

        return (
          <a
            href={`${
              config.REACT_APP_MEWS_BASE_URL
            }/Commander/Reservation/Detail/${props.value}#/properties`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {props.row._original.Reservation.Number}
          </a>
        );
      }
    },

    {
      Header: "Checkout date",
      accessor: "Reservation",
      Cell: (props: RowRenderProps) => {
        if (!props.value || !props.value.EndUtc) {
          return null;
        }
        let statusClassname = moment(
          props.row._original.Reservation.EndUtc
        ).isSame(moment(), "day")
          ? styles.reservationColumnWarning //Checkout today
          : styles.reservationColumnActive; //Active

        if (props.row._original.Reservation.State === "Processed") {
          //Checked out is inactive
          statusClassname = styles.reservationColumnInactive;
        }

        return (
          <div
            className={[styles.reservationColumn, statusClassname].join(" ")}
          >
            <span>{moment(props.value.EndUtc).format("DD MMM")}</span>
          </div>
        );
      }
    },
    {
      Header: "Linked date",
      accessor: "updatedAt",
      Cell: (props: RowRenderProps) => {
        if (!props.row._original.ReservationId) {
          return null;
        }
        return <span>{moment(props.value).format("DD MMM HH:mm")}</span>;
      }
    },
    {
      Header: "",
      accessor: "TokenId",
      Cell: (props: RowRenderProps) => {
        if (!props.row._original.ReservationId) {
          return null;
        }

        return (
          <Button
            link
            onClick={() =>
              onClickUnlink(props.value, props.row._original.BoxNumber)
            }
          >
            Unlink
          </Button>
        );
      }
    }
  ];
};

const WifiBoxList: React.FC<Props> = props => {
  const {
    state: wifiBoxState,
    unlink,
    reset,
    getTokenInformation,
    getList
  } = useContext(WifiBoxContext);
  const { state: authorizationState } = useContext(AuthorizationContext);
  const {
    list,
    loadingList,
    loadingListError,
    unlinkError,
    unlinkProcessing,
    unlinkProcessed
  } = wifiBoxState;
  const { authorizedHost } = authorizationState;
  const { showNotification } = useContext(NotificationContext);

  useEffect(() => {
    getList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (unlinkProcessed) {
      showNotification({
        title: `Successfully unlinked`,
        type: "success"
      });
    }
  }, [unlinkProcessed]); // eslint-disable-line react-hooks/exhaustive-deps

  if (authorizedHost === null) {
    return null;
  }

  const onClickUnlink: onClickUnlink = (wifiBox, boxNumber) => {
    unlink(wifiBox, boxNumber, authorizedHost.id);
    reset();
  };

  const onClickWifiBox: onClickWifiBox = wifiBox => {
    getTokenInformation(wifiBox);
  };

  const getInUse = (list: WifiBoxItem[]) => {
    return list.filter(box => box.Hub !== 0).length;
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div>
          <H1>All wifi boxes</H1>
          <Paragraph small>
            {getInUse(list)}/{list.length} in use
          </Paragraph>
        </div>
        <div>
          <Button onClick={() => getList()}>Refresh list</Button>
        </div>
      </div>
      {loadingListError && (
        <Paragraph error>Error loading wifi boxes</Paragraph>
      )}
      {unlinkError && <Paragraph error>Error unlinking wifi box</Paragraph>}
      {unlinkProcessing && <Paragraph>Unlinking...</Paragraph>}
      <Table
        data={list}
        defaultSorted={[{ id: "Hub", desc: false }]}
        defaultPageSize={100}
        showPageSizeOptions={false}
        minRows={15}
        className={styles.table}
        columns={getColumns(onClickUnlink, onClickWifiBox)}
        loading={loadingList}
        getTdProps={() => ({
          style: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }
        })}
      />
    </div>
  );
};

export default WifiBoxList;
