import React, { useContext, useEffect } from "react";
import { Context as LocationsContext } from "../../../../store/locations";
import styles from "./LocationsWrapper.module.scss";
import Button from "../../Button";
import Avatar from "components/elements/Avatar";
import Paragraph from "components/elements/Paragraph";

interface Props {
  title?: string;
}

const LocationsWrapper: React.FC<Props> = ({ title, children }) => {
  const { state: locationsState, reset, fetchReservationPlaces } = useContext(
    LocationsContext
  );
  const { reservation, customers } = locationsState;
  console.log("customers", customers);
  useEffect(() => {
    if (reservation) {
      fetchReservationPlaces(reservation.Id);
    }
  }, [reservation]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.wrapper}>
      {title && <h3>Your locations</h3>}
      <div className={styles.content}>{children}</div>
      {reservation && (
        <div className={styles.footer}>
          <div className={styles.avatars}>
            {customers.map(customer => (
              <Avatar
                key={customer.Id}
                src={customer.Picture}
                size="medium"
                alt="First"
              />
            ))}
          </div>
          <div>
            <Paragraph strong small>
              {customers.map(customer => customer.FirstName).join(" & ")}
            </Paragraph>
          </div>
          <div>
            <Button onClick={reset} small>
              Logout
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LocationsWrapper;
