import React, { useContext, useEffect } from "react";
import styles from "./Chat.module.scss";
import ChatList from "components/elements/Chat/ChatList";
import Sidebar from "components/layouts/Sidebar";
import ChatListForm from "components/elements/Chat/ChatListForm";
import ChatMessages from "components/elements/Chat/ChatMessages";
import SystemMessages from "components/elements/System/SystemMessages";
import ChatBroadcastItem from "components/elements/Chat/ChatBroadcastItem";
import ChatWifiBox from "components/elements/Chat/ChatWifiBox";
import Notifications from "components/elements/Notifications";

import { Context as ChatContext } from "../../../store/chat";
import { Context as AuthorizationContext } from "../../../store/authorization";
import ChatKioskPlaces from "components/elements/Chat/ChatKioskPlaces";

const Chat = () => {
  const { fetchChatRooms, fetchBroadCastRoom } = useContext(ChatContext);
  const { state: authorizationState } = useContext(AuthorizationContext);

  useEffect(() => {
    fetchChatRooms(authorizationState.authorizedHotelCode);
    fetchBroadCastRoom(authorizationState.authorizedHotelCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizationState.authorizedHotelCode]);

  return (
    <div>
      <div className={styles.wrapper}>
        <Sidebar activeLink="chat" />
        <Notifications />
        <div className={styles.chatList}>
          <ChatListForm />
          <div id="chat-list-scroll-container" className={styles.chatListList}>
            <ChatList lazyLoadScrollContainer="#chat-list-scroll-container" />
          </div>
          <div className={styles.chatBroadcastItem}>
            <ChatBroadcastItem />
          </div>
        </div>
        <div className={styles.chat}>
          <ChatMessages />
        </div>
        <div className={styles.systemMessages}>
          <SystemMessages />
        </div>
      </div>
      <ChatWifiBox />
      <ChatKioskPlaces />
    </div>
  );
};

export default Chat;
