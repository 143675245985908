import React from "react";
import styles from "./ImageCrop.module.scss";
import AvatarImageCropper from "react-avatar-image-cropper";

interface Props {
  onChange: (data: any) => void;
}

const ImageCrop: React.FC<Props> = props => {
  const maxsize = 1024 * 1024 * 4;
  const { onChange } = props;

  return (
    <div className={styles.wrapper}>
      <AvatarImageCropper
        apply={onChange}
        isBack
        maxsize={maxsize}
        text="Upload avatar"
      />
    </div>
  );
};

export default ImageCrop;
