import React from "react";
import styles from "./Tag.module.scss";

interface Props {
  contrast?: boolean;
}

const Tag: React.FC<Props> = props => {
  const { contrast } = props;

  const wrapperClassNames = [styles.wrapper];

  if (contrast) {
    wrapperClassNames.push(styles.contrast);
  }
  return <span className={wrapperClassNames.join(" ")}>{props.children}</span>;
};

export default Tag;
