import { firestore } from "services/firebase";
import { hotelCode } from "store/interface/chat";

/**
 * Update the placesReservationId for the hotel CityHub Places
 * @param hotelCode
 * @param reservationId
 */
export const updateKioskPlaces = (
  hotelCode: hotelCode | string | null,
  reservationId: string | undefined
) => {
  if (hotelCode !== null && reservationId !== undefined) {
    firestore()
      .collection("hotels")
      .where("hotelCode", "==", hotelCode)
      .get()
      .then((response) => {
        response.docs.forEach((doc) => {
          const docRef = firestore().collection("hotels").doc(doc.id);
          docRef.update({
            placesReservationId: reservationId,
          });
        });
      });
  }
};
