import React, { useContext } from "react";
import styles from "./Sidebar.module.scss";
import Avatar from "components/elements/Avatar";
import { Context as AuthorizationContext } from "../../../store/authorization";
import Paragraph from "components/elements/Paragraph";
import MarkerIcon from "assets/svg/location-marker.svg";
import ChatIcon from "assets/svg/chat.svg";
import WifiIcon from "assets/svg/wifi.svg";
import DocumentsIcon from "assets/svg/documents.svg";
import WristbandIcon from "assets/svg/wristband.svg";
import AdminIcon from "assets/svg/admin.svg";
import LogoutIcon from "assets/svg/logout.svg";
import { Link } from "react-router-dom";
import Button from "components/elements/Button";
import config from "helpers/config";

interface Props {
  activeLink:
    | "chat"
    | "wifiBox"
    | "admin"
    | "documents"
    | "hostLocations"
    | "wristband"
    | "analytics"
    | null;
}

const Sidebar: React.FC<Props> = (props) => {
  const { state: authorizationState, signOut } =
    useContext(AuthorizationContext);
  const { activeLink } = props;

  if (
    !authorizationState.authorizedHost ||
    !authorizationState.authorizedHotelCode
  ) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.avatarWrapper}>
        <Avatar
          crown={authorizationState.authorizedHost.isAdmin}
          sunglasses={authorizationState.authorizedHost.isWatcher}
          src={authorizationState.authorizedHost.Picture}
          alt={authorizationState.authorizedHost.FirstName}
        />
        <Paragraph small>
          {authorizationState.authorizedHost.FirstName}
        </Paragraph>
        <Paragraph className={styles.avatarLocation} small>
          <img src={MarkerIcon} alt="Location" />
          {
            authorizationState.hotelMapping[
              authorizationState.authorizedHotelCode
            ]
          }
        </Paragraph>
      </div>
      <div className={styles.navigationWrapper}>
        <Link className={activeLink === "chat" ? styles.active : ""} to="/">
          <img src={ChatIcon} alt="Chat" />
          <Paragraph small>Chat</Paragraph>
        </Link>
        {authorizationState.hotelProperties[
          authorizationState.authorizedHotelCode
        ].hasWifiBoxes && (
          <Link
            className={activeLink === "wifiBox" ? styles.active : ""}
            to="/wifiBox"
          >
            <img src={WifiIcon} alt="WifiBox" />
            <Paragraph small>Wifi box</Paragraph>
          </Link>
        )}
        {/* <Link
          className={activeLink === "wristband" ? styles.active : ""}
          to="/wristband"
        >
          <img src={WristbandIcon} alt="Wristband" />
          <Paragraph small>Wristband</Paragraph>
        </Link> */}
        <Link
          className={activeLink === "documents" ? styles.active : ""}
          to="/documents"
        >
          <img src={DocumentsIcon} alt="Documents" />
          <Paragraph small>Host Info</Paragraph>
        </Link>
        <Link
          className={activeLink === "hostLocations" ? styles.active : ""}
          to="/hostLocations"
        >
          <img src={DocumentsIcon} alt="Locations" />
          <Paragraph small>Locations</Paragraph>
        </Link>
        {authorizationState.authorizedHost.isAdmin && (
          <>
            <div className={styles.navigationDivider} />
            <Link
              className={activeLink === "admin" ? styles.active : ""}
              to="/admin"
            >
              <img src={AdminIcon} alt="Admin" />
              <Paragraph small>Admin</Paragraph>
            </Link>
          </>
        )}
        <Button link onClick={() => signOut()}>
          <img src={LogoutIcon} alt="Logout" />
          <Paragraph small>Logout</Paragraph>
        </Button>
      </div>
      <Paragraph small>version: {config.REACT_APP_VERSION}</Paragraph>
    </div>
  );
};

export default Sidebar;
