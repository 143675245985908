import React, { useContext, useEffect } from "react";
import { Route, RouteProps } from "react-router-dom";

import { Context } from "../../../store/authorization";

type Role = "default" | "admin" | "mapScreen";

interface Props extends RouteProps {
  redirectIfLoggedOut?: boolean;
  component: any;
  roles: Role[];
}

const PrivateRoute: React.FC<Props> = ({
  component: Component,
  redirectIfLoggedOut,
  roles,
  ...otherProps
}) => {
  const { state, addStorageListener } = useContext(Context);
  const isLoggedIn = !!state.authorizedHost;

  useEffect(() => {
    if (!state.addedStorageListener) {
      addStorageListener();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isLoggedIn && redirectIfLoggedOut !== false) {
      window.location.replace("/signin");
    }

    if (!state.authorizedHost) {
      return;
    }

    const userRoles = [
      state.authorizedHost.isAdmin && "admin",
      state.authorizedHost.isMapscreen && "mapScreen",
      !state.authorizedHost.isMapscreen && "default"
    ].filter(item => !!item);

    const intersections = roles.filter(role => userRoles.includes(role));
    if (
      intersections.length === 0 &&
      window.location.pathname !== "/unauthorized" &&
      redirectIfLoggedOut !== false
    ) {
      window.location.replace("/unauthorized");
    }
  }, [isLoggedIn, state.authorizedHost, roles]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Route
      {...otherProps}
      render={props => (isLoggedIn ? <Component {...props} /> : null)}
    />
  );
};

export default PrivateRoute;
