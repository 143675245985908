import React, { useReducer, useEffect } from "react";
import styles from "./WifiBox.module.scss";
import Sidebar from "components/layouts/Sidebar";
import WifiBoxList from "components/elements/WifiBox/WifiBoxList";
import {
  initialState as wifiBoxInitialState,
  reducer as wifiBoxReducer,
  Context as WifiBoxContext,
  actions as wifiBoxActions,
} from "../../../store/wifiBox";
import WifiBoxReservationForm from "components/elements/WifiBox/WifiBoxReservationForm";
import { RouteChildrenProps } from "react-router";
import Notifications from "components/elements/Notifications";

interface Props extends RouteChildrenProps<any, any> {}

const WifiBox: React.FC<Props> = (props) => {
  const { location, history } = props;
  const [wifiBoxState, wifiBoxDispatch] = useReducer(
    wifiBoxReducer,
    wifiBoxInitialState
  );

  useEffect(() => {
    if (location.state && location.state.reservation) {
      const locationState = {
        ...location.state,
      };

      delete locationState.reservation;
      delete locationState.customers;

      history.replace({
        ...history.location,
        state: locationState,
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <WifiBoxContext.Provider
      value={{
        state: wifiBoxState,
        ...wifiBoxActions(wifiBoxDispatch),
      }}
    >
      <div className={styles.wrapper}>
        <Sidebar activeLink="wifiBox" />
        <Notifications />
        <div className={styles.contentWrapper}>
          <div>
            <WifiBoxList />
          </div>
          <div>
            <WifiBoxReservationForm
              initialReservation={
                location.state && location.state.reservation
                  ? location.state.reservation
                  : null
              }
              initialCustomers={
                location.state && location.state.customers
                  ? location.state.customers
                  : null
              }
            />
          </div>
        </div>
      </div>
    </WifiBoxContext.Provider>
  );
};

export default WifiBox;
