import React from "react";
import styles from "./Stars.module.scss";

import StartIcon from "assets/svg/star.svg";
import StartWhiteIcon from "assets/svg/star-white.svg";

interface Props {
  count: number;
  type: "default" | "white";
  size?: "default" | "small";
}

const Stars: React.FC<Props> = ({ count, type, size }) => {
  const stars = new Array(count)
    .fill(0)
    .map((value, index) => (
      <img
        alt={value}
        key={index}
        src={type === "white" ? StartWhiteIcon : StartIcon}
        width={size === "small" ? 12 : 16}
      />
    ));

  return <span className={styles.starsContainer}>{stars}</span>;
};

export default Stars;
