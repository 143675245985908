import firebase from "firebase/app";
import "firebase/firestore";
import config from "helpers/config";



// See: https://firebase.google.com/docs/web/learn-more#config-object
// const firebaseConfig = {
//   apiKey: config.REACT_APP_API_KEY,
//   authDomain: config.REACT_APP_AUTH_DOMAIN,
//   databaseURL: config.REACT_APP_DATABASE_URL,
//   projectId: config.REACT_APP_PROJECT_ID,
//   storageBucket: config.REACT_APP_STORAGE_BUCKET,
//   messagingSenderId: config.REACT_APP_MESSAGING_SENDER_ID,
//   appId: config.REACT_APP_APP_ID,
//   measurementId: config.REACT_APP_MEASUREMENT_ID
// };

const firebaseConfig = {
  apiKey: "",
  authDomain: "",
  databaseURL: "",
  projectId: "",
  storageBucket: "",
  messagingSenderId: "",
  appId: "",
  measurementId: ""
};

let client: any = null;

export const setFireBaseConfig = (
  apiKey: string,
  authDomain: string,
  databaseURL: string,
  projectId: string,
  storageBucket: string,
  messagingSenderId: string,
  appId: string,
  measurementId: string
) => {
  firebaseConfig.apiKey = apiKey;
  firebaseConfig.authDomain = authDomain;
  firebaseConfig.databaseURL = databaseURL;
  firebaseConfig.projectId = projectId;
  firebaseConfig.storageBucket = storageBucket;
  firebaseConfig.messagingSenderId = messagingSenderId;
  firebaseConfig.appId = appId;
  firebaseConfig.measurementId = measurementId;
  
};

// Initialize Cloud Firestore and get a reference to the service
export const firestore = () => {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  } else {
    firebase.app();
  }
  return firebase.firestore();
}
