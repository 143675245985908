import { Action, ActionType } from "./types";
import { getClient, systemChannelName } from "services/pubNub";
import { SystemMessage } from "store/system";

interface addDefaultListener {
  (hotelCode: string): void;
}

interface initMessages {
  (property: string): void;
}

export interface SystemActionTypes {
  addDefaultListener: addDefaultListener;
  initMessages: initMessages;
}

export const actionTypes = {
  DEFAULT_LISTENER_ADDED: "system/DEFAULT_LISTENER_ADDED",
  ADD_MESSAGE: "system/ADD_MESSAGE",
  FETCHING_MESSAGES: "system/FETCHING_MESSAGES",
  SET_MESSAGES: "system/SET_MESSAGES"
};

const mapSystemMessage = (item: any): SystemMessage => ({
  ...item.entry.data,
  timetoken: Math.floor(parseInt(item.timetoken) / 10000),
  meta: item.entry.meta,
  reservation: {
    ...item.entry.data.reservation,
    thisReservation: {
      ...item.entry.data.reservation.thisReservation,
      HasBreakfast: item.entry.data.reservation.HasBreakfast,
      Notes: item.entry.data.reservation.Notes.filter(
        (note: { OrderId: string }) =>
          note.OrderId === item.entry.data.reservation.thisReservation.Id
      )
    }
  }
});

const addDefaultListener: Action = (
  dispatch
): addDefaultListener => hotelCode => {
  getClient().addListener({
    message: (data: any) => {
      if (
        data.message.event !== "message" ||
        data.message.sender.indexOf("system") !== 0
      ) {
        return;
      }

      const message = mapSystemMessage({
        entry: {
          ...data.message
        },
        timetoken: data.timetoken
      });

      dispatch({
        type: actionTypes.ADD_MESSAGE,
        channel: data.channel,
        message
      });
    }
  });
  console.info("addDefaultListener system", systemChannelName(hotelCode));

  getClient().subscribe({
    channels: [systemChannelName(hotelCode)]
  });

  dispatch({
    type: actionTypes.DEFAULT_LISTENER_ADDED
  });
};

const getChannelMessageHistory = async (
  channel: string,
  count: number
): Promise<SystemMessage[]> => {
  return new Promise((resolve, reject) => {
    getClient().history(
      {
        channel,
        count
      },
      (status: {}, response: any) => {
        const messages = (response && "messages" in response
          ? response.messages
          : []
        ).map(mapSystemMessage);
        resolve(messages);
      }
    );
  });
};

const initMessages: Action = (dispatch): initMessages => async property => {
  
  dispatch({
    type: actionTypes.FETCHING_MESSAGES
  });

  const messages = await getChannelMessageHistory(
    systemChannelName(property),
    50
  );
  
  dispatch({
    type: actionTypes.SET_MESSAGES,
    messages
  });
};

const actions = (dispatch: React.Dispatch<ActionType>): SystemActionTypes => ({
  addDefaultListener: addDefaultListener(dispatch),
  initMessages: initMessages(dispatch)
});

export default actions;
