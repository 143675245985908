const notificationSound = new Audio(require("../assets/audio/alert.mp3"));

export const notify = async ({
  title,
  tag,
  icon,
  body,
  onClick
}: {
  title: string;
  tag: string | undefined;
  icon: string;
  body: string;
  onClick: () => void;
}) => {
  if ("Notification" in window && Notification.permission === "granted") {
    const notification = new Notification(title, {
      icon,
      body,
      tag, //Later updates replace old ones
      renotify: true, //But do get a notification
      requireInteraction: true //And all notifications wait till they get interaction, don't hide automatically
    });

    notification.onclick = function() {
      if (onClick) {
        onClick();
      }

      window.focus();
      this.close();
    };
  }

  try {
    await notificationSound.play();
  } catch (e) {
    console.error(
      "Catched notification error, probably no interaction with the app yet"
    );
    console.error(e);
  }
};
