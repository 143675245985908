import React, { MouseEvent } from "react";
import styles from "./ChatRoomStatusIcon.module.scss";
import { ChatRoomStatus } from "store/interface/chat";
import StatusCircleIcon from "assets/svg/status-circle.svg";
import StatusDoneIcon from "assets/svg/status-done.svg";
import StatusNewIcon from "assets/svg/status-new.svg";

interface Props {
  status: ChatRoomStatus;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
}

const ChatRoomStatusIcon: React.FC<Props> = props => {
  const { status, onClick } = props;
  
  return (
    <img
      className={styles.statusIcon}
      alt={status}
      onClick={onClick}
      src={
        status === "done"
          ? StatusDoneIcon
          : status === "new"
          ? StatusNewIcon
          : StatusCircleIcon
      }
    />
  );
};

export default ChatRoomStatusIcon;
