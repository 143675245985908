import { Action, ActionType } from "./types";
import {
  setActiveChatRoomInterface,
  activeBroadcastRoomId,
  hotelCode,
  ChatRoom,
  Message,
  filterValue,
  cleanState,
} from "../interface/chat";

import { firestore } from "services/firebase";
import { timestampMinusDays, dateMinusDays } from "helpers/date";

import { notify } from "services/notifications";

export interface ChatActionTypes {
  setActiveChatRoom: setActiveChatRoomInterface;
  setActiveBroadcastRoom: activeBroadcastRoomId;
  fetchChatRooms: hotelCode;
  fetchBroadCastRoom: hotelCode;
  setFilterChatRoomList: filterValue;
  cleanChatState: cleanState;
}

export const actionTypes = {
  FETCH_CHAT_ROOM_LIST: "chat/FETCH_CHAT_ROOM_LIST",
  FETCH_BROADCAST: "chat/FETCH_BROADCAST",
  SET_CHAT_ROOM_LIST_FILTER: "chat/SET_CHAT_ROOM_LIST_FILTER",
  SET_ACTIVE_CHAT_ROOM: "chat/SET_ACTIVE_CHAT_ROOM",
  SET_ACTIVE_BROADCAST_ROOM: "chat/SET_ACTIVE_BROADCAST_ROOM",
  CLEAN_STATE: "chat/CLEAN_STATE",
};

const cleanChatState: Action =
  (dispatch): cleanState =>
  () => {
    dispatch({
      type: actionTypes.CLEAN_STATE,
    });
  };

let unsubscribeMessages: any = null;

const setActiveChatRoom: Action =
  (dispatch): setActiveChatRoomInterface =>
  async (activeChatRoomId, hotelCode) => {
    if (unsubscribeMessages !== null) {
      unsubscribeMessages();
    }

    /**
     * Get messsages
     * Filter messages on hotelCode
     *
     */
    unsubscribeMessages = firestore()
      .collection("chatRooms")
      .doc(activeChatRoomId)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const activeChatRoom = doc.data();
          const chatRoomMessages = activeChatRoom?.messages.filter(
            (message: Message) => message.hotelCode === hotelCode
          );
          delete activeChatRoom?.messages;

          dispatch({
            type: actionTypes.SET_ACTIVE_CHAT_ROOM,
            activeChatRoomId,
            activeChatRoom,
            chatRoomMessages,
          });
        } else {
          console.log("No chat room found!");
        }
      });
  };

const setActiveBroadcastRoom: Action =
  (dispatch): activeBroadcastRoomId =>
  async (activeBroadcastRoomId) => {
    if (unsubscribeMessages !== null) {
      unsubscribeMessages();
    }

    // Get messages
    unsubscribeMessages = firestore()
      .collection("broadcastRooms")
      .doc(activeBroadcastRoomId)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const activeBroadcastRoom = doc.data();
          const chatRoomMessages = activeBroadcastRoom?.messages;
          delete activeBroadcastRoom?.messages;

          dispatch({
            type: actionTypes.SET_ACTIVE_BROADCAST_ROOM,
            activeBroadcastRoomId,
            activeBroadcastRoom,
            chatRoomMessages,
          });
        } else {
          console.log("No broadcast room found!");
        }
      });
  };

/**
 * Fetch chatrooms
 * where hotelCode is the same as host
 * where has message with the same hotelcode is less than 2 days
 * or where status is new or open
 */
const fetchChatRooms: Action =
  (dispatch): hotelCode =>
  async (hotelCode) => {
    const activeHotelCode = hotelCode;
    const showMessagesFromPeriod = timestampMinusDays(7);
    let lastMessage = {
      chatRoomId: undefined as string | undefined,
      message: null as Message | null,
    };

    firestore()
      .collection("chatRooms")
      .where("hotelCode", "==", hotelCode)
      .where("updatedAt", ">=", showMessagesFromPeriod)
      // .where("booking", "!=", "")
      .onSnapshot((querySnapshot) => {
        let chatRoomList: Array<ChatRoom> = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();

          if (data) {
            let booking = false;
            if (data.booking) {
              booking =
                data.booking.checkedIn &&
                data.booking.checkIn >= dateMinusDays(2);
            }

            let lastMessages = [];
            if (data.messages && data.messages.length) {
              // Get messages with the same hotel code
              data.messages = data.messages.filter((message: Message) => {
                return message.hotelCode === hotelCode;
              });
              // Get messages which are not older than 2 days
              lastMessages = data.messages.filter((message: Message) => {
                return message.timetoken >= showMessagesFromPeriod;
              });

              // Get the latest message
              if (
                lastMessages.length &&
                (lastMessage.message === null ||
                  (lastMessage &&
                    lastMessage.message &&
                    lastMessage.message.timetoken <
                      lastMessages[lastMessages.length - 1].timetoken))
              ) {
                lastMessage.chatRoomId = doc.id;
                lastMessage.message = lastMessages[lastMessages.length - 1];
              }

              // Where status is new or open
              // const activeStatus = data.status !== 'done'; // not used

              if (lastMessages.length) {
                chatRoomList.push({ ...{ id: doc.id }, ...data } as ChatRoom);
              }
            }
          }
        });
        console.log("Chat messages loaded");

        dispatch({
          type: actionTypes.FETCH_CHAT_ROOM_LIST,
          chatRoomList,
          activeHotelCode,
          lastMessage,
        });
      });
  };

// Set broadcast messages
const fetchBroadCastRoom: Action =
  (dispatch): hotelCode =>
  async (hotelCode) => {
    if (hotelCode) {
      const docRef = firestore()
        .collection("broadcastRooms")
        .doc(hotelCode)
        .onSnapshot((doc) => {
          if (doc.exists) {
            const data = doc.data();
            if (data && data.messages) {
              const broadcastMessages = data.messages;
              dispatch({
                type: actionTypes.FETCH_BROADCAST,
                broadcastMessages,
              });
            }
          }
        });
    }
  };

const setFilterChatRoomList: Action =
  (dispatch): filterValue =>
  async (key: string, value: string) => {
    dispatch({
      type: actionTypes.SET_CHAT_ROOM_LIST_FILTER,
      key,
      value,
    });
  };

const actions = (dispatch: React.Dispatch<ActionType>): ChatActionTypes => ({
  setActiveChatRoom: setActiveChatRoom(dispatch),
  setActiveBroadcastRoom: setActiveBroadcastRoom(dispatch),
  fetchChatRooms: fetchChatRooms(dispatch),
  fetchBroadCastRoom: fetchBroadCastRoom(dispatch),
  setFilterChatRoomList: setFilterChatRoomList(dispatch),
  cleanChatState: cleanChatState(dispatch),
});

export default actions;
