//@ts-ignore
import PubNub from "pubnub";

const config = {
  publishKey: "",
  subscribeKey: "",
  authKey: "",
  uuid: ""
};

let client: any = null;

export const setPubNubKeys = (
  publishKey: string,
  subscribeKey: string,
  authKey: string,
  uuid: string
) => {
  config.publishKey = publishKey;
  config.subscribeKey = subscribeKey;
  config.authKey = authKey;
  config.uuid = uuid;
};

export const getClient = () => {
  if (!client) {
    client = new PubNub(config);
  }
  return client;
};

export const reservationChannelName = (reservationId: string) => {
  return `RES_${reservationId}`;
};

export const pubNubChatRoomChannelName = (chatRoomName: string) => {
  return `chat-engine#chat#public.#${chatRoomName}`;
};

export const systemChannelName = (property: string) => {
  return `SYSTEM_${property}`;
};

export const broadcastChannelName = (property: string) => {
  return `BROADCAST_${property}`;
};

export const getReservationIdFromPubNubChannelName = (channel: string) => {
  return channel.slice(channel.indexOf("RES_") + 4);
};
