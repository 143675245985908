import React, { useState, useContext } from "react";
import styles from "./Documents.module.scss";
import Sidebar from "components/layouts/Sidebar";
import Paragraph from "components/elements/Paragraph";
import { Context as AuthorizationContext } from "../../../store/authorization";

interface Props {}

const Documents: React.FC<Props> = props => {
  const { state: authorizationState } = useContext(AuthorizationContext);
  const { hotelProperties, authorizedHotelCode } = authorizationState;
  const [url, setUrl] = useState<string | null>(null);

  if (!authorizedHotelCode) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <Sidebar activeLink="documents" />
      <div className={styles.contentWrapper}>
        <div className={styles.header}>
          <Paragraph medium strong>
            Documents
          </Paragraph>
          <div className={styles.menu}>
            {hotelProperties[authorizedHotelCode].documentMenuItems.map(item => (
              <Paragraph key={item.url}>
                <a
                  href={item.url}
                  onClick={e => {
                    console.log("Click link");
                    e.preventDefault();
                    setUrl(item.url);
                  }}
                >
                  <span>{item.label}</span>
                </a>
              </Paragraph>
            ))}
          </div>
        </div>
        {url && <iframe title="CityHub document" className={styles.content} src={url} />}
      </div>
    </div>
  );
};

export default Documents;
