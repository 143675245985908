import React, { useContext } from "react";
import styles from "./HostLocations.module.scss";
import Sidebar from "components/layouts/Sidebar";
import Paragraph from "components/elements/Paragraph";
import { Context as AuthorizationContext } from "../../../store/authorization";

interface Props {}

const HostLocations: React.FC<Props> = props => {
  const { state: authorizationState } = useContext(AuthorizationContext);
  const { hotelProperties, authorizedHotelCode } = authorizationState;

  if (!authorizedHotelCode) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <Sidebar activeLink="hostLocations" />
      <div className={styles.contentWrapper}>
        <div className={styles.header}>
          <Paragraph medium strong>
            Locations
          </Paragraph>
        </div>
        <iframe
          title="CityHub locations"
          className={styles.content}
          src={hotelProperties[authorizedHotelCode].locationsUrl}
        />
      </div>
    </div>
  );
};

export default HostLocations;
