import React from "react";
import styles from "./Avatar.module.scss";
import AvatarIcon from "assets/svg/avatar.svg";
import CrownImage from "assets/img/crown.png";
import SunglassesImage from "assets/img/sunglasses.png";
import LazyLoad from "react-lazyload";

interface Props {
  src?: string | null;
  alt: string;
  number?: string;
  type?: "default" | "alternative" | "secondary" | "special";
  size?: "default" | "medium";
  crown?: boolean;
  sunglasses?: boolean;
  lazyLoadScrollContainer?: string;
}

const Avatar: React.FC<Props> = (props) => {
  const {
    src,
    alt,
    number,
    type,
    size,
    crown,
    sunglasses,
    lazyLoadScrollContainer,
  } = props;
  const wrapperClasses = [styles.wrapper];

  if (size === "medium") {
    wrapperClasses.push(styles.wrapperMedium);
  }

  if (type === "special") {
    wrapperClasses.push(styles.wrapperSpecial);
  }

  if (type === "alternative") {
    wrapperClasses.push(styles.wrapperAlternative);
  }

  if (type === "secondary") {
    wrapperClasses.push(styles.wrapperSecondary);
  }

  if (type === "default") {
    wrapperClasses.push(styles.wrapperDefault);
  }

  if (number) {
    return (
      <div className={wrapperClasses.join(" ")}>
        <div className={styles.number}>{number}</div>
      </div>
    );
  }

  return (
    <div className={styles.mainWrapper}>
      {crown && <img className={styles.crown} alt="Admin" src={CrownImage} />}
      {sunglasses && (
        <img
          className={styles.sunglasses}
          alt="Watcher"
          src={SunglassesImage}
        />
      )}
      <figure className={wrapperClasses.join(" ")}>
        {lazyLoadScrollContainer && (
          <LazyLoad
            height={90}
            offset={100}
            scrollContainer={lazyLoadScrollContainer}
          >
            <img src={src ? src : AvatarIcon} alt={alt} />
          </LazyLoad>
        )}
        {!lazyLoadScrollContainer && (
          <img src={src ? src : AvatarIcon} alt={alt} />
        )}
      </figure>
    </div>
  );
};

export default Avatar;
