import React, { useReducer } from "react";
import { Switch, Route } from "react-router-dom";
import styles from "./App.module.scss";
import NotFound from "../containers/NotFound";
import {
  initialState,
  reducer,
  Context,
  actions,
} from "../../store/authorization";
import PrivateRoute from "../elements/PrivateRoute/PrivateRoute";
import Signin from "../containers/Signin";
import Chat from "components/containers/Chat";
import WifiBox from "components/containers/WifiBox";
import WifiBoxAdd from "components/containers/WifiBoxAdd";
import Admin from "components/containers/Admin";
import {
  initialState as chatInitialState,
  reducer as chatReducer,
  Context as ChatContext,
  actions as chatActions,
} from "../../store/chat";
import {
  initialState as systemInitialState,
  reducer as systemReducer,
  Context as SystemContext,
  actions as systemActions,
} from "../../store/system";
import {
  initialState as customerInitialState,
  reducer as customerReducer,
  Context as CustomerContext,
  actions as customerActions,
} from "../../store/reservation";
import {
  initialState as wifiBoxInitialState,
  reducer as wifiBoxReducer,
  Context as WifiBoxContext,
  actions as wifiBoxActions,
} from "../../store/wifiBox";
import {
  initialState as notificationInitialState,
  reducer as notificationReducer,
  Context as NotificationContext,
  actions as notificationActions,
} from "../../store/notification";
import {
  initialState as locationsInitialState,
  reducer as locationsReducer,
  Context as LocationsContext,
  actions as locationsActions,
} from "../../store/locations";
import ChatNotifier from "components/elements/Chat/ChatNotifier";
import Documents from "components/containers/Documents";
import Wristband from "components/containers/Wristband";
import Locations from "components/containers/Locations";
import HostLocations from "components/containers/HostLocations";
import Unauthorized from "components/containers/Unauthorized";
import Analytics from "components/containers/Analytics";

const App: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [systemState, systemDispatch] = useReducer(
    systemReducer,
    systemInitialState
  );
  const [chatState, chatDispatch] = useReducer(chatReducer, chatInitialState);
  const [customerState, customerDispatch] = useReducer(
    customerReducer,
    customerInitialState
  );
  const [wifiBoxState, wifiBoxDispatch] = useReducer(
    wifiBoxReducer,
    wifiBoxInitialState
  );
  const [notificationState, notificationDispatch] = useReducer(
    notificationReducer,
    notificationInitialState
  );
  const [locationsState, locationsDispatch] = useReducer(
    locationsReducer,
    locationsInitialState
  );

  return (
    <div className={styles.wrapper}>
      <Context.Provider
        value={{
          state,
          ...actions(dispatch),
        }}
      >
        <SystemContext.Provider
          value={{
            state: systemState,
            ...systemActions(systemDispatch),
          }}
        >
          <WifiBoxContext.Provider
            value={{
              state: wifiBoxState,
              ...wifiBoxActions(wifiBoxDispatch),
            }}
          >
            <ChatContext.Provider
              value={{
                state: chatState,
                ...chatActions(chatDispatch),
              }}
            >
              <CustomerContext.Provider
                value={{
                  state: customerState,
                  ...customerActions(customerDispatch),
                }}
              >
                <NotificationContext.Provider
                  value={{
                    state: notificationState,
                    ...notificationActions(notificationDispatch),
                  }}
                >
                  <LocationsContext.Provider
                    value={{
                      state: locationsState,
                      ...locationsActions(locationsDispatch),
                    }}
                  >
                    <PrivateRoute
                      redirectIfLoggedOut={false}
                      roles={["default"]}
                      path="/"
                      component={ChatNotifier}
                    />

                    <Switch>
                      <PrivateRoute
                        roles={["default"]}
                        exact
                        path="/"
                        component={Chat}
                      />
                      <Route exact path="/signin" component={Signin} />
                      <PrivateRoute
                        roles={["default"]}
                        exact
                        path="/wifiBox"
                        component={WifiBox}
                      />
                      <PrivateRoute
                        roles={["default"]}
                        exact
                        path="/wifiBox/add"
                        component={WifiBoxAdd}
                      />
                      <PrivateRoute
                        roles={["default"]}
                        exact
                        path="/hostLocations"
                        component={HostLocations}
                      />
                      <PrivateRoute
                        roles={["admin"]}
                        exact
                        path="/admin"
                        component={Admin}
                      />
                      <PrivateRoute
                        roles={["default"]}
                        exact
                        path="/documents"
                        component={Documents}
                      />
                      <PrivateRoute
                        roles={["default"]}
                        exact
                        path="/wristband"
                        component={Wristband}
                      />
                      <PrivateRoute
                        roles={["default"]}
                        exact
                        path="/analytics"
                        component={Analytics}
                      />
                      <PrivateRoute
                        roles={["default", "mapScreen"]}
                        path="/locations"
                        component={Locations}
                      />
                      <PrivateRoute
                        roles={["admin", "default", "mapScreen"]}
                        path="/unauthorized"
                        component={Unauthorized}
                      />
                      <Route component={NotFound} />
                    </Switch>
                  </LocationsContext.Provider>
                </NotificationContext.Provider>
              </CustomerContext.Provider>
            </ChatContext.Provider>
          </WifiBoxContext.Provider>
        </SystemContext.Provider>
      </Context.Provider>
    </div>
  );
};

export default App;
