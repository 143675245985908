import React, { useContext, useEffect } from "react";
import styles from "./HostForm.module.scss";
import { Context as HostContext } from "../../../../store/host";
import { Context as WifiBoxContext } from "../../../../store/wifiBox";
import { Context as AuthorizationContext } from "../../../../store/authorization";
import Paragraph from "components/elements/Paragraph";
import Button from "components/elements/Button";
import ImageCrop from "components/elements/ImageCrop";
import { handleInputChange } from "helpers/form";
import Input from "components/elements/Input";
import H1 from "components/elements/H1";

interface Props {}

const HostForm: React.FC<Props> = (props) => {
  const {
    state: hostState,
    removeHostToken,
    resetForm,
    setFormValue,
    saveForm,
    subscribeToScanner,
    unsubscribeFromScanner,
  } = useContext(HostContext);
  const { state: wifiBoxState } = useContext(WifiBoxContext);
  const { state: authorizationState } = useContext(AuthorizationContext);
  const { authorizedHotelCode } = authorizationState;

  useEffect(() => {
    if (authorizedHotelCode) {
      subscribeToScanner(authorizedHotelCode);
    }

    return () => {
      if (authorizedHotelCode) {
        unsubscribeFromScanner(authorizedHotelCode);
      }
    };
  }, [authorizedHotelCode]); // eslint-disable-line react-hooks/exhaustive-deps

  const { loadingTokenInfoProcessing, loadingTokenInfoError } = wifiBoxState;
  const { error, values, errors, saving, saved } = hostState.form;

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <H1>Add CityHost</H1>
      </div>
      {error && <Paragraph error>Failed save try again.</Paragraph>}
      {saving && <Paragraph>Saving...</Paragraph>}
      {saved && <Paragraph success>Saved</Paragraph>}

      {loadingTokenInfoProcessing && (
        <Paragraph>Loading token info...</Paragraph>
      )}
      {loadingTokenInfoError && (
        <Paragraph error>Error loading token info</Paragraph>
      )}
      {!saving && (
        <div>
          <div>
            <Input
              value={values.firstName}
              onChange={handleInputChange(setFormValue)}
              id="firstName"
              name="firstName"
              type="text"
              placeholder="Name"
            />
            {errors.firstName && (
              <Paragraph error>{errors.firstName}</Paragraph>
            )}
          </div>
          <div>
            <Input
              value={values.mewsID}
              onChange={handleInputChange(setFormValue)}
              name="mewsID"
              id="mewsID"
              type="text"
              placeholder="Mews ID"
            />
            {errors.mewsID && <Paragraph error>{errors.mewsID}</Paragraph>}
          </div>
          <div>
            <label className={styles.label}>Avatar</label>
            {values.avatar && (
              <div className={styles.imageWrapper}>
                <img
                  alt="Avatar"
                  width="250"
                  src={
                    typeof values.avatar === "string"
                      ? values.avatar
                      : URL.createObjectURL(values.avatar)
                  }
                />
                <div className={styles.buttons}>
                  <div>
                    <Button
                      contrast
                      onClick={() => setFormValue("avatar", null)}
                    >
                      Change
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {!values.avatar && (
              <ImageCrop
                onChange={(imageBlob) => setFormValue("avatar", imageBlob)}
              />
            )}
          </div>

          <div>
            <label className={styles.label} htmlFor="gif">
              GIF
            </label>
            {values.gif && (
              <div className={styles.imageWrapper}>
                <img
                  alt="gif"
                  width="250"
                  src={
                    typeof values.gif === "string"
                      ? values.gif
                      : URL.createObjectURL(values.gif)
                  }
                />
                <div className={styles.buttons}>
                  <div>
                    <Button contrast onClick={() => setFormValue("gif", null)}>
                      Change
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {!values.gif && (
              <input
                className={styles.fileInput}
                id="gif"
                type="file"
                name="gif"
                accept="image/gif"
                onChange={(event: React.FormEvent<HTMLInputElement>) =>
                  //@ts-ignore https://github.com/microsoft/TypeScript/issues/31816
                  setFormValue("gif", event.target.files[0])
                }
              />
            )}
          </div>

          {values.token && !values.mewsID && (
            <Paragraph error>
              Scanned wristband, need a Mews ID to connect.
            </Paragraph>
          )}
          {values.token && values.mewsID && (
            <>
              {values.tokenInput === "scanner" && (
                <Paragraph success>
                  Scanned wristband, will be attached to the host after saving
                  the form.
                </Paragraph>
              )}
              {values.tokenInput === "api" && (
                <Paragraph success>Host has wristband.</Paragraph>
              )}
              {values.hostId && (
                <div className={styles.buttons}>
                  <div>
                    <Button onClick={() => removeHostToken(values.mewsID)}>
                      Remove wristband from host
                    </Button>
                  </div>
                </div>
              )}
            </>
          )}
          <div className={styles.buttons}>
            <div>
              <Button onClick={() => saveForm(values)}>
                {values.hostId ? "Update" : "Create"}
              </Button>
            </div>
            <div>
              <Button contrast onClick={resetForm}>
                Reset
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HostForm;
