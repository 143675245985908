import React from "react";
import styles from "./SelectedPlace.module.scss";
import Paragraph from "../Paragraph";
import Button from "../Button";

import CloseIcon from "assets/svg/close.svg";
import Stars from "../Stars";

interface Props {
  type: "map" | "form" | "message";
  imgSrc: string;
  title?: string;
  address?: string;
  rating?: number;
  onClose?: () => void;
  onSelect?: () => void;
}

const SelectedPlace: React.FC<Props> = props => {
  const { imgSrc, title, address, rating, onClose, onSelect, type } = props;
  
  const wrapperStyles = [styles.wrapper];

  type === "map" && wrapperStyles.push(styles.wrapperMap);
  type === "form" && wrapperStyles.push(styles.wrapperForm);
  type === "message" && wrapperStyles.push(styles.wrapperMessage);

  return (
    <div className={wrapperStyles.join(" ")}>
      {type === "message" ? (
        <div
          className={styles.image}
          style={{ backgroundImage: `url(${imgSrc})` }}
        />
      ) : imgSrc ? (
        <img alt={title} className={styles.image} src={imgSrc} />
      ) : (
        <div className={[styles.image, styles.fallbackImage].join(" ")} />
      )}
      <div className={styles.description}>
        <Paragraph className={styles.title}>
          {title}&nbsp;
          <Stars
            type={type === "message" ? "white" : "default"}
            count={Math.round(rating ? rating : 0)}
          />
        </Paragraph>
        {address && (
          <Paragraph className={styles.subtitle} small>
            {address}
          </Paragraph>
        )}
      </div>
      <div className={styles.buttons}>
        {onClose && (
          <img
            alt="Close"
            onClick={onClose}
            className={styles.closeButton}
            src={CloseIcon}
          />
        )}
        {onSelect && <Button onClick={onSelect}>Select</Button>}
      </div>
    </div>
  );
};

export default SelectedPlace;
