import React, { useState, useEffect } from "react";
import styles from "./Drawer.module.scss";

const Drawer: React.FC = ({ children }) => {
  const wrapperStyles = [styles.wrapper];
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 0);
  });

  if (show) {
    wrapperStyles.push(styles.show);
  }
  return <div className={wrapperStyles.join(" ")}>{children}</div>;
};

export default Drawer;
