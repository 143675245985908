import React, { useContext, useEffect, useState } from "react";
import styles from "./SystemMessages.module.scss";
import {
  Context as SystemContext,
  State as SystemState,
} from "../../../../store/system";
import { Context as AuthorizationContext } from "../../../../store/authorization";
import { Context as WifiBoxContext } from "../../../../store/wifiBox";
import SystemMessage from "../SystemMessage/SystemMessage";
import ArrowUpIcon from "assets/svg/arrow-up.svg";
import Paragraph from "components/elements/Paragraph";

interface Props {}

const SystemMessages: React.FC<Props> = (props) => {
  const {
    state: systemState,
    addDefaultListener,
    initMessages,
  } = useContext(SystemContext);
  const { openWifiBoxModal } = useContext(WifiBoxContext);

  const { state: authorizationState } = useContext(AuthorizationContext);
  const [newMessages, setNewMessages] = useState<SystemState["messages"]>([]);
  const [expandedMessage, setExpendedMessage] = useState<number | null>(null);
  const { messages } = systemState;
  const scrollWrapperRef = React.createRef<HTMLDivElement>();
  const sortedMessages = messages.sort((a, b) => b.timetoken - a.timetoken);

  useEffect(() => {
    if (authorizationState.authorizedHotelCode) {
      addDefaultListener(authorizationState.authorizedHotelCode);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (authorizationState.authorizedHotelCode) {
      initMessages(authorizationState.authorizedHotelCode);
    }
    if (scrollWrapperRef.current !== null) {
      scrollWrapperRef.current.addEventListener("scroll", (event) => {
        const target = event.target as HTMLDivElement;
        if (target.scrollTop === 0) {
          setNewMessages([]);
        }
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!scrollWrapperRef.current || sortedMessages.length === 0) {
      return;
    }

    if (scrollWrapperRef.current.scrollTop !== 0) {
      setNewMessages([...newMessages, sortedMessages[0]]);
    }

    if (scrollWrapperRef.current.scrollTop === 0) {
      setExpendedMessage(sortedMessages[0].timetoken);
    }
  }, [sortedMessages]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.wrapper}>
      {newMessages.length > 0 && (
        <div
          className={styles.newMessagesWrapper}
          onClick={() => {
            if (scrollWrapperRef.current) {
              scrollWrapperRef.current.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              setExpendedMessage(newMessages[0].timetoken);
            }
          }}
        >
          <div>
            <Paragraph>
              {newMessages.length} new{" "}
              {newMessages.length === 1
                ? newMessages[0].MessageType === "SYSTEM_MESSAGE_CHECKIN"
                  ? "check in"
                  : "check out"
                : "events"}
              !
            </Paragraph>
          </div>
          <img alt="Show less" src={ArrowUpIcon} />
        </div>
      )}
      <div className={styles.scrollWrapper} ref={scrollWrapperRef}>
        {sortedMessages.map((message, index) => (
          <SystemMessage
            key={message.timetoken}
            message={message}
            expanded={
              (expandedMessage === null && index === 0) ||
              expandedMessage === message.timetoken
            }
            toggleExpanded={(expanded) => {
              setExpendedMessage(expanded ? message.timetoken : 0);
            }}
            hotelCode={authorizationState.authorizedHotelCode}
            // addWifiBox={openWifiBoxModal}
          />
        ))}
      </div>
    </div>
  );
};

export default SystemMessages;
