import React, { useContext, useEffect } from "react";
import { Context as AuthorizationContext } from "../../../../store/authorization";
import { getWebSocketClient } from "services/webSocket";
import { getMewsCustomerByTokenId } from "helpers/mews";
import { hotelApi } from "services/api";

interface Props {}

const ChatKioskPlaces: React.FC<Props> = (props) => {
  const { state: authorizationState } = useContext(AuthorizationContext);
  const { authorizedHotelCode } = authorizationState;

  const subscribeToScanner = (hotelCode: string) => {
    const onMessage = async (message: { TokenId?: string }) => {
      if (!("TokenId" in message)) {
        return;
      }

      const getGuest = await getMewsCustomerByTokenId(
        message.TokenId as string
      );

      console.log(getGuest);

      // Send message to pupnub via hotel api
      if (getGuest) {
        try {
          hotelApi({
            path: "/reservation/checkguest",
            params: {
              ReservationId: getGuest.ReservationId,
            },
          });
        } catch (e) {
          console.error(e);
        }
      }
    };

    const socket = getWebSocketClient(hotelCode);
    socket.onmessage = (event) => {
      console.log("Received message from websocket", event);
      const message = JSON.parse(event.data);
      console.log("Passing message", message);
      onMessage(message);
    };
  };

  const unsubscribeFromScanner = (hotelCode: string) => {
    const socket = getWebSocketClient(hotelCode);
    socket.close();
  };

  useEffect(() => {
    if (authorizedHotelCode) {
      subscribeToScanner(authorizedHotelCode);
    }

    return () => {
      if (authorizedHotelCode) {
        unsubscribeFromScanner(authorizedHotelCode);
      }
    };
  }, [authorizedHotelCode]); // eslint-disable-line react-hooks/exhaustive-deps
  return <div></div>;
};

export default ChatKioskPlaces;
