import React, { useEffect, useState } from "react";
import styles from "./ChatMessageLocation.module.scss";
import { getAddressFromCoordinates } from "services/google";
import LocationIcon from "assets/svg/location.svg";
import Paragraph from "components/elements/Paragraph";
import { Message } from "store/interface/chat";

interface Props {
  lat: number;
  lng: number;
  onClickLocation: (location: Message["location"]) => void;
}

const ChatMessageLocation: React.FC<Props> = props => {
  const { lat, lng, onClickLocation } = props;

  const [address, setAddress] = useState<null | string>(null);

  useEffect(() => {
    try {
      getAddressFromCoordinates(lat, lng).then(address => setAddress(address));
    } catch (e) {
      console.error(e);
    }
  }, [lat, lng]);

  if (!address) {
    return null;
  }

  return (
    <div
      className={styles.wrapper}
      onClick={() =>
        onClickLocation({
          lat,
          lng
        })
      }
    >
      <Paragraph small>
        <img src={LocationIcon} alt="Location" />
        &nbsp;
        <span>{address}</span>
      </Paragraph>
    </div>
  );
};

export default ChatMessageLocation;
