import React, { MouseEvent, useContext } from "react";
import styles from "./ChatListItem.module.scss";
import Avatar from "components/elements/Avatar";
import Paragraph from "components/elements/Paragraph";
import { ChatRoom } from "store/interface/chat";
import ChatRoomStatusIcon from "components/elements/ChatRoomStatusIcon";
import { fromNowOrNow } from "helpers/date";
import { Context as ChatContext } from "../../../../store/chat";

interface Props {
  chatRoom: ChatRoom
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  lazyLoadScrollContainer?: string;
}

const ChatListItem: React.FC<Props> = props => {
  const {
    chatRoom,
    onClick,
    lazyLoadScrollContainer
  } = props;

  const {
    state: chatState,
  } = useContext(ChatContext);
  const { activeChatRoomId } = chatState;

  const classes = [styles.wrapper];

  if (activeChatRoomId === chatRoom.id) {
    classes.push(styles.active);
  }
  
  const lastMessage = chatRoom.messages ? chatRoom.messages[chatRoom.messages.length - 1] : false;


  return (
    <div className={classes.join(" ")} onClick={onClick}>
        <div className={styles.hub}>
          <ChatRoomStatusIcon status={chatRoom.status} />
          {chatRoom.booking && chatRoom.booking.hubNumber && (
            <>
            {/* <Paragraph small>{chatRoom.booking.property}</Paragraph> */}
            <Paragraph small>HUB</Paragraph>
            <Paragraph strong>{chatRoom.booking.hubNumber}</Paragraph>
            </>
          )}
        </div>
        <div className={styles.avatar}>
          <Avatar
            src={chatRoom.owner.picture}
            alt={chatRoom.owner.firstName}
            lazyLoadScrollContainer={lazyLoadScrollContainer}
            />
        </div>
      <div className={styles.message}>
        <Paragraph medium maxLines={1}>
          {chatRoom.owner.firstName}
        </Paragraph>
        {lastMessage && (
          <>
            <Paragraph maxLines={2}>
              {lastMessage.messageType.indexOf("HOST_") === 0 && (
                <strong>{lastMessage.sender.firstName}: </strong>
              )}
              {lastMessage.text}
            </Paragraph>
            <Paragraph small>{fromNowOrNow(lastMessage.timetoken)}</Paragraph>
          </>
        )}
      </div>
    </div>
  );
};

export default ChatListItem;
