import React, { useContext, useEffect } from "react";
import styles from "./WifiBoxAddForm.module.scss";
import Paragraph from "components/elements/Paragraph";
import { Context as WifiBoxContext } from "../../../../store/wifiBox";
import { Context as AuthorizationContext } from "../../../../store/authorization";
import Button from "components/elements/Button";

interface Props {}

const WifiBoxAddForm: React.FC<Props> = (props) => {
  const {
    state: wifiBoxState,
    setFormBoxNumber,
    setFormWifiBox,
    add,
    reset,
    subscribeToScanner,
    unsubscribeFromScanner,
  } = useContext(WifiBoxContext);
  const { state: authorizationState } = useContext(AuthorizationContext);

  const {
    addError: error,
    addProcessing: processing,
    addProcessed: processed,
  } = wifiBoxState;
  const { authorizedHost, authorizedHotelCode } = authorizationState;
  const { boxNumber, wifiBox } = wifiBoxState.form;
  const boxNumberInputRef = React.createRef<HTMLInputElement>();

  useEffect(() => {
    if (boxNumberInputRef.current) {
      boxNumberInputRef.current.focus();
    }
    reset();
    if (authorizedHotelCode) {
      subscribeToScanner(authorizedHotelCode, true);
    }

    return () => {
      if (authorizedHotelCode) {
        unsubscribeFromScanner(authorizedHotelCode);
      }
    };
  }, [authorizedHotelCode]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!authorizedHost) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      {error && <Paragraph error>Failed to add, try again.</Paragraph>}
      {processing && <Paragraph>Adding...</Paragraph>}
      {processed && <Paragraph success>Successfully added</Paragraph>}
      {!processing && (
        <div>
          <div>
            <label className={styles.label} htmlFor="boxNumber">
              Box Number
            </label>
            <input
              value={boxNumber}
              onChange={(event) => setFormBoxNumber(event.target.value)}
              ref={boxNumberInputRef}
              className={styles.textField}
              id="boxNumber"
              type="number"
              placeholder="Enter box number"
            />
          </div>
          <div>
            <label className={styles.label} htmlFor="wifi-box">
              Wifi Box
            </label>
            <input
              value={wifiBox}
              onChange={(event) =>
                setFormWifiBox({ value: event.target.value })
              }
              className={styles.textField}
              id="wifi-box"
              type="text"
              placeholder="Scan wifi box"
            />
          </div>
          {wifiBox && boxNumber && (
            <Button onClick={() => add(authorizedHost.id, wifiBox, boxNumber)}>
              Add
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default WifiBoxAddForm;
