import React, { useContext } from "react";
import styles from "./ChatMessagesHeader.module.scss";
import Paragraph from "components/elements/Paragraph";
import ChatRoomStatusIcon from "components/elements/ChatRoomStatusIcon";
import { Context as ChatContext } from "../../../../store/chat";
import Button from "components/elements/Button";
import { firestore } from "services/firebase";
import moment from "moment";
import config from "helpers/config";
import { updateKioskPlaces } from "helpers/firebase";

interface Props {
  view: string;
  setView: (view: "messages" | "map") => void;
}

const ChatMessagesHeader: React.FC<Props> = (props) => {
  const { state: chatState } = useContext(ChatContext);
  const { activeChatRoomId, activeChatRoom } = chatState;

  const { view, setView } = props;

  // Update the chat room status
  const setChatRoomStatus = (
    activeChatRoomId: string | undefined,
    status: string
  ) => {
    const docRef = firestore().collection("chatRooms").doc(activeChatRoomId);
    docRef.update({
      status: status,
    });
  };

  return (
    <div className={styles.wrapper}>
      {activeChatRoom && (
        <div>
          <div className={styles.nameStatusWrapper}>
            {activeChatRoom && (
              <Paragraph medium>{activeChatRoom.owner.firstName}</Paragraph>
            )}
          </div>

          {activeChatRoom &&
            activeChatRoom.hotelCode &&
            activeChatRoom.booking !== undefined &&
            activeChatRoom.booking.id !== undefined && (
              <div
                className={styles.toPlaces}
                onClick={() =>
                  updateKioskPlaces(
                    activeChatRoom.hotelCode,
                    activeChatRoom.booking?.id
                  )
                }
                title="Show guest on CityHub Places"
              >
                🗺️
              </div>
            )}
          {activeChatRoom && activeChatRoom.booking && (
            <a
              href={`${config.REACT_APP_MEWS_BASE_URL}/Commander/Reservation/Detail/${activeChatRoom.booking?.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {activeChatRoom.booking.number}
            </a>
          )}

          {activeChatRoom && "status" in activeChatRoom && (
            <ChatRoomStatusIcon
              status={activeChatRoom.status}
              onClick={() =>
                setChatRoomStatus(
                  activeChatRoomId,
                  activeChatRoom.status !== "done" ? "done" : "new"
                )
              }
            />
          )}
        </div>
      )}
      {activeChatRoom && activeChatRoom.booking && (
        <>
          <div>
            <Paragraph className={styles.metaInformation} small>
              <span
                aria-label="Check-in"
                role="img"
                className={styles.metaIcon}
              >
                🛬
              </span>
              {moment(activeChatRoom.booking.checkIn).format("DD MMM")}
              <span
                aria-label="Check-out"
                role="img"
                className={styles.metaIcon}
              >
                🛫
              </span>
              {moment(activeChatRoom.booking.checkOut).format("DD MMM")}
            </Paragraph>
          </div>
          {/* {activeChatRoom.reservation.notes && (
              <div>
                <Paragraph className={styles.notes} small>
                  {activeChatRoom.reservation.notes.map((note) => note).join(" / ")}
                </Paragraph>
              </div>
            )} */}
        </>
      )}
      {activeChatRoom && (
        <div className={styles.toggleWrapper}>
          <Button
            contrast={view !== "messages"}
            onClick={() => setView("messages")}
          >
            Chat
          </Button>
          <Button contrast={view !== "map"} onClick={() => setView("map")}>
            Map
          </Button>
        </div>
      )}
    </div>
  );
};

export default ChatMessagesHeader;
