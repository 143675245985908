import React, { useContext } from "react";
import styles from "./ChatBroadcastItem.module.scss";
import Avatar from "components/elements/Avatar";
import BroadcastIcon from "assets/svg/broadcast.svg";
import Paragraph from "components/elements/Paragraph";
import { fromNowOrNow } from "helpers/date";
import { Context as ChatContext } from "../../../../store/chat";
import { Context as AuthorizationContext } from "../../../../store/authorization";

interface Props {}

const ChatBroadcastItem: React.FC<Props> = props => {
  const {
    state: chatState,
    setActiveBroadcastRoom
  } = useContext(ChatContext);

  const { activeBroadcastRoomId, broadcastMessages } = chatState;

  const { state: authorizationState } = useContext(AuthorizationContext);

  const wrapperClassNames = [styles.wrapper];
  
  const lastMessage = broadcastMessages ? broadcastMessages[broadcastMessages.length - 1] : false;

  activeBroadcastRoomId && wrapperClassNames.push(styles.active);

  return (
    <div
      onClick={() => {
        if (activeBroadcastRoomId) {
          setActiveBroadcastRoom(undefined);
        }
        setTimeout(() => {
          if (authorizationState.authorizedHotelCode) {
            setActiveBroadcastRoom(authorizationState.authorizedHotelCode);
          }
        }, 0);
      }}
      className={wrapperClassNames.join(" ")}
    >
      <Avatar type="default" src={BroadcastIcon} alt="Broadcast" />
      <div className={styles.message}>
        {lastMessage && (
          <>
            <Paragraph maxLines={1} medium>
              Broadcast message
            </Paragraph>
            <Paragraph maxLines={2}>{lastMessage.text}</Paragraph>
            <Paragraph small>{fromNowOrNow(lastMessage.timetoken)}</Paragraph>
          </>
        )}
      </div>
    </div>
  );
};

export default ChatBroadcastItem;
