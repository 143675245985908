import React, { useReducer } from "react";
import styles from "./Admin.module.scss";
import Sidebar from "components/layouts/Sidebar";
import {
  initialState as hostInitialState,
  reducer as hostReducer,
  Context as HostContext,
  actions as HostActions,
} from "../../../store/host";
import HostList from "components/elements/Admin/HostList";
import HostForm from "components/elements/Admin/HostForm";

interface Props {}

const Admin: React.FC<Props> = (props) => {
  const [hostState, hostDispatch] = useReducer(hostReducer, hostInitialState);

  return (
    <HostContext.Provider
      value={{
        state: hostState,
        ...HostActions(hostDispatch),
      }}
    >
      <div className={styles.wrapper}>
        <Sidebar activeLink="admin" />
        <div className={styles.contentWrapper}>
          <div>
            <HostList />
          </div>
          <div>
            <HostForm />
          </div>
        </div>
      </div>
    </HostContext.Provider>
  );
};

export default Admin;
