import React from "react";
import reservationActions, {
  actionTypes,
  ReservationActionTypes,
} from "./actions/reservation";

export interface ReservationContext extends ReservationActionTypes {
  state: State;
}

export interface State {
  reservationsFetching: boolean;
  reservationsFetched: boolean;
  reservationsFetchingErrorCode: number | null;
  reservations: Reservation[];
  customers: Customer[];
}

export interface Reservation {
  Id: string;
  Number: string;
  Hub?: {
    Number: string;
  };
  State: "Started";
  StartUtc: string;
  EndUtc: string;
  Notes: Array<{
    OrderId: string;
    Text: string;
  }>;
  HasBreakfast: boolean;
}

export interface Customer {
  Id: string;
  ReservationId: string;
  FirstName: string;
  LastName: string;
  Email: string;
  Picture: string;
  Title: string;
  NationalityCode: string | null;
  Classifications: ["Returning", "LoyaltyProgram"];
}

export const initialState: State = {
  reservationsFetching: false,
  reservationsFetched: false,
  reservationsFetchingErrorCode: null,
  reservations: [],
  customers: [],
};

export const reducer = (
  state = initialState,
  action: { type: string; [key: string]: any }
): State => {
  switch (action.type) {
    case actionTypes.RESERVATIONS_FETCHING:
      return {
        ...state,
        reservationsFetching: true,
        reservationsFetchingErrorCode: null,
        reservationsFetched: false,
      };
    case actionTypes.RESERVATIONS_FETCHING_ERROR:
      return {
        ...state,
        reservationsFetching: false,
        reservationsFetchingErrorCode: action.errorCode,
      };
    case actionTypes.RESERVATIONS_FETCHED:
      return {
        ...state,
        reservations: action.reservations,
        reservationsFetching: false,
        reservationsFetched: true,
      };
    case actionTypes.CUSTOMERS_FETCHED:
      return {
        ...state,
        customers: action.customers,
      };
    default:
      console.error("No action provided to reducer");
      return state;
  }
};

export const Context = React.createContext<ReservationContext>({
  state: initialState,
  ...reservationActions(() => {
    console.error("Dispatch not implemented");
  }),
});

export const actions = reservationActions;
