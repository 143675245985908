import { Action, ActionType } from "./types";
import { getSocketIOClient, getWebSocketClient } from "services/webSocket";
import { hotelApi, mobileApi } from "services/api";
import { Customer } from "store/reservation";
import {
  reservationChannelName,
  pubNubChatRoomChannelName,
  getClient,
} from "services/pubNub";
import {
  getChannelMessageHistory,
  createMessage,
  getMessageType,
} from "helpers/pubnub";
import { getPlace } from "helpers/map";
import { Place } from "store/interface/chat";

interface subscribeToScanner {
  (hotelCode: string, onTokenScanned?: null | (() => void)): void;
}

interface unsubscribeFromScanner {
  (hotelCode: string): void;
}

interface getTokenInformation {
  (tokenId: string): void;
}

interface reset {
  (): void;
}

interface fetchReservationPlaces {
  (reservationId: string): void;
}

interface fetchSelectedPlace {
  (mapRef: google.maps.Map, placeId: string): void;
}

interface send {
  (text: string, hotelCode: string, place: Place, reservationId: string): void;
}

export interface LocationsActionTypes {
  subscribeToScanner: subscribeToScanner;
  unsubscribeFromScanner: unsubscribeFromScanner;
  reset: reset;
  fetchReservationPlaces: fetchReservationPlaces;
  fetchSelectedPlace: fetchSelectedPlace;
  send: send;
}

export const actionTypes = {
  LOCATIONS_LOADING_TOKEN_INFO_PROCESSING:
    "locations/LOCATIONS_LOADING_TOKEN_INFO_PROCESSING",
  LOCATIONS_LOADING_TOKEN_INFO_PROCESSED:
    "locations/LOCATIONS_LOADING_TOKEN_INFO_PROCESSED",
  LOCATIONS_LOADING_TOKEN_INFO_ERROR:
    "locations/LOCATIONS_LOADING_TOKEN_INFO_ERROR",
  LOCATIONS_RESET: "locations/LOCATIONS_RESET",
  LOCATIONS_LOADING_RESERVATION_LOCATIONS_PROCESSING:
    "locations/LOCATIONS_LOADING_RESERVATION_LOCATIONS_PROCESSING",
  LOCATIONS_LOADING_RESERVATION_LOCATIONS_PROCESSED:
    "locations/LOCATIONS_LOADING_RESERVATION_LOCATIONS_PROCESSED",
  LOCATIONS_LOADING_RESERVATION_LOCATIONS_ERROR:
    "locations/LOCATIONS_LOADING_RESERVATION_LOCATIONS_ERROR",
  LOCATIONS_LOADING_SELECTED_PLACE_PROCESSING:
    "locations/LOCATIONS_LOADING_SELECTED_PLACE_PROCESSING",
  LOCATIONS_LOADING_SELECTED_PLACE_PROCESSED:
    "locations/LOCATIONS_LOADING_SELECTED_PLACE_PROCESSED",
  LOCATIONS_LOADING_SELECTED_PLACE_ERROR:
    "locations/LOCATIONS_LOADING_SELECTED_PLACE_ERROR",
  LOCATIONS_SEND_PROCESSING: "locations/LOCATIONS_SEND_PROCESSING",
  LOCATIONS_SEND_PROCESSED: "locations/LOCATIONS_SEND_PROCESSED",
  LOCATIONS_SEND_ERROR: "locations/LOCATIONS_SEND_ERROR",
};

export const subscribeToScanner: Action =
  (dispatch): subscribeToScanner =>
  (hotelCode, onTokenScanned = null) => {
    const onMessage = (message: { TokenId?: string }) => {
      console.info("Scanned token", message);
      if (!("TokenId" in message)) {
        return;
      }

      getTokenInformation(dispatch)(message.TokenId);

      if (typeof onTokenScanned === "function") {
        onTokenScanned();
      }
    };

    // if (hotelCode === "CPH") {
    const socket = getWebSocketClient(hotelCode);
    socket.onmessage = (event) => {
      console.log("Received message from websocket", event);
      const message = JSON.parse(event.data);
      console.log("Passing message", message);
      onMessage(message);
    };
    // } else {
    //   const socket = getSocketIOClient();
    //   socket.on("Token", onMessage);
    // }
  };

export const unsubscribeFromScanner: Action =
  (dispatch): unsubscribeFromScanner =>
  (hotelCode) => {
    // if (hotelCode === "CPH") {
    const socket = getWebSocketClient(hotelCode);
    socket.close();
    // } else {
    //   const socket = getSocketIOClient();
    //   socket.off("Token");
    // }
  };

export const getTokenInformation: Action =
  (dispatch): getTokenInformation =>
  async (tokenId) => {
    dispatch({
      type: actionTypes.LOCATIONS_LOADING_TOKEN_INFO_PROCESSING,
    });

    try {
      const data = await hotelApi({
        path: `/reservation/token/?TokenId=${encodeURIComponent(tokenId)}`,
      });
      if (
        "Reservations" in data &&
        data.Reservations.length > 0 &&
        "Customers" in data &&
        data.Customers.length > 0
      ) {
        dispatch({
          type: actionTypes.LOCATIONS_LOADING_TOKEN_INFO_PROCESSED,
          reservation: data.Reservations[0],
          customers: data.Customers.filter(
            (customer: Customer) =>
              data.Reservations[0].CompanionIds.indexOf(customer.Id) !== -1
          ),
        });
      } else {
        dispatch({
          type: actionTypes.LOCATIONS_LOADING_TOKEN_INFO_ERROR,
        });
      }
    } catch (e) {
      dispatch({
        type: actionTypes.LOCATIONS_LOADING_TOKEN_INFO_ERROR,
      });
    }
  };

export const reset: Action =
  (dispatch): reset =>
  () =>
    dispatch({
      type: actionTypes.LOCATIONS_RESET,
    });

export const fetchReservationPlaces: Action =
  (dispatch): fetchReservationPlaces =>
  async (reservationId: string) => {
    dispatch({
      type: actionTypes.LOCATIONS_LOADING_RESERVATION_LOCATIONS_PROCESSING,
    });

    try {
      const channel = reservationChannelName(reservationId);

      const messages = await getChannelMessageHistory(channel, 50);

      const reservationPlaces = messages
        .filter((message) => !!message.place)
        .map((message) => message.place)
        .filter(
          (place, index, self) =>
            place &&
            self.findIndex(
              (item, itemIndex) => item && item.id === place.id
            ) === index
        );
      dispatch({
        type: actionTypes.LOCATIONS_LOADING_RESERVATION_LOCATIONS_PROCESSED,
        reservationPlaces,
      });
    } catch (e) {
      dispatch({
        type: actionTypes.LOCATIONS_LOADING_RESERVATION_LOCATIONS_ERROR,
      });
    }
  };

export const fetchSelectedPlace: Action =
  (dispatch): fetchSelectedPlace =>
  async (mapRef, placeId) => {
    dispatch({
      type: actionTypes.LOCATIONS_LOADING_SELECTED_PLACE_PROCESSING,
    });

    try {
      const place = await getPlace(mapRef, placeId);
      dispatch({
        type: actionTypes.LOCATIONS_LOADING_SELECTED_PLACE_PROCESSED,
        place,
      });
    } catch (e) {
      dispatch({
        type: actionTypes.LOCATIONS_LOADING_SELECTED_PLACE_ERROR,
      });
    }
  };

export const send: Action =
  (dispatch): send =>
  async (text, hotelCode, place, reservationId) => {
    dispatch({
      type: actionTypes.LOCATIONS_SEND_PROCESSING,
    });

    try {
      const type = "default";

      const host = await getCurrentHost(hotelCode);

      const messageType = getMessageType(type, place);
      const channels = [
        reservationChannelName(reservationId),
        pubNubChatRoomChannelName(reservationChannelName(reservationId)),
      ];

      channels.forEach((channel) => {
        const message = createMessage({
          channel,
          text,
          host,
          type,
          messageType,
          hotelCode,
          place,
          reservationId,
        });

        getClient().publish(message);
      });

      dispatch({
        type: actionTypes.LOCATIONS_SEND_PROCESSED,
      });
    } catch (error) {
      dispatch({
        type: actionTypes.LOCATIONS_SEND_ERROR,
        error,
      });
    }
  };

const getCurrentHost = async (hotelCode: string) => {
  const response = await mobileApi({
    path: `/host/current`,
    method: "GET",
    params: {
      HotelCode: hotelCode,
    },
  });

  return response;
};

const actions = (
  dispatch: React.Dispatch<ActionType>
): LocationsActionTypes => ({
  subscribeToScanner: subscribeToScanner(dispatch),
  unsubscribeFromScanner: unsubscribeFromScanner(dispatch),
  reset: reset(dispatch),
  fetchReservationPlaces: fetchReservationPlaces(dispatch),
  fetchSelectedPlace: fetchSelectedPlace(dispatch),
  send: send(dispatch),
});

export default actions;
