import React from "react";
import authorizationActions, {
  actionTypes,
  AuthorizationActionTypes,
} from "./actions/authorization";
import { setMobileApiSessionToken, setHotelApiKey } from "services/api";
import { setPubNubKeys } from "services/pubNub";
import { setFireBaseConfig } from "services/firebase";
import { analyticsInitialize } from "services/google";
import regionsAMS from "assets/locations/regions-AMS.json";
import regionsRTM from "assets/locations/regions-RTM.json";

export interface AuthorizationContext extends AuthorizationActionTypes {
  state: State;
}

interface State {
  addedStorageListener: boolean;
  signingIn: boolean;
  signingInError: string | null;
  authorizedHost: Host | null;
  authorizedHotelCode: string | null;
  authorizedKeys: {} | null;
  availableHostsFetching: boolean;
  availableHostsError: string | null;
  availableHosts: Host[];
  filteredHosts: Host[];
  signInForm: {
    values: {
      id: string | null;
      name: string;
      password: string;
      hotelCode: string;
    };
    errors: {};
  };
  hotelMapping: {
    [k: string]: string;
  };
  hotelProperties: {
    [k: string]: {
      coordinates: {
        lat: number;
        lng: number;
      };
      googleMapsRegion: string;
      documentMenuItems: Array<{
        label: string;
        url: string;
      }>;
      regions: any;
      locationsUrl: string;
      locationsDefaultOptions: {
        center: {
          lat: number;
          lng: number;
        };
        zoom: number;
      };
      hasWifiBoxes: boolean;
    };
  };
}

export interface Host {
  id: string;
  FirstName: string;
  CurrentHotelCode: string;
  DefaultHotelCode: string;
  Picture: string;
  Gif: string;
  isAdmin: boolean;
  isMapscreen: boolean;
  isWatcher: boolean;
  CustomerId: string;
  AvatarUrl?: string;
  GifUrl?: string;
}

let authorizedData = null;
try {
  const storageData = localStorage.getItem("console-authorized");

  if (storageData) {
    authorizedData = JSON.parse(storageData);
  }
  if (authorizedData) {
    setMobileApiSessionToken(authorizedData.authorizedCredentials.SessionToken);
    setHotelApiKey(authorizedData.authorizedCredentials.HotelApiKey);
    setPubNubKeys(
      authorizedData.authorizedCredentials.PubNub_Publish_Key,
      authorizedData.authorizedCredentials.PubNub_Subscribe_Key,
      authorizedData.authorizedCredentials.AuthKey,
      `HOST_${authorizedData.authorizedHost.id}`
    );
    setFireBaseConfig(
      authorizedData.authorizedCredentials.Firebase.REACT_APP_API_KEY,
      authorizedData.authorizedCredentials.Firebase.REACT_APP_AUTH_DOMAIN,
      authorizedData.authorizedCredentials.Firebase.REACT_APP_DATABASE_URL,
      authorizedData.authorizedCredentials.Firebase.REACT_APP_PROJECT_ID,
      authorizedData.authorizedCredentials.Firebase.REACT_APP_STORAGE_BUCKET,
      authorizedData.authorizedCredentials.Firebase
        .REACT_APP_MESSAGING_SENDER_ID,
      authorizedData.authorizedCredentials.Firebase.REACT_APP_APP_ID,
      authorizedData.authorizedCredentials.Firebase.REACT_APP_MEASUREMENT_ID
    );
    analyticsInitialize(
      authorizedData.authorizedHost.id,
      authorizedData.authorizedHotelCode
    );
  }
} catch (errors) {}

export const initialState: State = {
  addedStorageListener: false,
  signingIn: false,
  signingInError: null,
  authorizedHost: authorizedData ? authorizedData.authorizedHost : null,
  authorizedHotelCode: authorizedData ? authorizedData.authorizedHotelCode : {},
  authorizedKeys: authorizedData ? authorizedData.authorizedKeys : {},
  availableHostsFetching: false,
  availableHostsError: null,
  availableHosts: [],
  filteredHosts: [],
  signInForm: {
    values: {
      id: null,
      name: "",
      password: "",
      hotelCode: "AMS",
    },
    errors: {},
  },
  hotelMapping: {
    AMS: "Amsterdam",
    RTM: "Rotterdam",
    CPH: "Copenhagen",
    RKV: "Reykjavík",
  },
  hotelProperties: {
    AMS: {
      coordinates: {
        lat: 52.367267,
        lng: 4.867222,
      },
      googleMapsRegion: "NL",
      documentMenuItems: [
        {
          label: "FAQ",
          url: "https://docs.google.com/document/d/1ICbrw-QrtQceHitU9wu1WLlL6xy9giFcfHqgm_CDadk/preview",
        },

        {
          label: "What to do If",
          url: "https://docs.google.com/presentation/d/1wTYImus7zxixTATnGaB6PgKAwVWF3JJnPdLOx97Jwmo/preview#",

          // "https://docs.google.com/presentation/d/e/2PACX-1vRC_IFQPS51oYlaqFWETtkDW2oJEMXzBrbQVOzpk-9NNU4lzMS5wwuWy4hTl97IB4ms8pJRIVPEmfAM/embed?start=false&loop=false&delayms=3000",
        },
        {
          label: "Favorite locations",
          url: "https://drive.google.com/file/d/18RtOAVEfbPFttUUEN1TnTzxY7geppcfG/preview",
        },
        {
          label: "Cancelation Policies",
          url: "https://docs.google.com/document/d/1Fsn4uGXzACd99170MwU2zEfLEDUwG4faG-HGc-waaT8/preview",
        },
        {
          label: "Procedures",
          url: "https://docs.google.com/document/d/11U6SdhgR6mWEEW27O9otG4tYYWyxzetP3zQMDBFPmYI/preview",
        },

        // {
        //   label: "What to do If",
        //   url:
        //     "https://drive.google.com/file/d/1GN36Odppx4Z-c7RS2M-ysdm0ndGQBsx9/preview",

        //   // https://drive.google.com/open?id=1RDv75JIVJ1vImONL-sN1N7tDOPsY4Mg8
        // },
        // {
        //   label: "CityHost Manual",
        //   url:
        //     "https://drive.google.com/file/d/1--Qgjw7DvsVAzsYrVYWKBD-pXvnSBd4v/preview"
        // },
        // {
        //   label: "Cancelation Policies",
        //   url:
        //     "https://docs.google.com/document/d/e/2PACX-1vQabrLH64jn7YH02obA7gga6vvKdXElolt5rfyg3pL8kosTbBb5Mw79-sf0Eqx10YA_Ih5q7gRZgqgW/pub?embedded=true",
        // },
        // {
        //   label: "FAQ",
        //   url:
        //     "https://docs.google.com/document/d/e/2PACX-1vTtIpRNKxBmHeVSeyoArRrdb-LdJAe26mnyT9xZPtOFYZHKBg2usCVFl5BNcikFMr-qpA9FT3v_mK5u/pub?embedded=true",
        // },

        // {
        //   label: "Standard Operating Procedures",
        //   url:
        //     "https://docs.google.com/document/d/e/2PACX-1vTyR2I4qvosJiZG_DHzZW3htYyJYZqb9ES1CLQ_5JUkRsrBdTo4PUmPFNXhgeaAl9QhrCuA3yIvDwFQ/pub?embedded=true",
        // },

        // {
        //   label: "Favorite locations",
        //   url:
        //     "https://docs.google.com/document/d/e/2PACX-1vTHwDi2uz-tzuQ1ZoisBR1aFpQD7LMm2-cEiF2A7qqPlHIBaX90mq6S41XhjUPx9A/pub?embedded=true",
        // },
      ],
      regions: regionsAMS,
      locationsDefaultOptions: {
        center: {
          lat: 52.3758561,
          lng: 4.9053424,
        },
        zoom: 14,
      },
      locationsUrl:
        "https://www.google.com/maps/d/u/0/embed?mid=1dEia5aTJrIfzwwT603l3xLreaY8",
      // "https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d19496.064163564984!2d4.84034653955078!3d52.352139799999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1snl!2snl!4v1574437567916!5m2!1snl!2snl"
      // hasWifiBoxes: true,
      hasWifiBoxes: false,
    },
    RTM: {
      coordinates: {
        lat: 51.9159813,
        lng: 4.4774469,
      },
      googleMapsRegion: "NL",
      documentMenuItems: [
        {
          label: "FAQ",
          url: "https://docs.google.com/document/d/10mCXZ31Unh9F9_TIChy-rVGdwS892WMQYus51ko1Eqo/preview",
        },
        {
          label: "Cancelation Policies",
          url: "https://docs.google.com/document/d/1Fsn4uGXzACd99170MwU2zEfLEDUwG4faG-HGc-waaT8/preview",
        },

        {
          label: "What to do If",
          url:
            // "https://docs.google.com/document/d/1dt00YrPlaE0UdiB--MXJmzfwiCTYyjxOHdCJx7doRYw/preview",
            // "https://drive.google.com/file/d/11VYHsbkhwCbmxzwrnDmiO7L6vRzaDxj8/preview",
            "https://drive.google.com/file/d/1uUbigPwcZkAbaAoV7CGRQcTrt_20rafj/preview",
        },
        {
          label: "Lost & Found",
          url: "https://drive.google.com/file/d/1tsZCm1hfU6Pe81S3_bkXQ-1Qn4Mr3pYJ/preview",
        },

        {
          label: "Procedures",
          url: "https://docs.google.com/document/d/11U6SdhgR6mWEEW27O9otG4tYYWyxzetP3zQMDBFPmYI/preview",
        },
      ],
      regions: regionsRTM,
      locationsDefaultOptions: {
        center: {
          lat: 52.3758561,
          lng: 4.9053424,
        },
        zoom: 14,
      },
      locationsUrl:
        "https://www.google.com/maps/d/u/0/embed?mid=1RH3LTAvzOPUMVPYMfJOfie54dZdV_uRZ",
      // "https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d19496.064163564984!2d4.84034653955078!3d52.352139799999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1snl!2snl!4v1574437567916!5m2!1snl!2snl"
      // hasWifiBoxes: true,
      hasWifiBoxes: false,
    },
    CPH: {
      coordinates: {
        lat: 55.671665,
        lng: 12.546557,
      },
      googleMapsRegion: "DK",
      documentMenuItems: [
        {
          label: "FAQ",
          url: "https://docs.google.com/document/d/1jDFA-zawjjHlEdVCzR_hDKYAQ2_xGuxlkABdtYJcO58/preview",
        },
        {
          label: "Cancelation Policies",
          url: "https://docs.google.com/document/d/1Fsn4uGXzACd99170MwU2zEfLEDUwG4faG-HGc-waaT8/preview",
        },

        {
          label: "What to do If",
          url: "https://docs.google.com/document/d/114F01BmHAnhG5L3NloLjtojDVxsUJtCvrFtfxZI5ppg/preview",
        },
        // {
        //   label: "Lost & Found",
        //   url:
        //     "https://drive.google.com/file/d/148pRetBbD96JOieBKoFfFIkNnhR0AfdS/preview",
        // },

        {
          label: "Standard Operating Procedures",
          url: "https://docs.google.com/document/d/11U6SdhgR6mWEEW27O9otG4tYYWyxzetP3zQMDBFPmYI/preview",
        },
      ],
      regions: [],
      locationsDefaultOptions: {
        center: {
          lat: 55.671665,
          lng: 12.546557,
        },
        zoom: 14,
      },
      locationsUrl:
        "https://www.google.com/maps/d/embed?mid=1w8CZUbQoIbVgbFdW3qLWsb3LIHPW7JP_",
      hasWifiBoxes: false,
    },
    RKV: {
      coordinates: {
        lat: 64.145969,
        lng: -21.9273675,
      },
      googleMapsRegion: "IS",
      documentMenuItems: [
        {
          label: "FAQ",
          url: "https://docs.google.com/document/d/1jDFA-zawjjHlEdVCzR_hDKYAQ2_xGuxlkABdtYJcO58/preview",
        },
        {
          label: "Cancelation Policies",
          url: "https://docs.google.com/document/d/1Fsn4uGXzACd99170MwU2zEfLEDUwG4faG-HGc-waaT8/preview",
        },

        {
          label: "What to do If",
          url: "https://docs.google.com/document/d/114F01BmHAnhG5L3NloLjtojDVxsUJtCvrFtfxZI5ppg/preview",
        },
        // {
        //   label: "Lost & Found",
        //   url:
        //     "https://drive.google.com/file/d/148pRetBbD96JOieBKoFfFIkNnhR0AfdS/preview",
        // },

        {
          label: "Standard Operating Procedures",
          url: "https://docs.google.com/document/d/11U6SdhgR6mWEEW27O9otG4tYYWyxzetP3zQMDBFPmYI/preview",
        },
      ],
      regions: [],
      locationsDefaultOptions: {
        center: {
          lat: 64.145969,
          lng: -21.9273675,
        },
        zoom: 14,
      },
      locationsUrl:
        "https://www.google.com/maps/d/embed?mid=1w8CZUbQoIbVgbFdW3qLWsb3LIHPW7JP_",
      hasWifiBoxes: false,
    },
  },
};

const filterHosts = (name: string, hosts: Host[]) => {
  if (name.length === 0) {
    return hosts;
  }

  return hosts.filter(
    (host) => host.FirstName.toLowerCase().indexOf(name.toLowerCase()) !== -1
  );
};

export const reducer = (
  state = initialState,
  action: { type: string; [key: string]: any }
): State => {
  switch (action.type) {
    case actionTypes.SIGNING_IN:
      return {
        ...state,
        signingIn: true,
        signingInError: null,
      };
    case actionTypes.SIGNING_IN_ERROR:
      return {
        ...state,
        signingIn: false,
        signingInError: action.error,
      };
    case actionTypes.SIGNED_IN:
      return {
        ...state,
        signingIn: false,
        authorizedHost: action.authorizedHost,
        authorizedHotelCode: action.authorizedHotelCode,
        authorizedKeys: action.authorizedKeys,
        signInForm: initialState.signInForm,
      };
    case actionTypes.AVAILABLE_HOSTS_FETCHING:
      return {
        ...state,
        availableHostsFetching: true,
        availableHostsError: null,
      };
    case actionTypes.AVAILABLE_HOSTS_ERROR:
      return {
        ...state,
        availableHostsFetching: false,
        availableHostsError: action.error,
      };
    case actionTypes.AVAILABLE_HOSTS_FETCHED:
      return {
        ...state,
        availableHostsFetching: false,
        availableHosts: action.availableHosts,
        filteredHosts: filterHosts(
          state.signInForm.values.name,
          action.availableHosts
        ),
      };
    case actionTypes.SIGN_IN_FORM_SET_VALUE:
      return {
        ...state,
        filteredHosts:
          action.key === "name"
            ? filterHosts(action.value, state.availableHosts)
            : state.filteredHosts,
        signInForm: {
          ...state.signInForm,
          values: {
            ...state.signInForm.values,
            [action.key]: action.value,
          },
        },
      };
    case actionTypes.ADDED_STORAGE_LISTENER:
      return {
        ...state,
        addedStorageListener: true,
      };
    case actionTypes.SIGN_OUT:
      return {
        ...state,
        authorizedHost: null,
        authorizedHotelCode: null,
        authorizedKeys: null,
      };
    default:
      console.error("No action provided to reducer");
      return state;
  }
};

export const Context = React.createContext<AuthorizationContext>({
  state: initialState,
  ...authorizationActions(() => {
    console.error("Dispatch not implemented");
  }),
});

export const actions = authorizationActions;
