import React, { useContext, useEffect } from "react";
import { Context as ChatContext } from "../../../../store/chat";
import { Context as SystemContext } from "../../../../store/system";
import { notify } from "services/notifications";
import moment from "moment";
import { RouteChildrenProps } from "react-router";

interface Props extends RouteChildrenProps { }

const ChatNotifier: React.FC<Props> = (props) => {
  const { history, location } = props;
  const { state: chatState, setActiveChatRoom } = useContext(
    ChatContext
  );
  const { state: systemState } = useContext(SystemContext);

  const { activeHotelCode, lastMessage } = chatState;
  const { messages } = systemState;

  useEffect(() => {
    console.log('Notification: ', Notification.permission);

    if (!("Notification" in window)) {
      console.log("No notification support in browser");
      return;
    }

    if (Notification.permission !== "granted") {
      Notification.requestPermission();
    }
  }, []);

  useEffect(() => {
    // console.log('ChatNotifier lastMessage: ', lastMessage);
    if (
      lastMessage &&
      lastMessage.message &&
      lastMessage.chatRoomId &&
      lastMessage.message.messageType === "OWNER_MESSAGE" &&
      moment().diff(moment(lastMessage.message.timetoken), "second") < 10
    ) {
      notify({
        title: `New notification from ${lastMessage.message.sender.firstName}`,
        tag: lastMessage.chatRoomId,
        body: lastMessage.message.text,
        icon: lastMessage.message.sender.picture,
        onClick: () => {
          setActiveChatRoom(lastMessage.chatRoomId, activeHotelCode);
          history.push("/");
        },
      });
    }
  }, [lastMessage.message]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   const lastSystemMessage = messages.length > 0 ? messages[0] : null;
  //   if (
  //     lastSystemMessage &&
  //     moment().diff(moment(lastSystemMessage.timetoken), "second") < 10
  //   ) {
  //     notify({
  //       title: `New ${
  //         lastSystemMessage.MessageType === "SYSTEM_MESSAGE_CHECKIN"
  //           ? "check-in"
  //           : "check-out"
  //       }`,
  //       tag: "system",
  //       body: `From hub ${lastSystemMessage.reservation.Hub}`,
  //       icon: "",
  //       onClick: () => {
  //         history.push("/");
  //       },
  //     });
  //   }
  // }, [messages]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default ChatNotifier;
