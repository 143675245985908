import React from 'react';
import PropTypes from 'prop-types';
import styles from './H1.module.scss';

const H1 = props => {
  const { children, ...otherProps } = props;

  return (
    <h1 className={styles.h1} {...otherProps}>
      {children}
    </h1>
  );
};

H1.propTypes = {
  children: PropTypes.node
};

export default H1;
