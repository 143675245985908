import React, { useState } from "react";
import styles from "./ChatMessageForm.module.scss";
import MarkerIcon from "assets/svg/marker-grey.svg";
import SendIcon from "assets/svg/send.svg";
import TextArea from "components/elements/TextArea/TextArea";

interface Props {
  sendMessage: (message: string) => void;
  onClickLocation?: () => void;
}

const onSubmit = (
  message: string,
  sendMessage: Props["sendMessage"],
  setMessage: (message: string) => void
) => {
  message = message.trim();

  if (!message) {
    return;
  }
  setMessage("");
  sendMessage(message);
};

const ChatMessageForm: React.FC<Props> = props => {
  const [message, setMessage] = useState("");
  const { sendMessage, onClickLocation } = props;

  return (
    <div className={styles.wrapper}>
      <div className={styles.icon}>
        <img
          className={styles.locationButton}
          onClick={onClickLocation}
          src={MarkerIcon}
          alt="Location"
        />
      </div>
      <div className={styles.input}>
        <TextArea
          type="message"
          placeholder="Say something..."
          value={message}
          onChange={event => setMessage(event.target.value)}
          onSelectEmoji={emoji => setMessage(message + emoji)}
          onKeyDown={event => {
            if (event.metaKey && event.key === "Enter") {
              onSubmit(message, sendMessage, setMessage);
            }
          }}
        />
      </div>
      <div className={styles.icon}>
        <img
          src={SendIcon}
          alt="Send"
          onClick={() => onSubmit(message, sendMessage, setMessage)}
        />
      </div>
    </div>
  );
};

export default ChatMessageForm;
