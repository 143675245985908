import React, { useContext, useEffect, useState } from "react";
import styles from "./ChatListForm.module.scss";
import Input from "components/elements/Input";
import { handleInputChange } from "helpers/form";
import { Context as ChatContext } from "../../../../store/chat";
import StatusOpenIcon from "assets/svg/status-circle.svg";
import StatusDoneIcon from "assets/svg/status-done.svg";
import StatusNewIcon from "assets/svg/status-new.svg";
import StatusInhouseIcon from "assets/svg/status-inhouse.svg";
import Paragraph from "components/elements/Paragraph";
import { dateMinusDays } from "helpers/date";

interface Props { }

const ChatListForm: React.FC<Props> = props => {
  const { state: chatState, setFilterChatRoomList } = useContext(ChatContext);
  const { chatRoomList, chatRoomListFilter } = chatState;
  const [allCount, setAllCount] = useState<number>(0);
  const [newCount, setNewCount] = useState<number>(0);
  const [openCount, setOpenCount] = useState<number>(0);
  const [inhouseCount, setInhouseCount] = useState<number>(0);
  const [doneCount, setDoneCount] = useState<number>(0);

  useEffect(() => {
    setAllCount(chatRoomList.length);
    setNewCount(chatRoomList.filter(key => key.status === "new").length);
    // setOpenCount(chatRoomList.filter( key => key.status === "open" ).length);
    setDoneCount(chatRoomList.filter(key => key.status === "done").length);

    const inhouseCount = chatRoomList.filter(chatRoom => {
      if (chatRoom.booking !== undefined) {
        return (chatRoom.booking.checkIn <= dateMinusDays(0) && chatRoom.booking.checkOut >= dateMinusDays(0));
      } else {
        return false;
      }
    }).length;
    setInhouseCount(inhouseCount);
  }, [chatRoomList]);

  return (
    <div className={styles.wrapper}>
      <Input
        type="search"
        placeholder="Search"
        value={chatRoomListFilter.text}
        name="text"
        onChange={handleInputChange(setFilterChatRoomList)}
      />
      <div className={styles.statusWrapper}>
        <div
          className={
            chatRoomListFilter.status === "all" ? styles.active : ""
          }
          onClick={() => setFilterChatRoomList("status", "all")}
        >
          <Paragraph small>All ({allCount})</Paragraph>
        </div>
        <div
          className={
            chatRoomListFilter.status === "new" ? styles.active : ""
          }
          onClick={() => setFilterChatRoomList("status", "new")}
        >
          <Paragraph small>
            <img src={StatusNewIcon} alt="New" />
            <span>New ({newCount})</span>
          </Paragraph>
        </div>
        {/* <div
          className={
            chatRoomListFilter.status === "open" ? styles.active : ""
          }
          onClick={() => setFilterChatRoomList("status", "open")}
        >
          <Paragraph small>
            <img src={StatusOpenIcon} alt="New" />
            <span>Open ({openCount})</span>
          </Paragraph>
        </div> */}
        <div
          className={
            chatRoomListFilter.status === "done" ? styles.active : ""
          }
          onClick={() => setFilterChatRoomList("status", "done")}
        >
          <Paragraph small>
            <img src={StatusDoneIcon} alt="Done" />
            <span>Done ({doneCount})</span>
          </Paragraph>
        </div>
        <div
          className={
            chatRoomListFilter.status === "inhouse" ? styles.active : ""
          }
          onClick={() => setFilterChatRoomList("status", "inhouse")}
        >
          <Paragraph small>
            <img src={StatusInhouseIcon} alt="Inhouse" />
            <span>Inhouse ({inhouseCount})</span>
          </Paragraph>
        </div>
      </div>
    </div>
  );
};

export default ChatListForm;
