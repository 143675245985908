import React, { useEffect, useContext, useState } from "react";
import styles from "./ChatList.module.scss";
import { Context as ChatContext } from "../../../../store/chat";
import { ChatRoom } from "../../../../store/interface/chat";
import { Context as AuthorizationContext } from "../../../../store/authorization";

import LoadingIcon from "assets/img/loading.gif";
import ChatListItem from "../ChatListItem";
import { dateMinusDays } from "helpers/date";


interface Props {
  lazyLoadScrollContainer: string;
}

const ChatList: React.FC<Props> = props => {

  const { lazyLoadScrollContainer } = props;

  const {
    state: chatState,
    setActiveChatRoom,
  } = useContext(ChatContext);
  const { state: authorizationState } = useContext(AuthorizationContext);

  const { chatRoomList, chatRoomListFilter } = chatState;

  const [messages, setMessages] = useState<Array<ChatRoom>>([]);

  useEffect(() => {
    if (chatRoomList.length > 0) {

      // Filter by status
      const filteredMessagesByStatus = chatRoomList.filter((chatRoom) => {
        if (chatRoomListFilter.status === "all") {
          return true;
        }

        else if (chatRoomListFilter.status === "inhouse") {
          if (chatRoom.booking !== undefined) {
            return (chatRoom.booking.checkIn <= dateMinusDays(0) && chatRoom.booking.checkOut >= dateMinusDays(0));
          } else {
            return false;
          }
        }

        else {
          return chatRoomListFilter.status === chatRoom.status;
        }
      });

      const filteredMessages = filteredMessagesByStatus.filter(
        (chatRoom) => {
          // Check booking hubNumber
          if (chatRoom.booking !== undefined && chatRoom.booking.hubNumber !== null && chatRoom.booking.hubNumber.toLowerCase().indexOf(chatRoomListFilter.text.toLowerCase()) !== -1) {
            return true;
          }

          // Return if name is the same
          else if (chatRoom.owner.firstName !== null && chatRoom.owner.firstName.toLowerCase().indexOf(chatRoomListFilter.text.toLowerCase()) !== -1) {
            return true;
          }

          // Return false if no messages
          else if (!chatRoom.messages) {
            return false;
          }

          // Return true if text has filter
          else {
            return chatRoom.messages.find((message) => message.text.toLowerCase().indexOf(chatRoomListFilter.text.toLowerCase()) !== -1);
          }
        }
      );


      // Sort by date
      filteredMessages.sort((a: ChatRoom, b: ChatRoom) => {
        if (a.messages && b.messages) {
          var y = a.messages[a.messages.length - 1].timetoken;
          var x = b.messages[b.messages.length - 1].timetoken;
          return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        } else {
          return -1;
        }
      });


      setMessages(filteredMessages);
    }

  }, [chatRoomList, chatRoomListFilter]);

  return (
    <>
      {messages.length === 0 && (
        <div className={styles.loadingWrapper}>
          No messages
        </div>
      )}

      {messages.length > 0 && (
        <>
          {messages.map(chatRoom => (
            <ChatListItem
              lazyLoadScrollContainer={lazyLoadScrollContainer}
              onClick={() => {
                setActiveChatRoom(chatRoom.id, authorizationState.authorizedHotelCode);
              }}
              chatRoom={chatRoom}
              key={chatRoom.id}
            />
          ))}
        </>
      )}
    </>
  );
};

export default ChatList;
