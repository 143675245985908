import React, { useContext, useEffect, useState } from "react";
import styles from "./Analytics.module.scss";
import Sidebar from "components/layouts/Sidebar";
import Paragraph from "components/elements/Paragraph";
import { Context as AuthorizationContext } from "../../../store/authorization";
import { getWebSocketClient } from "services/webSocket";
import { hotelApi } from "services/api";
import Button from "components/elements/Button";
import { getMewsCustomerByTokenId } from "helpers/mews";
import { firestore } from "services/firebase";
import firebase from "firebase/app";
import "firebase/firestore";
import { fcmApi } from "services/api";
import { dateMinusDays, timestampMinusDays } from "helpers/date";
import moment from "moment";

interface Props {}

const Analytics: React.FC<Props> = (props) => {
  const { state: authorizationState } = useContext(AuthorizationContext);
  const { authorizedHotelCode } = authorizationState;

  const [chatTotalCount, setChatTotalCount] = useState(0);
  const [chatYesterdayCount, setChatYesterdayCount] = useState(0);
  const [chatroomCount, setChatroomCount] = useState(0);
  const [installBeforeCheckIn, setInstallBeforeCheckIn] = useState(0);
  const [bookingConnected, setBookingConnected] = useState(0);
  const [disableButton, setDisableButton] = useState(false);
  const [analyticsData, setAnalyticsData] = useState([]);
  const showUsers = () => {
    console.log("start");
    let countChatTotal = 0;
    let countChatYesterday = 0;
    let countInstallBeforeCheckIn = 0;
    let countConnectedBooking = 0;
    const chatRooms = firestore()
      .collection("chatRooms")
      .where("hotelCode", "==", authorizedHotelCode)
      .get()
      .then((response) => {
        setDisableButton(true);
        setChatroomCount(response.docs.length);

        response.docs.forEach((doc) => {
          const data = doc.data();
          if (data && data.messages && data.messages.length > 0) {
            if (
              data.messages.some(
                (m: any) =>
                  m.type !== "broadcast" &&
                  m.timetoken >
                    moment()
                      .subtract(1, "days")
                      .startOf("D")
                      .toDate()
                      .getTime() &&
                  m.timetoken <
                    moment().subtract(1, "days").endOf("D").toDate().getTime()
              )
            ) {
              countChatYesterday++;
            }

            if (data.booking && data.messages[0].type !== "broadcast") {
              console.log(
                data.messages[0].timetoken,
                moment(data.booking.checkIn).toDate().getTime()
              );

              if (
                data.messages[0].timetoken <
                moment(data.booking.checkIn).toDate().getTime()
              ) {
                countInstallBeforeCheckIn++;
              }
            }
          } else {
            countChatTotal++;
          }
        });
        setChatTotalCount(countChatTotal);
        setChatYesterdayCount(countChatYesterday);
        setInstallBeforeCheckIn(countInstallBeforeCheckIn);
        setDisableButton(false);
      });
    console.log("done");
  };

  return (
    <div className={styles.wrapper}>
      <Sidebar activeLink="analytics" />
      <div className={styles.contentWrapper}>
        <div className={styles.header}>
          <Paragraph medium strong>
            Analytics
          </Paragraph>
        </div>
        <div className={styles.content}>
          <div className={styles.buttons}>
            <div>
              {/* {disableButton === false && (
                <Button onClick={() => showUsers()}>Run analytics</Button>
              )} */}
            </div>
          </div>
          Hebben de app geïnstalleerd in {authorizedHotelCode}: {chatroomCount}
          <br />
          Gebruikte de chat functie nooit (geen broadcast): {chatTotalCount}
          <br />
          Gebruikte gisteren de chat functie (geen broadcast):{" "}
          {chatYesterdayCount}
          <br />
          Heeft een booking gekoppeld: {chatYesterdayCount}
          <br />
          Geïnstalleerd voor checking: {installBeforeCheckIn}
          <br />
        </div>
      </div>
    </div>
  );
};

export default Analytics;
