import React from "react";
import chatActions, { actionTypes, ChatActionTypes } from "./actions/chat";
import { State } from "./interface/chat"

export interface ChatContext extends ChatActionTypes {
  state: State;
}

export const initialState: State = {
  activeHotelCode: null,
  activeChatRoomId: undefined,
  activeChatRoom: null,
  activeBroadcastRoomId: undefined,
  activeBroadcastRoom: null,
  chatRoomList: [],
  chatRoomMessages: [],
  broadcastMessages: [],
  chatRoomListFilter: {
    status: "all",
    text: ""
  },
  lastMessage: {
    chatRoomId: undefined,
    message: null
  }
};

export const reducer = (
  state = initialState,
  action: { type: string; [key: string]: any }
): State => {

  switch (action.type) {
    case actionTypes.SET_ACTIVE_CHAT_ROOM:
      return {
        ...state,
        activeChatRoomId: action.activeChatRoomId,
        activeChatRoom: action.activeChatRoom,
        activeBroadcastRoomId: undefined,
        activeBroadcastRoom: null,
        chatRoomMessages: action.chatRoomMessages,
      };
    case actionTypes.SET_ACTIVE_BROADCAST_ROOM:
      return {
        ...state,
        activeChatRoomId: undefined,
        activeChatRoom: null,
        activeBroadcastRoomId: action.activeBroadcastRoomId,
        activeBroadcastRoom: action.activeBroadcastRoom,
        chatRoomMessages: action.chatRoomMessages,
      };

    case actionTypes.CLEAN_STATE:
      return {
        ...state,
        activeChatRoomId: undefined,
        activeChatRoom: null,
        activeBroadcastRoomId: undefined,
        activeBroadcastRoom: null,
        chatRoomMessages: [],
      };

    case actionTypes.FETCH_CHAT_ROOM_LIST:
      return {
        ...state,
        chatRoomList: action.chatRoomList,
        activeHotelCode: action.activeHotelCode,
        lastMessage: action.lastMessage
      };

    case actionTypes.FETCH_BROADCAST:
      return {
        ...state,
        broadcastMessages: action.broadcastMessages,
      };

    case actionTypes.SET_CHAT_ROOM_LIST_FILTER:
      return {
        ...state,
        chatRoomListFilter: {
          ...state.chatRoomListFilter,
          [action.key]: action.value
        }
      };
    default:
      console.error("No action provided to reducer");
      return state;
  }
};

export const Context = React.createContext<ChatContext>({
  state: initialState,
  ...chatActions(() => {
    console.error("Dispatch not implemented");
  })
});

export const actions = chatActions;
