import React from "react";
import styles from "./Select.module.scss";

const Select = props => {
  const { items, ...otherProps } = props;

  return (
    <select className={styles.select} {...otherProps}>
      {items.map(({ key, label, value }) => (
        <option key={key} value={value}>
          {label}
        </option>
      ))}
    </select>
  );
};

export default Select;
