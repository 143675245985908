import React from "react";
import hostActions, { actionTypes, HostActionTypes } from "./actions/host";
import { Host } from "./authorization";

const REQUEST_ACTIVE = "REQUEST_ACTIVE";
const REQUEST_FAILED = "REQUEST_FAILED";
const REQUEST_SUCCESS = "REQUEST_SUCCESS";

const hostsToStore = (hosts: Array<{ id: string; [k: string]: any }>) => {
  const reduceHosts: { [k: string]: any } = {};

  hosts.forEach(host => {
    reduceHosts[host.id] = {
      ...host
    };
  });

  return reduceHosts;
};

export interface HostContext extends HostActionTypes {
  state: State;
}

export interface State {
  requests: {
    collection?: "REQUEST_ACTIVE" | "REQUEST_FAILED" | "REQUEST_SUCCESS";
  };
  records: {
    [id: string]: Host;
  };
  form: {
    saved: boolean;
    saving: boolean;
    error: boolean;
    errors: {
      firstName: string | null;
      mewsID: string | null;
    };
    values: {
      hostId: string | null;
      firstName: string;
      mewsID: string;
      avatar: string | null;
      gif: string | null;
      token: string | null;
      tokenInput: string | null;
    };
  };
}

export const initialState: State = {
  requests: {},
  records: {},
  form: {
    saved: false,
    saving: false,
    error: false,
    errors: {
      firstName: null,
      mewsID: null
    },
    values: {
      hostId: null,
      firstName: "",
      mewsID: "",
      avatar: null,
      gif: null,
      token: null,
      tokenInput: null
    }
  }
};

export const reducer = (
  state = initialState,
  action: { type: string; [key: string]: any }
): State => {
  switch (action.type) {
    case actionTypes.HOST_REQUEST:
      return {
        ...state,
        requests: Object.assign({}, state.requests, {
          collection: REQUEST_ACTIVE
        })
      };
    case actionTypes.HOST_REQUEST_FAILED:
      return {
        ...state,
        requests: Object.assign({}, state.requests, {
          collection: REQUEST_FAILED
        })
      };
    case actionTypes.HOST_REQUEST_SUCCESS:
      return {
        ...state,
        requests: Object.assign({}, state.requests, {
          collection: REQUEST_SUCCESS
        }),
        records: hostsToStore(action.hosts)
      };
    case actionTypes.HOST_FETCH:
      return {
        ...state,
        requests: Object.assign({}, state.requests, {
          [action.hostId]: REQUEST_ACTIVE
        })
      };
    case actionTypes.HOST_FETCH_FAILED:
      return {
        ...state,
        requests: Object.assign({}, state.requests, {
          [action.hostId]: REQUEST_FAILED
        })
      };
    case actionTypes.HOST_FETCH_SUCCESS:
      const appendRecords = action.host ? [action.host] : [];

      return {
        ...state,
        requests: Object.assign({}, state.requests, {
          [action.hostId]: REQUEST_SUCCESS
        }),
        records: Object.assign({}, state.records, hostsToStore(appendRecords))
      };
    case actionTypes.HOST_SET_FORM_VALUE:
      return {
        ...state,
        form: {
          ...state.form,
          values: {
            ...state.form.values,
            [action.key]: action.value ? action.value : ""
          }
        }
      };
    case actionTypes.HOST_SET_FORM_ERROR: {
      return {
        ...state,
        form: {
          ...state.form,
          errors: {
            ...state.form.errors,
            [action.key]: action.value
          }
        }
      };
    }
    case actionTypes.HOST_SUBMIT_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          saving: true
        }
      };
    case actionTypes.HOST_SUBMIT_SUCCESS:
      return {
        ...state,
        form: {
          ...state.form,
          saving: false,
          saved: true
        }
      };
    case actionTypes.HOST_SUBMIT_ERROR:
      return {
        ...state,
        form: {
          ...state.form,
          saving: false,
          error: true
        }
      };
    case actionTypes.HOST_RESET_FORM: {
      return {
        ...state,
        form: {
          ...initialState.form
        }
      };
    }
    default:
      console.error("No action provided to reducer");
      return state;
  }
};

export const Context = React.createContext<HostContext>({
  state: initialState,
  ...hostActions(() => {
    console.error("Dispatch not implemented");
  })
});

export const actions = hostActions;
