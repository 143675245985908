import React, { MouseEvent } from "react";
import { Link } from "react-router-dom";
import styles from "./Button.module.scss";

interface Props {
  big?: boolean;
  simple?: boolean;
  small?: boolean;
  to?: string | Object;
  contrast?: boolean;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  link?: boolean;
  fat?: boolean;
  className?: string;
}

const Button: React.FC<Props> = props => {
  const {
    children,
    big,
    small,
    simple,
    to,
    contrast,
    onClick,
    link,
    fat,
    className
  } = props;

  const buttonClassNames = [styles.button];

  if (simple) {
    buttonClassNames.push(styles.buttonSimple);
  }

  if (small) {
    buttonClassNames.push(styles.buttonSmall);
  }

  if (big) {
    buttonClassNames.push(styles.buttonBig);
  }

  if (contrast) {
    buttonClassNames.push(styles.contrast);
  }

  if (link) {
    buttonClassNames.push(styles.link);
  }

  if (fat) {
    buttonClassNames.push(styles.fat);
  }

  if (className) {
    buttonClassNames.push(className);
  }

  return (
    <>
      {!!to && (
        <Link className={buttonClassNames.join(" ")} to={to}>
          {children}
        </Link>
      )}
      {!to && (
        <button className={buttonClassNames.join(" ")} onClick={onClick}>
          {children}
        </button>
      )}
    </>
  );
};

export default Button;
