import React, { useContext, useEffect } from "react";
import { Context as LocationsContext } from "../../../../store/locations";
import { Context as AuthorizationContext } from "../../../../store/authorization";
import styles from "./Place.module.scss";
import { RouteComponentProps } from "react-router";
import LocationsWrapper from "../LocationsWrapper";
import LoadingIcon from "assets/img/loading.gif";
import Stars from "components/elements/Stars";
import Button from "components/elements/Button";
import Paragraph from "components/elements/Paragraph";
import moment from "moment";

interface Props extends RouteComponentProps<{ id: string }> {
  onClose: () => void;
  mapRef?: google.maps.Map;
}

const formatHour = (time: string) => {
  return moment(time, "HH:mm").format("hha");
};

const Place: React.FC<Props> = ({ match, onClose, mapRef }) => {
  const placeId = match.params.id;
  const {
    state: locationsState,
    fetchSelectedPlace,
    send,
  } = useContext(LocationsContext);
  const { state: authorizationState } = useContext(AuthorizationContext);
  const {
    selectedPlace,
    reservation,
    sendProcessed,
    sendProcessing,
    sendError,
  } = locationsState;
  const { authorizedHost, authorizedHotelCode } = authorizationState;

  useEffect(() => {
    if (placeId && mapRef) {
      fetchSelectedPlace(mapRef, placeId);
    }
  }, [placeId, mapRef]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!authorizedHost || !authorizedHotelCode || !selectedPlace) {
    return (
      <LocationsWrapper>
        <Button className={styles.closeButton} link onClick={onClose} />
      </LocationsWrapper>
    );
  }

  return (
    <LocationsWrapper>
      <div className={styles.imageWrapper}>
        <img
          src={selectedPlace.picture ? selectedPlace.picture : ""}
          alt={selectedPlace.name}
        />
        <Button className={styles.closeButton} link onClick={onClose} />
      </div>
      <div className={styles.wrapper}>
        <h3>{selectedPlace.name}</h3>
        {selectedPlace.rating && (
          <Stars
            count={Math.round(selectedPlace.rating)}
            type="default"
            size="small"
          />
        )}
        {selectedPlace.types && selectedPlace.types.length > 0 && (
          <Paragraph className={styles.locationType} small>
            {selectedPlace.types[0]}
          </Paragraph>
        )}
        {reservation && selectedPlace && !sendProcessed && !sendProcessing && (
          <div className={styles.buttons}>
            <div>
              <Button
                small
                onClick={() =>
                  send(
                    "Your location",
                    authorizedHotelCode,
                    selectedPlace,
                    reservation.Id
                  )
                }
              >
                Save &amp; send to app
              </Button>
            </div>
          </div>
        )}
        {sendProcessing && (
          <div className={styles.buttons}>
            <img width="15" src={LoadingIcon} alt="Loading" />
          </div>
        )}
        {sendProcessed && (
          <div className={styles.buttons}>
            <Paragraph success>Sent to your CityHub app</Paragraph>
          </div>
        )}
        {sendError && (
          <div className={styles.buttons}>
            <Paragraph error>Failed to sent to the CityHub app</Paragraph>
          </div>
        )}
        <Paragraph className={styles.address}>
          {[selectedPlace.streetAddress, selectedPlace.phone]
            .filter((item) => item !== "")
            .join("\n")}
          <br />
          {selectedPlace.openingHours && selectedPlace.openingHours.open_now
            ? "Open now"
            : "Closed now"}
          {selectedPlace.openingHours &&
            `: ${formatHour(
              selectedPlace.openingHours.periods[moment().day()].open.time
            )}-${formatHour(
              selectedPlace.openingHours.periods[moment().day()].close.time
            )}`}
        </Paragraph>
      </div>
    </LocationsWrapper>
  );
};

export default Place;
