import * as Sentry from "@sentry/browser";
import config from "helpers/config";

const setupSentry = () => {
  if (config.REACT_APP_SENTRY_DSN) {
    Sentry.init({ dsn: config.REACT_APP_SENTRY_DSN });
  }
};

export default setupSentry;
