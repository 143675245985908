import React from "react";
import systemActions, {
  actionTypes,
  SystemActionTypes,
} from "./actions/system";
import { Reservation, Customer } from "./reservation";

export interface SystemContext extends SystemActionTypes {
  state: State;
}

export interface State {
  defaultListenerAdded: boolean;
  fetchingMessages: boolean;
  messages: SystemMessage[];
}

export interface SystemMessage {
  MessageType:
    | "SYSTEM_MESSAGE_CHECKIN"
    | "SYSTEM_MESSAGE_CHECKOUT"
    | "SYSTEM_MESSAGE_CHECKINGOUT"
    | "SYSTEM_MESSAGE_CHECKGUEST";
  timetoken: number;
  text: string;
  reservation: {
    Hub: string;
    Customers: Customer[];
    thisReservation: Reservation;
    thisReservationNights: number;
    thisReservationBikes: number;
    thisReservationHubType: string;
  };
  sender: {
    firstName: string;
    picture: string;
  };
  type: "default";
}

export const initialState: State = {
  defaultListenerAdded: false,
  fetchingMessages: false,
  messages: [],
};

export const reducer = (
  state = initialState,
  action: { type: string; [key: string]: any }
): State => {
  switch (action.type) {
    case actionTypes.DEFAULT_LISTENER_ADDED:
      return {
        ...state,
        defaultListenerAdded: true,
      };
    case actionTypes.ADD_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.message],
      };
    case actionTypes.FETCHING_MESSAGES:
      return {
        ...state,
        fetchingMessages: true,
        messages: [],
      };
    case actionTypes.SET_MESSAGES:
      return {
        ...state,
        fetchingMessages: false,
        messages: action.messages,
      };
    default:
      console.error("No action provided to reducer");
      return state;
  }
};

export const Context = React.createContext<SystemContext>({
  state: initialState,
  ...systemActions(() => {
    console.error("Dispatch not implemented");
  }),
});

export const actions = systemActions;
