import { Message, Place } from "store/interface/chat";
import { getClient } from "services/pubNub";
import { Host } from "store/authorization";

export const getChannelMessageHistory = async (
  channel: string,
  count: number
): Promise<Message[]> => {
  return new Promise((resolve, reject) => {
    getClient().history(
      {
        channel,
        count,
      },
      (status: {}, response: any) => {
        const messages = (
          response && "messages" in response ? response.messages : []
        ).map(
          (item: any): Message => ({
            ...item.entry.data,
            timetoken: Math.floor(parseInt(item.timetoken) / 10000),
            meta: {
              messageType:
                item.entry.sender.indexOf("host_") === 0
                  ? "HOST_MESSAGE"
                  : "GUEST_HOST",
            },
          })
        );
        resolve(messages);
      }
    );
  });
};

export const createMessage = (params: {
  channel: string;
  text: string;
  host: Host;
  type: "default" | "broadcast";
  messageType: string;
  reservationId: string | null;
  hotelCode: string;
  place: any;
}) => {
  const {
    channel,
    text,
    host,
    type,
    messageType,
    reservationId,
    hotelCode,
    place,
  } = params;

  const message: any = {
    channel,
    storeInHistory: true,
    message: {
      data: {
        text,
        sender: {
          picture: host.Picture,
          firstName: host.FirstName,
        },
        type,
        meta: {
          messageType,
          reservationId: null,
          hotelCode,
          origin: "console",
        },
        place: null,
      },
      event: "message",
      sender: "host_" + host.id,
      pn_debug: true,
      pn_gcm: {
        data: {
          message: `${place ? "📍 " : ""}CityHost ${host.FirstName}: ${text}`,
        },
      },
      pn_apns: {
        aps: {
          alert: {
            title: `${place ? "📍 " : ""}CityHost ${host.FirstName}`,
            body: text,
          },
        },
      },
    },
  };

  if (place) {
    message.message.data.place = place;
  } else {
    delete message.message.data.place;
  }

  if (reservationId) {
    message.message.data.meta.reservationId = reservationId;
  } else {
    delete message.message.data.meta.reservationId;
  }

  return message;
};

export const getMessageType = (type: string, place: Place | null) => {
  let messageType = "HOST_";

  if (type === "broadcast") {
    messageType += "BROADCAST_";
  }

  if (place) {
    messageType += "LOCATION";
  } else {
    messageType += "MESSAGE";
  }

  return messageType;
};

export default {
  getChannelMessageHistory,
  createMessage,
  getMessageType,
};
