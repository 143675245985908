import React from "react";
import locationsActions, {
  actionTypes,
  LocationsActionTypes
} from "./actions/locations";
import { Reservation, Customer } from "./reservation";
import { Place } from "./interface/chat";

export interface SystemContext extends LocationsActionTypes {
  state: State;
}

export interface State {
  loadingTokenInfoProcessing: boolean;
  loadingTokenInfoProcessed: boolean;
  loadingTokenInfoError: string | null;
  reservation: Reservation | null;
  customers: Customer[];
  loadingReservationPlacesProcessing: boolean;
  loadingReservationPlacesProcessed: boolean;
  loadingReservationPlacesError: string | null;
  reservationPlaces: Place[];
  loadingSelectedPlaceProcessing: boolean;
  loadingSelectedPlaceProcessed: boolean;
  loadingSelectedPlaceError: string | null;
  selectedPlace: Place | null;
  sendProcessing: boolean;
  sendProcessed: boolean;
  sendError: string | null;
}

export const initialState: State = {
  loadingTokenInfoProcessing: false,
  loadingTokenInfoProcessed: false,
  loadingTokenInfoError: null,
  reservation: null,
  customers: [],
  loadingReservationPlacesProcessing: false,
  loadingReservationPlacesProcessed: false,
  loadingReservationPlacesError: null,
  reservationPlaces: [],
  loadingSelectedPlaceProcessing: false,
  loadingSelectedPlaceProcessed: false,
  loadingSelectedPlaceError: null,
  selectedPlace: null,
  sendProcessing: false,
  sendProcessed: false,
  sendError: null
};

export const reducer = (
  state = initialState,
  action: { type: string; [key: string]: any }
): State => {
  switch (action.type) {
    case actionTypes.LOCATIONS_LOADING_TOKEN_INFO_PROCESSING:
      return {
        ...state,
        loadingTokenInfoProcessing: true,
        loadingTokenInfoProcessed: false,
        loadingTokenInfoError: null
      };
    case actionTypes.LOCATIONS_LOADING_TOKEN_INFO_PROCESSED:
      return {
        ...state,
        loadingTokenInfoProcessing: false,
        loadingTokenInfoProcessed: true,
        reservation: action.reservation,
        customers: action.customers
      };
    case actionTypes.LOCATIONS_LOADING_TOKEN_INFO_ERROR:
      return {
        ...state,
        loadingTokenInfoError: action.error,
        loadingTokenInfoProcessing: false
      };
    case actionTypes.LOCATIONS_RESET:
      return initialState;
    case actionTypes.LOCATIONS_LOADING_RESERVATION_LOCATIONS_PROCESSING:
      return {
        ...state,
        loadingReservationPlacesProcessing: true,
        loadingReservationPlacesProcessed: false,
        loadingReservationPlacesError: null
      };
    case actionTypes.LOCATIONS_LOADING_RESERVATION_LOCATIONS_PROCESSED:
      return {
        ...state,
        loadingReservationPlacesProcessing: false,
        loadingReservationPlacesProcessed: true,
        reservationPlaces: action.reservationPlaces
      };
    case actionTypes.LOCATIONS_LOADING_RESERVATION_LOCATIONS_ERROR:
      return {
        ...state,
        loadingReservationPlacesError: action.error,
        loadingReservationPlacesProcessing: false
      };

    case actionTypes.LOCATIONS_LOADING_SELECTED_PLACE_PROCESSING:
      return {
        ...state,
        loadingSelectedPlaceProcessing: true,
        loadingSelectedPlaceProcessed: false,
        loadingSelectedPlaceError: null,
        sendProcessing: false,
        sendProcessed: false,
        sendError: null
      };
    case actionTypes.LOCATIONS_LOADING_SELECTED_PLACE_PROCESSED:
      return {
        ...state,
        loadingSelectedPlaceProcessing: false,
        loadingSelectedPlaceProcessed: true,
        selectedPlace: action.place
      };
    case actionTypes.LOCATIONS_LOADING_SELECTED_PLACE_ERROR:
      return {
        ...state,
        loadingSelectedPlaceError: action.error,
        loadingSelectedPlaceProcessing: false
      };
    case actionTypes.LOCATIONS_SEND_PROCESSING:
      return {
        ...state,
        sendProcessing: true,
        sendProcessed: false,
        sendError: null
      };
    case actionTypes.LOCATIONS_SEND_PROCESSED:
      return {
        ...state,
        sendProcessing: false,
        sendProcessed: true
      };
    case actionTypes.LOCATIONS_SEND_ERROR:
      return {
        ...state,
        sendError: action.error,
        sendProcessing: false
      };
    default:
      console.error("No action provided to reducer");
      return state;
  }
};

export const Context = React.createContext<SystemContext>({
  state: initialState,
  ...locationsActions(() => {
    console.error("Dispatch not implemented");
  })
});

export const actions = locationsActions;
