import React, { useReducer } from "react";
import styles from "./WifiBoxAdd.module.scss";
import Sidebar from "components/layouts/Sidebar";
import {
  initialState as wifiBoxInitialState,
  reducer as wifiBoxReducer,
  Context as WifiBoxContext,
  actions as wifiBoxActions
} from "../../../store/wifiBox";
import WifiBoxAddForm from "components/elements/WifiBox/WifiBoxAddForm";

interface Props {}

const WifiBoxAdd: React.FC<Props> = props => {
  const [wifiBoxState, wifiBoxDispatch] = useReducer(
    wifiBoxReducer,
    wifiBoxInitialState
  );

  return (
    <WifiBoxContext.Provider
      value={{
        state: wifiBoxState,
        ...wifiBoxActions(wifiBoxDispatch)
      }}
    >
      <div className={styles.wrapper}>
        <Sidebar activeLink="wifiBox" />
        <div className={styles.contentWrapper}>
          <div>
            <WifiBoxAddForm />
          </div>
        </div>
      </div>
    </WifiBoxContext.Provider>
  );
};

export default WifiBoxAdd;
