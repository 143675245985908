import React from "react";
import wifiBoxActions, {
  actionTypes,
  WifiBoxActionTypes
} from "./actions/wifiBox";
import { Reservation, Customer } from "./reservation";

export interface WifiBoxContext extends WifiBoxActionTypes {
  state: State;
}

interface Tag {
  key: string;
  label: string;
  url: string;
}

export interface WifiBoxItem {
  Hub: number;
}

interface State {
  form: {
    reservationId: string;
    wifiBox: string;
    wifiBoxIsLinked: boolean;
    boxNumber: string;
  };
  linkProcessing: boolean;
  linkProcessed: boolean;
  linkError: string | null;
  unlinkProcessing: boolean;
  unlinkProcessed: boolean;
  unlinkError: string | null;
  addProcessing: boolean;
  addProcessed: boolean;
  addError: string | null;
  loadingTokenInfoProcessing: boolean;
  loadingTokenInfoProcessed: boolean;
  loadingTokenInfoError: string | null;
  subscribed: boolean;
  loadingList: boolean;
  loadingListError: string | null;
  list: WifiBoxItem[];
  reservation: Reservation | null;
  customer: Customer | null;
  wifiBox: {
    value: string;
    boxNumber: string;
    hub: string;
    isLinked: boolean;
  } | null;
  wifiBoxModalOpen: boolean;
}

export const initialState: State = {
  form: {
    reservationId: "",
    wifiBox: "",
    wifiBoxIsLinked: false,
    boxNumber: ""
  },
  linkProcessing: false,
  linkProcessed: false,
  linkError: null,
  unlinkProcessing: false,
  unlinkProcessed: false,
  unlinkError: null,
  addProcessing: false,
  addProcessed: false,
  addError: null,
  loadingTokenInfoProcessing: false,
  loadingTokenInfoProcessed: false,
  loadingTokenInfoError: null,
  subscribed: false,
  loadingList: false,
  loadingListError: null,
  list: [],
  reservation: null,
  customer: null,
  wifiBox: null,
  wifiBoxModalOpen: false
};

export const reducer = (
  state = initialState,
  action: { type: string; [key: string]: any }
): State => {
  switch (action.type) {
    case actionTypes.WIFI_BOX_SUBSCRIBE:
      return {
        ...state,
        subscribed: true
      };
    case actionTypes.WIFI_BOX_UNSUBSCRIBE:
      return {
        ...state,
        subscribed: false
      };
    case actionTypes.WIFI_BOX_SET_FORM_RESERVATION:
      return {
        ...state,
        linkProcessed: false,
        form: {
          ...state.form,
          reservationId: action.value
        }
      };
    case actionTypes.WIFI_BOX_SET_FORM_BOX_NUMBER:
      return {
        ...state,
        form: {
          ...state.form,
          boxNumber: action.value
        }
      };
    case actionTypes.WIFI_BOX_SET_FORM_WIFI_BOX:
      return {
        ...state,
        linkProcessed: false,
        form: {
          ...state.form,
          wifiBox: action.value,
          wifiBoxIsLinked: action.isLinked
        }
      };
    case actionTypes.WIFI_BOX_LINK_PROCESSING:
      return {
        ...state,
        linkProcessing: true,
        linkProcessed: false,
        linkError: null
      };
    case actionTypes.WIFI_BOX_LINK_PROCESSED:
      return {
        ...state,
        linkProcessing: false,
        linkProcessed: true,
        wifiBox: null,
        reservation: null,
        customer: null,
        form: {
          ...initialState.form
        }
      };
    case actionTypes.WIFI_BOX_LINK_ERROR:
      return {
        ...state,
        linkError: action.error,
        linkProcessing: false
      };
    case actionTypes.WIFI_BOX_UNLINK_PROCESSING:
      return {
        ...state,
        unlinkProcessing: true,
        unlinkProcessed: false,
        unlinkError: null
      };
    case actionTypes.WIFI_BOX_UNLINK_PROCESSED:
      return {
        ...state,
        unlinkProcessing: false,
        unlinkProcessed: true,
        wifiBox: null,
        reservation: null,
        customer: null,
        form: {
          ...initialState.form
        }
      };
    case actionTypes.WIFI_BOX_UNLINK_ERROR:
      return {
        ...state,
        unlinkError: action.error,
        unlinkProcessing: false
      };
    case actionTypes.WIFI_BOX_ADD_PROCESSING:
      return {
        ...state,
        addProcessing: true,
        addProcessed: false,
        addError: null
      };
    case actionTypes.WIFI_BOX_ADD_PROCESSED:
      return {
        ...state,
        addProcessing: false,
        addProcessed: true,
        form: {
          ...initialState.form
        }
      };
    case actionTypes.WIFI_BOX_ADD_ERROR:
      return {
        ...state,
        addError: action.error,
        addProcessing: false
      };
    case actionTypes.WIFI_BOX_LOADING_TOKEN_INFO_PROCESSING:
      return {
        ...state,
        loadingTokenInfoProcessing: true,
        loadingTokenInfoProcessed: false,
        loadingTokenInfoError: null
      };
    case actionTypes.WIFI_BOX_LOADING_TOKEN_INFO_PROCESSED:
      return {
        ...state,
        loadingTokenInfoProcessing: false,
        loadingTokenInfoProcessed: true
      };
    case actionTypes.WIFI_BOX_LOADING_TOKEN_INFO_ERROR:
      return {
        ...state,
        loadingTokenInfoError: action.error,
        loadingTokenInfoProcessing: false
      };
    case actionTypes.WIFI_BOX_GET_LIST:
      return {
        ...state,
        list: [],
        loadingList: true,
        loadingListError: null
      };
    case actionTypes.WIFI_BOX_GET_LIST_ERROR:
      return {
        ...state,
        list: [],
        loadingList: false,
        loadingListError: action.error
      };
    case actionTypes.WIFI_BOX_SET_LIST:
      return {
        ...state,
        list: action.list,
        loadingList: false
      };
    case actionTypes.WIFI_BOX_SET_RESERVATION:
      return {
        ...state,
        reservation: action.reservation,
        customer: action.customer
      };
    case actionTypes.WIFI_BOX_SET_WIFI_BOX:
      return {
        ...state,
        wifiBox: {
          value: action.value,
          boxNumber: action.boxNumber,
          hub: action.hub,
          isLinked: action.isLinked
        }
      };
    case actionTypes.WIFI_BOX_RESET:
      return {
        ...initialState
      };
    case actionTypes.WIFI_BOX_RESET_FORM:
      return {
        ...state,
        form: {
          ...initialState.form
        },
        reservation: initialState.reservation,
        customer: initialState.customer,
        wifiBox: initialState.wifiBox
      };
    case actionTypes.WIFI_BOX_OPEN_MODAL:
      return {
        ...state,
        wifiBoxModalOpen: true
      };
    case actionTypes.WIFI_BOX_CLOSE_MODAL:
      return {
        ...state,
        wifiBoxModalOpen: false
      };
    default:
      console.error("No action provided to reducer");
      return state;
  }
};

export const Context = React.createContext<WifiBoxContext>({
  state: initialState,
  ...wifiBoxActions(() => {
    console.error("Dispatch not implemented");
  })
});

export const actions = wifiBoxActions;
