import { Place } from "store/interface/chat";
import { mobileApi } from "services/api";
import config from "helpers/config";

export const getPlace = async (mapRef: google.maps.Map, placeId: string) => {
  try {
    return new Promise((resolve, reject) => {
      /* eslint-disable no-undef */
      const service = new google.maps.places.PlacesService(mapRef);
      /* eslint-enable no-undef */
      const request = {
        placeId
      };
      service.getDetails(request, async data => {
        const result = await mapToPlace(data);
        resolve(result);
      });
    });
  } catch (e) {
    // throw new Error(e);
  }
};

export const getPlacePhotoUrl: any = (photoReference: string) => {
  return `https://maps.googleapis.com/maps/api/place/photo/json?photoreference=${encodeURIComponent(
    photoReference
  )}&maxwidth=600&maxheight=600&key=${encodeURIComponent(
    config.REACT_APP_GOOGLE_API_KEY
  )}`;
};

export const mapType = (type: string) =>
  `${type.charAt(0).toUpperCase()}${type.slice(1).replace(/_/g, " ")}`;

export const mapToPlace = async (
  place: google.maps.places.PlaceResult | any
): Promise<Place | null> => {
  if (!place || !place.geometry) {
    return null;
  }

  const getAddressComponent = (
    components: any,
    type: string
  ): string | null => {
    const component =
      components &&
      components.find((comp: any) => comp.types.indexOf(type) !== -1);
    return component ? component.long_name : null;
  };

  const city = getAddressComponent(place.address_components, "locality");
  const postalCode = getAddressComponent(
    place.address_components,
    "postal_code"
  );
  let picture = null;

  if (place.photos) {
    try {
      let url = "";

      if (typeof place.photos[0].getUrl !== "function") {
        url = getPlacePhotoUrl(place.photos[0].photo_reference);
      } else {
        url = place.photos[0].getUrl({ maxHeight: 600, maxWidth: 800 });
      }

      picture = url;
      // const response = await mobileApi({
      //   path: "/host/mapsImg",
      //   params: {
      //     URL: url
      //   }
      // });

      // if (response && "ImageUrl" in response) {
      //   picture = response.ImageUrl;
      // }
    } catch (e) {
      console.error(e);
    }
  }

  return {
    id: place.place_id,
    location: {
      lat:
        typeof place.geometry.location.lat === "function"
        ? place.geometry.location.lat()
        : place.geometry.location.lat,
      lng:
        typeof place.geometry.location.lng === "function"
        ? place.geometry.location.lng()
        : place.geometry.location.lng,
    },
    name: place.name,
    rating: place.rating ?? null,
    website: place.website ?? null,
    streetAddress: place.formatted_address,
    picture,
    postalCode,
    city,
    types: place.types.map(mapType),
    openingHours: place.opening_hours
      ? {
          ...JSON.parse(JSON.stringify(place.opening_hours)), // remove function from object
          open_now: place.opening_hours.isOpen()
        }
      : null,
    phone: place.international_phone_number ?? null
  };
};
