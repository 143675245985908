import React from "react";
import Paragraph from "../../elements/Paragraph";

const NotFound = props => {
  return (
    <div>
      <Paragraph error>Pagina niet gevonden</Paragraph>
    </div>
  );
};

export default NotFound;
