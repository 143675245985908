import React, { InputHTMLAttributes, useState } from "react";
import styles from "./Input.module.scss";
import SearchIcon from "assets/svg/search.svg";
import SmileyIcon from "assets/svg/smiley.svg";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  type: "checkbox" | "search" | "message" | "password" | "number" | "text";
  onSelectEmoji?: (emoji: string) => void;
}

const pickerStyle = {
  width: "100%",
  height: "250px",
  overflow: "hidden"
};

const Input: React.FC<Props> = props => {
  const wrapperClasses = [styles.wrapper];
  const inputClasses = [styles.input];
  const [showPicker, setShowPicker] = useState<boolean>(false);
  const inputRef = React.createRef<HTMLInputElement>();

  const { type, onSelectEmoji } = props;

  if (type === "checkbox") {
    inputClasses.push(styles.checkbox);
  }

  if (type === "search") {
    wrapperClasses.push(styles.search);
  }

  if (type === "message") {
    wrapperClasses.push(styles.message);
  }

  return (
    <div>
      {type === "message" && showPicker && (
        <Picker
          autoFocus={showPicker}
          native
          style={pickerStyle}
          onSelect={emoji => {
            setShowPicker(false);
            if (onSelectEmoji && "native" in emoji) {
              onSelectEmoji(emoji.native);
            }
            if (inputRef.current) {
              inputRef.current.focus();
            }
          }}
        />
      )}
      <div className={wrapperClasses.join(" ")}>
        {type === "search" && <img alt="Search" src={SearchIcon} />}
        <input ref={inputRef} className={inputClasses.join(" ")} {...props} />
        {type === "message" && (
          <img
            alt="Show Emoji's"
            src={SmileyIcon}
            onClick={() => setShowPicker(!showPicker)}
          />
        )}
      </div>
    </div>
  );
};

export default Input;
