import React, { useContext, useState, useEffect } from "react";
import styles from "./ChatMessages.module.scss";
import { Context as ChatContext } from "../../../../store/chat";
import { Message, Place } from "../../../../store/interface/chat";
import { Context as AuthorizationContext } from "../../../../store/authorization";
import ChatMessageForm from "../ChatMessageForm";
import ChatMessagesList from "../ChatMessagesList";
import ChatMessagesHeader from "../ChatMessagesHeader";
import Map from "components/elements/Map";
import SelectedPlace from "components/elements/SelectedPlace";
import { timestamp, datePlusDays } from "helpers/date";
import { firestore } from "services/firebase";
import firebase from "firebase/app";
import "firebase/firestore";
import { fcmApi } from "services/api";

interface Props {}

const ChatMessages: React.FC<Props> = (props) => {
  const { state: chatState, cleanChatState } = useContext(ChatContext);

  const { state: authorizationState } = useContext(AuthorizationContext);
  const [view, setView] = useState<"messages" | "map">("messages");
  const [selectedPlace, setSelectedPlace] = useState<Place | null>(null);
  const [userLocation, setUserLocation] = useState<Message["location"] | null>(
    null
  );

  const {
    activeChatRoomId,
    activeChatRoom,
    activeBroadcastRoomId,
    activeBroadcastRoom,
    chatRoomMessages,
    chatRoomList,
  } = chatState;

  useEffect(() => {
    const chatroom = chatRoomList.filter(
      (chatRoom) => chatRoom.id === activeChatRoomId
    );
    if (chatroom.length === 0) {
      cleanChatState();
    }
  }, [chatRoomList]); // eslint-disable-line react-hooks/exhaustive-deps

  const lastUserLocationMessage =
    chatRoomMessages.length > 0
      ? chatRoomMessages.find((message) => !!message.location)
      : false;

  const places =
    chatRoomMessages.length > 0
      ? (chatRoomMessages
          .map((message) => message.place)
          .filter((place) => place !== null && place !== undefined)
          .filter(
            (place, index, self) =>
              self.findIndex(
                (selfPlace) =>
                  !!selfPlace && !!place && selfPlace.id === place.id
              ) === index
          ) as Place[])
      : [];

  async function sendFCMMessage(fcmToken: string, body: string): Promise<any> {
    try {
      const data = {
        to: fcmToken,
        notification: {
          title:
            "CityHost " + `${authorizationState.authorizedHost!.FirstName}`,
          body: body,
          mutable_content: true,
          sound: "Tri-tone",
          // image: "https://faq.whatsapp.com/images/presma/whatsapp/whatsapp_logo-2x.png"
        },
      };

      // Send notification
      const response = await fcmApi({ data });
    } catch (e) {
      console.error("sendFCMMessage error", e);
    }
  }

  const sendMessageByReservationId = (
    reservationId: string,
    message: string
  ) => {
    let m: Message = {
      text: message,
      sender: {
        firstName: authorizationState.authorizedHost!.FirstName,
        picture: authorizationState.authorizedHost!.Picture,
      },
      hotelCode: authorizationState.authorizedHotelCode,
      messageType: "HOST_MESSAGE",
      timetoken: timestamp(),
      type: activeBroadcastRoomId ? "broadcast" : "default",
    };

    const chatRooms = firestore()
      .collection("chatRooms")
      .where("booking.id", "==", reservationId)
      .get()
      .then((response) => {
        response.docs.forEach((doc) => {
          const docRef = firestore().collection("chatRooms").doc(doc.id);
          docRef.update({
            status: "done",
            messages: firebase.firestore.FieldValue.arrayUnion(m),
            updatedAt: timestamp(),
          });

          docRef.get().then((result) => {
            const data = result.data();
            if (data) {
              sendFCMMessage(data.notificationToken, m.text);
            }
          });
        });
      });
  };

  const sendChatRoomMessage = (message: string) => {
    let m: Message = {
      text: message,
      sender: {
        firstName: authorizationState.authorizedHost!.FirstName,
        picture: authorizationState.authorizedHost!.Picture,
      },
      hotelCode: authorizationState.authorizedHotelCode,
      messageType: "HOST_MESSAGE",
      timetoken: timestamp(),
      type: activeBroadcastRoomId ? "broadcast" : "default",
    };

    if (selectedPlace) {
      m = {
        ...m,
        place: selectedPlace,
      };
    }

    // Add new chat message to Firebase and send push notification
    if (activeChatRoomId) {
      const docRef = firestore().collection("chatRooms").doc(activeChatRoomId);
      docRef.update({
        status: "done",
        messages: firebase.firestore.FieldValue.arrayUnion(m),
        updatedAt: timestamp(),
      });

      docRef.get().then((result) => {
        const data = result.data();
        if (data) {
          sendFCMMessage(data.notificationToken, m.text);
        }
      });
    }

    // Add new broadcast message to Firebase
    if (activeBroadcastRoomId) {
      const docRef = firestore()
        .collection("broadcastRooms")
        .doc(activeBroadcastRoomId);
      docRef.update({
        messages: firebase.firestore.FieldValue.arrayUnion(m),
        updatedAt: timestamp(),
      });

      /**
       * Send to all chatrooms with active booking
       * where hotelCode is the same as host
       * and active booking or two days before arrival
       */
      const chatRooms = firestore()
        .collection("chatRooms")
        .where("hotelCode", "==", activeBroadcastRoomId)
        .get()
        .then((response) => {
          let batch = firebase.firestore().batch();
          response.docs.forEach((doc) => {
            const docRef = firebase
              .firestore()
              .collection("chatRooms")
              .doc(doc.id);
            docRef.get().then((result) => {
              const data = result.data();

              if (
                data &&
                data.booking &&
                data.booking.property == activeBroadcastRoomId &&
                data.booking.checkIn <= datePlusDays(0) &&
                data.booking.checkOut >= datePlusDays(0)
              ) {
                docRef.update({
                  status: data.messages.length !== 0 ? data.status : "done",
                  messages: firebase.firestore.FieldValue.arrayUnion(m),
                  updatedAt: timestamp(),
                });

                docRef.get().then((result) => {
                  const data = result.data();
                  if (data) {
                    sendFCMMessage(data.notificationToken, m.text);
                  }
                });
              }
            });
          });
          batch.commit();
        });
    }
    setSelectedPlace(null);
  };

  return (
    <div className={styles.wrapper}>
      <ChatMessagesHeader view={view} setView={setView} />
      <div
        className={
          view !== "messages" ? styles.hidden : styles.messagesContainer
        }
      >
        <ChatMessagesList
          messages={chatRoomMessages}
          onClickUserLocation={(location) => {
            setUserLocation(location);
            setView("map");
          }}
        />
      </div>
      <div
        className={view !== "messages" ? styles.hidden : styles.formContainer}
      >
        {selectedPlace && (
          <SelectedPlace
            type="form"
            imgSrc={selectedPlace.picture ? selectedPlace.picture : ""}
            title={selectedPlace.name ? selectedPlace.name : ""}
            address={
              selectedPlace.streetAddress ? selectedPlace.streetAddress : ""
            }
            rating={selectedPlace.rating}
            onClose={() => setSelectedPlace(null)}
          />
        )}
        {(activeChatRoom !== null || activeBroadcastRoom !== null) && (
          <ChatMessageForm
            sendMessage={(message) => {
              sendChatRoomMessage(message);
            }}
            onClickLocation={() => setView("map")}
          />
        )}
      </div>
      <div className={view !== "map" ? styles.hidden : styles.mapContainer}>
        <Map
          onSelectPlace={(selectedPlace) => {
            setSelectedPlace(selectedPlace);
            setView("messages");
            setUserLocation(null);
          }}
          places={places}
          userLocation={
            userLocation
              ? userLocation
              : lastUserLocationMessage
              ? lastUserLocationMessage.location
              : null
          }
          userPicture={
            activeChatRoom !== null && activeChatRoom.owner.picture
              ? activeChatRoom.owner.picture
              : null
          }
        />
      </div>
    </div>
  );
};

export default ChatMessages;
