import {
  mobileApi,
  setMobileApiSessionToken,
  setHotelApiKey
} from "services/api";
import { Host } from "store/authorization";
import { Action, ActionType } from "./types";
import { setPubNubKeys } from "services/pubNub";
import { setFireBaseConfig } from "services/firebase";
import { analyticsInitialize } from "services/google";

interface setSignInFormValue {
  (key: string, value: string | null): void;
}

interface signIn {
  (host: Host, password: string, hotelCode: string): void;
}

interface signOut {
  (): void;
}

interface fetchAvailableHosts {
  (): Promise<void>;
}

interface addStorageListener {
  (): void;
}

export interface AuthorizationActionTypes {
  fetchAvailableHosts: fetchAvailableHosts;
  setSignInFormValue: setSignInFormValue;
  signIn: signIn;
  signOut: signOut;
  addStorageListener: addStorageListener;
}

export const actionTypes = {
  SIGNING_IN: "authorization/SIGNING_IN",
  SIGNED_IN: "authorization/SIGNED_IN",
  SIGNING_IN_ERROR: "authorization/SIGNING_IN_ERROR",
  AVAILABLE_HOSTS_FETCHING: "authorization/AVAILABLE_HOSTS_FETCHING",
  AVAILABLE_HOSTS_FETCHED: "authorization/AVAILABLE_HOSTS_FETCHED",
  AVAILABLE_HOSTS_ERROR: "authorization/AVAILABLE_HOSTS_ERROR",
  SIGN_IN_FORM_SET_VALUE: "authorization/SIGN_IN_FORM_SET_VALUE",
  ADDED_STORAGE_LISTENER: "authorization/ADDED_STORAGE_LISTENER",
  SIGN_OUT: "authorization/SIGN_OUT"
};

const fetchAvailableHosts: Action = (
  dispatch
): fetchAvailableHosts => async () => {
  dispatch({
    type: actionTypes.AVAILABLE_HOSTS_FETCHING
  });
  const availableHosts = await mobileApi({
    path: "/host/all"
  });

  dispatch({
    type: actionTypes.AVAILABLE_HOSTS_FETCHED,
    availableHosts
  });
};

const setSignInFormValue: Action = (dispatch): setSignInFormValue => (
  key,
  value
) => {
  dispatch({
    type: actionTypes.SIGN_IN_FORM_SET_VALUE,
    key,
    value
  });
};

const signIn: Action = (dispatch): signIn => async (
  host,
  password,
  hotelCode
) => {
  dispatch({
    type: actionTypes.SIGNING_IN
  });

  try {
    const response = await mobileApi({
      path: `/host/login`,
      method: "POST",
      params: {
        HotelCode: hotelCode,
        HostId: host.id,
        Password: password
      }
    });

    setMobileApiSessionToken(response.Config.SessionToken);
    setHotelApiKey(response.Config.HotelApiKey);
    setPubNubKeys(
      response.Config.PubNub_Publish_Key,
      response.Config.PubNub_Subscribe_Key,
      response.Config.AuthKey,
      `HOST_${response.Host.id}`
    );
    if(response.Config.Firebase){
      setFireBaseConfig(
        response.Config.Firebase.REACT_APP_API_KEY,
        response.Config.Firebase.REACT_APP_AUTH_DOMAIN,
        response.Config.Firebase.REACT_APP_DATABASE_URL,
        response.Config.Firebase.REACT_APP_PROJECT_ID,
        response.Config.Firebase.REACT_APP_STORAGE_BUCKET,
        response.Config.Firebase.REACT_APP_MESSAGING_SENDER_ID,
        response.Config.Firebase.REACT_APP_APP_ID,
        response.Config.Firebase.REACT_APP_MEASUREMENT_ID
      );
    }

    dispatch({
      type: actionTypes.SIGNED_IN,
      authorizedHost: response.Host,
      authorizedHotelCode: hotelCode,
      authorizedCredentials: response.Config
    });

    localStorage.setItem(
      "console-authorized",
      JSON.stringify({
        authorizedHost: response.Host,
        authorizedCredentials: response.Config,
        authorizedHotelCode: hotelCode
      })
    );

    analyticsInitialize(response.Host.id, hotelCode);
  } catch (e) {
    console.error(e);
    dispatch({
      type: actionTypes.SIGNING_IN_ERROR,
      error: "Incorrect credentials"
    });
  }
};

const addStorageListener: Action = (dispatch): addStorageListener => () => {
  window.addEventListener("storage", () => {
    const authorizationData = localStorage.getItem("console-authorized");
    if (!authorizationData) {
      signOut(dispatch)();
    }
  });

  dispatch({
    type: actionTypes.ADDED_STORAGE_LISTENER
  });
};

const signOut: Action = (dispatch): signOut => () => {
  dispatch({
    type: actionTypes.SIGN_OUT
  });

  if (localStorage.getItem("console-authorized")) {
    localStorage.removeItem("console-authorized");
  }
};

const actions = (
  dispatch: React.Dispatch<ActionType>
): AuthorizationActionTypes => ({
  fetchAvailableHosts: fetchAvailableHosts(dispatch),
  setSignInFormValue: setSignInFormValue(dispatch),
  signIn: signIn(dispatch),
  signOut: signOut(dispatch),
  addStorageListener: addStorageListener(dispatch)
});

export default actions;
