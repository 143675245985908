import React, { useContext, useEffect } from "react";
import { Context as LocationsContext } from "../../../../store/locations";
import styles from "./Places.module.scss";
import Paragraph from "components/elements/Paragraph";
import LocationsWrapper from "../LocationsWrapper";
import { RouteComponentProps } from "react-router";

interface Props extends RouteComponentProps {
  onClickLocation: (id: string | undefined) => void;
}

const Places: React.FC<Props> = ({ onClickLocation, history, match }) => {
  const { state: locationsState, fetchReservationPlaces } = useContext(
    LocationsContext
  );
  const { reservation, reservationPlaces } = locationsState;

  useEffect(() => {
    if (reservation) {
      fetchReservationPlaces(reservation.Id);
    } else {
      history.push("/locations");
    }
  }, [reservation]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <LocationsWrapper title="Your locations">
      {reservationPlaces.map(location => (
        <div
          onClick={() => onClickLocation(location.id)}
          className={styles.location}
          key={location.id}
        >
          <div className={styles.imageWrapper}>
            <img src={location.picture ? location.picture : ""} alt="" />
          </div>
          <div>
            {location.name && <Paragraph strong>{location.name}</Paragraph>}
            {location.types && location.types.length > 0 && (
              <Paragraph small className={styles.locationType}>
                {location.types[0]}
              </Paragraph>
            )}
          </div>
        </div>
      ))}
    </LocationsWrapper>
  );
};

export default Places;
