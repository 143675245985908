import React from "react";
import notificationActions, {
  actionTypes,
  NotificationActionTypes
} from "./actions/notification";

export interface SystemContext extends NotificationActionTypes {
  state: State;
}

export interface State {
  notification: null | Notification;
  showNotification: boolean;
}

export interface Notification {
  title: string;
  type: "success" | "error";
}

export const initialState: State = {
  notification: null,
  showNotification: false
};

export const reducer = (
  state = initialState,
  action: { type: string; [key: string]: any }
): State => {
  switch (action.type) {
    case actionTypes.SHOW_NOTIFICATION:
      return {
        ...state,
        notification: action.notification,
        showNotification: true
      };
    case actionTypes.HIDE_NOTIFICATION:
      return {
        ...state,
        showNotification: false
      };
    default:
      console.error("No action provided to reducer");
      return state;
  }
};

export const Context = React.createContext<SystemContext>({
  state: initialState,
  ...notificationActions(() => {
    console.error("Dispatch not implemented");
  })
});

export const actions = notificationActions;
