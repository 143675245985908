import Geocode from "react-geocode";
import config from "helpers/config";
import ReactGA from "react-ga";

Geocode.setApiKey(config.REACT_APP_GOOGLE_API_KEY);

const addressFromCoordinatesCache: {
  [k: string]: string | null;
} = {};

export const getAddressFromCoordinates = async (lat: number, lng: number) => {
  const cacheKey = `${lat}-${lng}`;

  if (cacheKey in addressFromCoordinatesCache) {
    return addressFromCoordinatesCache[cacheKey];
  }

  try {
    const response = await Geocode.fromLatLng(lat, lng);
    const addressParts = [];

    if (
      response.results.length > 0 &&
      response.results[0].address_components.length > 0
    ) {
      const address = response.results[0].address_components.find(
        (addressComponent: { types: string[] }) =>
          addressComponent.types.indexOf("route") !== -1
      );

      if (address) {
        addressParts.push(address.long_name);
      }

      const number = response.results[0].address_components.find(
        (addressComponent: { types: string[] }) =>
          addressComponent.types.indexOf("street_number") !== -1
      );
      if (number) {
        addressParts.push(number.long_name);
      }
    }

    if (addressParts.length === 0) {
      addressFromCoordinatesCache[cacheKey] = null;
      return null;
    }

    const ret = addressParts.join(" ");

    addressFromCoordinatesCache[cacheKey] = ret;

    return ret;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const analyticsInitialize = (userId: string, property: string) => {
  ReactGA.initialize(config.REACT_APP_GOOGLE_ANALYTICS_ID, {
    debug: true,
    gaOptions: {
      userId
    }
  });

  ReactGA.set({
    dimension1: property,
    dimension4: `${userId}`
  });
};

export const analyticsReceivedMessage = (reservationId: string) => {
  ReactGA.set({
    dimension2: reservationId
  });

  ReactGA.event({
    category: "Chat",
    action: "Received message",
    label: "GUEST_MESSAGE"
  });

  ReactGA.set({
    dimension2: null
  });
};

export const analyticsSendMessage = (
  reservationId: string,
  messageType: string,
  hostName: string,
  previousMessageTimetoken: number | null
) => {
  ReactGA.set({
    dimension2: reservationId,
    dimension3: hostName
  });
  if (typeof previousMessageTimetoken === "number") {
    const secondsDifference = Math.round(
      parseInt(
        `${new Date().getTime() - Math.round(previousMessageTimetoken)}`,
        10
      ) / 1000
    );

    ReactGA.set({
      metric1: secondsDifference
    });
  }
  ReactGA.event({
    category: "Chat",
    action: "Send Message",
    label: messageType
  });

  ReactGA.set({
    metric1: null,
    dimension2: null,
    dimension3: null
  });
};

export const analyticsLinkWifiBox = (
  reservationId: string,
  wifiBoxNumber: string
) => {
  ReactGA.set({
    dimension2: reservationId
  });

  ReactGA.event({
    category: "WifiBox",
    action: "Link",
    label: `${wifiBoxNumber}`
  });

  ReactGA.set({
    dimension2: null
  });
};

export const analyticsUnlinkWifiBox = (wifiBoxNumber: string) => {
  ReactGA.event({
    category: "WifiBox",
    action: "Unlink",
    label: `${wifiBoxNumber}`
  });
};
