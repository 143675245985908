import React from "react";
import PropTypes from "prop-types";
import styles from "./Paragraph.module.scss";

const Paragraph = props => {
  const {
    children,
    medium,
    intermediate,
    big,
    small,
    strong,
    className,
    maxLines,
    error,
    success,
    ...otherProps
  } = props;
  const classes = [styles.paragraph];

  if (small) {
    classes.push(styles.small);
  }

  if (big) {
    classes.push(styles.big);
  }

  if (medium) {
    classes.push(styles.medium);
  }

  if (intermediate) {
    classes.push(styles.intermediate);
  }

  if (error) {
    classes.push(styles.error);
  }

  if (success) {
    classes.push(styles.success);
  }

  if (strong) {
    classes.push(styles.strong);
  }

  if (maxLines) {
    classes.push(styles.maxLines);
    classes.push(styles[`maxLines-${maxLines}`]);
  }

  if (className) {
    classes.push(className);
  }

  return (
    <p className={classes.join(" ")} {...otherProps}>
      {children}
    </p>
  );
};

Paragraph.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  small: PropTypes.bool,
  big: PropTypes.bool,
  intermediate: PropTypes.bool,
  medium: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  strong: PropTypes.bool,
  maxLines: PropTypes.number
};

export default Paragraph;
