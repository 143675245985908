import React, { useContext, useEffect, useState } from "react";
import { useTimeoutFn } from "react-use";
import styles from "./Locations.module.scss";
import Map from "components/elements/Map";
import { Context as LocationsContext } from "../../../store/locations";
import { Context as AuthorizationContext } from "../../../store/authorization";
import Paragraph from "components/elements/Paragraph";
import ScanWristband from "assets/svg/scan_wristband.svg";
import LoadingIcon from "assets/img/loading.gif";
import Drawer from "components/layouts";
import { Switch, Route, RouteComponentProps } from "react-router";
import Places from "components/elements/Locations/Places";
import Place from "components/elements/Locations/Place";
import Region from "components/elements/Locations/Region/Region";
import { Helmet } from "react-helmet";

interface Props extends RouteComponentProps {}

const Locations: React.FC<Props> = ({ match, history }) => {
  const { state: authorizationState } = useContext(AuthorizationContext);
  const [mapRef, setMapRef] = useState<google.maps.Map | undefined>(undefined);

  const {
    state: locationsState,
    subscribeToScanner,
    unsubscribeFromScanner,
    reset,
  } = useContext(LocationsContext);

  const { hotelProperties, authorizedHotelCode } = authorizationState;

  const {
    loadingTokenInfoProcessing,
    reservation,
    reservationPlaces,
  } = locationsState;
  const [selectedPlaceId, setSelectedPlaceId] = useState<string | undefined>(
    undefined
  );
  const [selectedRegionId, setSelectedRegionId] = useState<string | undefined>(
    undefined
  );

  const [fullScreenEnabled, setFullScreenEnabled] = useState(false);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const locationPathName = history.location.pathname;
  const matchUrl = match.url;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [shouldLogout, _, resetLogoutTimeout] = useTimeoutFn(() => {
    reset();
    setSelectedPlaceId(undefined);
    setSelectedRegionId(undefined);
    history.push(`${matchUrl}`);
  }, 60 * 5 * 1000);

  useEffect(() => {
    resetLogoutTimeout();
  }, [reservation, selectedPlaceId, selectedRegionId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (reservation) {
      setIsLoggedIn(true);
    }

    if (reservation && locationPathName === matchUrl) {
      history.push(`${matchUrl}/places`);
      setIsLoggedIn(true);
    } else if (!reservation && isLoggedIn) {
      history.push(`${matchUrl}`);
      setSelectedPlaceId(undefined);
      setSelectedRegionId(undefined);
      setIsLoggedIn(false);

      if (
        !selectedPlaceId &&
        !selectedRegionId &&
        mapRef &&
        authorizedHotelCode
      ) {
        mapRef.setZoom(
          hotelProperties[authorizedHotelCode].locationsDefaultOptions.zoom
        );
        mapRef.panTo(
          hotelProperties[authorizedHotelCode].locationsDefaultOptions.center
        );
      }
    }
  }, [reservation, locationPathName, matchUrl, isLoggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (authorizedHotelCode) {
      subscribeToScanner(authorizedHotelCode);
    }
    return () => {
      if (authorizedHotelCode) {
        unsubscribeFromScanner(authorizedHotelCode);
      }
    };
  }, [authorizedHotelCode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (fullScreenEnabled && document.fullscreenEnabled) {
      document.documentElement.requestFullscreen();
    }
  }, [fullScreenEnabled]);

  if (!authorizedHotelCode) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <meta http-equiv="refresh" content={`${12 * 60 * 60}`} />
      </Helmet>
      {!fullScreenEnabled && (
        <button
          className={styles.fullScreenButton}
          onClick={() => setFullScreenEnabled(true)}
        ></button>
      )}

      <Switch>
        <Route
          path={[`${match.url}/places`, `${match.url}/region`]}
          render={() => (
            <Drawer>
              <Switch>
                <Route
                  path={`${match.url}/places`}
                  exact
                  render={(props) => (
                    <Places
                      {...props}
                      onClickLocation={(id) => setSelectedPlaceId(id)}
                    />
                  )}
                />
                <Route
                  path={`${match.url}/places/:id`}
                  exact
                  render={(props) => (
                    <Place
                      {...props}
                      mapRef={mapRef}
                      onClose={() => {
                        setSelectedPlaceId(undefined);

                        history.push(
                          isLoggedIn ? `${matchUrl}/places` : matchUrl
                        );
                      }}
                    />
                  )}
                />
                <Route
                  path={`${match.url}/region/:id`}
                  exact
                  render={(props) => (
                    <Region
                      {...props}
                      onClose={() => {
                        setSelectedRegionId(undefined);
                        history.push(matchUrl);
                      }}
                    />
                  )}
                />
              </Switch>
            </Drawer>
          )}
        />
      </Switch>
      <Map
        setMapRef={setMapRef}
        onChangeSelectedPlace={(place) => {
          if (place) {
            history.push(`${match.url}/places/${place.id}`);
            setSelectedPlaceId(place.id);
          } else {
            setSelectedPlaceId(undefined);
          }
        }}
        onChangeSelectedRegion={(region) => {
          if (region) {
            history.push({
              pathname: `${match.url}/region/${region.properties.id}`,
              state: {
                region,
              },
            });
            setSelectedRegionId(region.properties.id);
          } else {
            setSelectedRegionId(undefined);
          }
        }}
        showSelectedPlaceOverlay={false}
        places={reservationPlaces}
        selectedPlaceId={selectedPlaceId}
        selectedRegionId={selectedRegionId}
        userLocation={null}
        userPicture={null}
        regions={hotelProperties[authorizedHotelCode].regions}
        showDirections
        initialCenter={
          hotelProperties[authorizedHotelCode].locationsDefaultOptions.center
        }
        initialZoom={
          hotelProperties[authorizedHotelCode].locationsDefaultOptions.zoom
        }
      />
      {!reservation && (
        <div className={styles.scanWrapper}>
          {!loadingTokenInfoProcessing && (
            <img width="238" src={ScanWristband} alt="Scan your wristband" />
          )}
          {loadingTokenInfoProcessing && (
            <div className={styles.loadingWrapper}>
              <img src={LoadingIcon} alt="Loading" />
            </div>
          )}
          <div className={styles.scanText}>
            <Paragraph big strong>
              Scan your wristband
            </Paragraph>
          </div>
        </div>
      )}
    </div>
  );
};

export default Locations;
