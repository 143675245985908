import React, { useEffect } from "react";
import styles from "./ChatMessagesList.module.scss";
import { Message } from "store/interface/chat";
import Avatar from "components/elements/Avatar";
import ChatMessageLocation from "../ChatMessageLocation";
import Paragraph from "components/elements/Paragraph";
import SelectedPlace from "components/elements/SelectedPlace";
import { fromNowOrNow } from "helpers/date";
import BroadcastIcon from "assets/svg/broadcast-black.svg";

interface Props {
  messages: Message[];
  onClickUserLocation: (location: Message["location"]) => void;
}

const ChatMessagesList: React.FC<Props> = props => {
  const { messages, onClickUserLocation } = props;
  let bottomElement: HTMLElement | null = null;

  useEffect(() => {
    if (messages && bottomElement) {
      bottomElement.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.wrapper}>
      {messages.length > 0 && (
        <>
          { 
            React.Children.toArray(
              messages.map(message => 
                <div
                  className={[
                    styles.messageItem,
                    message.messageType.indexOf("OWNER_MESSAGE") === 0
                      ? styles.messageItemReversed
                      : ""
                  ].join(" ")}
                >
                  <Avatar src={message.sender.picture} alt={message.sender.firstName} />
                  <div className={styles.message}>
                    <div
                      className={[
                        styles.messageText,
                        message.place && styles.messagePlace,
                        message.type === "broadcast" && styles.messageBroadcast
                      ].join(" ")}
                    >
                      {message.text}
                      <Paragraph small className={styles.messageSender}>
                        {message.sender.firstName}
                      </Paragraph>
                    </div>
                    {message.place && (
                      <div className={styles.place}>
                        <SelectedPlace
                          type="message"
                          imgSrc={message.place.picture ? message.place.picture : ""}
                          title={message.place.name ? message.place.name : ""}
                          address={
                            message.place.streetAddress
                              ? message.place.streetAddress
                              : ""
                          }
                          rating={message.place.rating}
                        />
                      </div>
                    )}
                    <div className={styles.messageMeta}>
                      <Paragraph small>{fromNowOrNow(message.timetoken)}</Paragraph>
                      {message.location && (
                        <ChatMessageLocation
                          lat={message.location.lat}
                          lng={message.location.lng}
                          onClickLocation={onClickUserLocation}
                        />
                      )}
                      {message.type === "broadcast" && (
                        <Paragraph small className={styles.broadcastLabel}>
                          <img
                            width="14"
                            src={BroadcastIcon}
                            alt="Broadcasted message"
                          />{" "}
                          Broadcasted message
                        </Paragraph>
                      )}
                    </div>
                  </div>
                </div>
              )
            )
          }
        </>
      )}
      <div ref={ref => (bottomElement = ref)} />
    </div>
  );
};

export default ChatMessagesList;
