import React from "react";
import styles from "./Region.module.scss";
import { RouteComponentProps } from "react-router";
import LocationsWrapper from "../LocationsWrapper";
import Button from "components/elements/Button";
import Paragraph from "components/elements/Paragraph";

interface Region {
  properties: {
    id: string;
    name: string;
    description: string;
    pictureUrl: string;
    type: string;
  };
}

interface Props extends RouteComponentProps<{ id: string }, any, any> {
  onClose: () => void;
}

const Region: React.FC<Props> = ({ history, onClose, location }) => {
  const region =
    location && location.state ? (location.state.region as Region) : null;

  if (!region) {
    history.push("/locations");
    return null;
  }

  const { pictureUrl, name, description, type } = region.properties;

  return (
    <LocationsWrapper>
      <div className={styles.imageWrapper}>
        <img src={pictureUrl} alt={name} />
        <Button className={styles.closeButton} link onClick={onClose} />
      </div>
      <div className={styles.wrapper}>
        <h3>{name}</h3>
        {type && (
          <Paragraph className={styles.locationType} small>
            {type}
          </Paragraph>
        )}
        {description && <Paragraph small>{description}</Paragraph>}
      </div>
    </LocationsWrapper>
  );
};

export default Region;
