import React, { useContext, useEffect } from "react";
import styles from "./WifiBoxReservationForm.module.scss";
import { Context as WifiBoxContext } from "../../../../store/wifiBox";
import { Context as AuthorizationContext } from "../../../../store/authorization";
import { Context as NotificationContext } from "../../../../store/notification";
import Paragraph from "components/elements/Paragraph";
import Button from "components/elements/Button";
import H1 from "components/elements/H1";
import LoadingIcon from "assets/img/loading.gif";
import { Reservation, Customer } from "store/reservation";

interface Props {
  initialReservation: Reservation | null;
  initialCustomers: Customer[] | null;
}

const WifiBoxReservationForm: React.FC<Props> = (props) => {
  const { initialReservation, initialCustomers } = props;

  const {
    state: wifiBoxState,
    link,
    unlink,
    resetForm,
    subscribeToScanner,
    unsubscribeFromScanner,
    setFormReservation,
  } = useContext(WifiBoxContext);
  const { state: authorizationState } = useContext(AuthorizationContext);
  const { showNotification } = useContext(NotificationContext);

  const {
    linkError,
    linkProcessing,
    wifiBox,
    reservation,
    customer,
    linkProcessed,
    loadingTokenInfoProcessing,
    loadingTokenInfoError,
  } = wifiBoxState;
  const {
    reservationId,
    wifiBox: wifiBoxValue,
    wifiBoxIsLinked,
    boxNumber,
  } = wifiBoxState.form;

  const { authorizedHost, authorizedHotelCode } = authorizationState;

  const showLinkButton = wifiBox && reservationId && !wifiBoxIsLinked;
  const showUnlinkButton = wifiBox && wifiBoxIsLinked;

  useEffect(() => {
    if (authorizedHotelCode) {
      subscribeToScanner(authorizedHotelCode);
    }
    return () => {
      if (authorizedHotelCode) {
        unsubscribeFromScanner(authorizedHotelCode);
      }
    };
  }, [authorizedHotelCode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      initialReservation &&
      initialCustomers &&
      initialCustomers.length > 0 &&
      initialReservation.Hub
    ) {
      setFormReservation({
        reservation: initialReservation,
        customer: initialCustomers[0],

        value: initialReservation.Id,
        hub: initialReservation.Hub.toString(),
      });
    }
  }, [initialReservation, initialCustomers]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (linkProcessed) {
      showNotification({
        title: `Wifi box ${boxNumber} successfully linked`,
        type: "success",
      });
    }
  }, [linkProcessed]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!authorizedHost) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <H1>Connect wifi box</H1>
      {!linkProcessing && (reservationId || wifiBoxValue) && (
        <div className={styles.selectedInformation}>
          <div>
            <Paragraph>HUB</Paragraph>
            <Paragraph medium>
              {wifiBox && wifiBox.hub
                ? wifiBox.hub
                : reservation && reservation.Hub
                ? reservation.Hub.Number
                : "--"}
            </Paragraph>
          </div>
          <div>
            <Paragraph>GUEST</Paragraph>
            <Paragraph medium>
              {customer ? `${customer.FirstName} ${customer.LastName}` : "--"}
            </Paragraph>
          </div>
          <div>
            <Paragraph>WIFI BOX</Paragraph>
            <Paragraph medium>{wifiBox ? wifiBox.boxNumber : "--"}</Paragraph>
          </div>
        </div>
      )}
      {linkError && <Paragraph error>Failed to link, try again.</Paragraph>}
      {linkProcessing && (
        <div className={styles.inform}>
          <Paragraph>
            <img alt="Linking" width="20" src={LoadingIcon} />
            Linking...
          </Paragraph>
        </div>
      )}
      {linkProcessed && <Paragraph success>Successfully linked</Paragraph>}

      {loadingTokenInfoProcessing && (
        <div className={styles.inform}>
          <Paragraph>
            <img alt="Loading" width="20" src={LoadingIcon} />
            Loading token info...
          </Paragraph>
        </div>
      )}
      {loadingTokenInfoError && (
        <Paragraph error>Error loading token info</Paragraph>
      )}
      {!linkProcessing &&
        !loadingTokenInfoProcessing &&
        !reservationId &&
        !wifiBoxValue && (
          <div className={styles.inform}>
            <Paragraph>
              <img alt="Scan" width="20" src={LoadingIcon} />
              Scan a wifi box or wristband to start
            </Paragraph>
          </div>
        )}
      {!linkProcessing &&
        !loadingTokenInfoProcessing &&
        !reservationId &&
        wifiBoxValue && (
          <div className={styles.inform}>
            <Paragraph>
              <img alt="Scan" width="20" src={LoadingIcon} />
              Please scan a wristband
            </Paragraph>
          </div>
        )}
      {!linkProcessing &&
        !loadingTokenInfoProcessing &&
        reservationId &&
        !wifiBoxValue && (
          <div className={styles.inform}>
            <Paragraph>
              <img alt="Scan" width="20" src={LoadingIcon} />
              Please scan a wifi box, or select one in the list
            </Paragraph>
          </div>
        )}
      <div className={styles.buttons}>
        {showLinkButton && (
          <Button
            className={styles.button}
            onClick={() =>
              link(authorizedHost.id, reservationId, wifiBoxValue, boxNumber)
            }
          >
            Link wifi box
          </Button>
        )}
        {showUnlinkButton && (
          <Button
            className={styles.button}
            onClick={() => unlink(wifiBoxValue, boxNumber, authorizedHost.id)}
          >
            Unlink wifi box
          </Button>
        )}
        {(reservationId || wifiBoxValue) && (
          <Button className={styles.button} link onClick={resetForm} simple>
            Reset
          </Button>
        )}
      </div>
    </div>
  );
};

export default WifiBoxReservationForm;
