import React, { useEffect, useContext } from "react";
import styles from "./HostList.module.scss";
import { RowRenderProps } from "react-table";
import "react-table/react-table.css";
import Button from "components/elements/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faImages, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Context as HostContext } from "../../../../store/host";
import { Context as AuthorizationContext } from "../../../../store/authorization";
import Paragraph from "components/elements/Paragraph";
import { setCurrentHost, deleteHost } from "store/actions/host"; // eslint-disable-line @typescript-eslint/no-unused-vars
import H1 from "components/elements/H1";
import Table from "components/elements/Table";
import config from "helpers/config";

interface Props {}

const getColumns = (setCurrentHost: setCurrentHost, deleteHost: deleteHost) => {
  return [
    {
      Header: "ID",
      accessor: "id",
      id: "id",
      Cell: (props: RowRenderProps) => {
        return (
          <Button
            link
            onClick={() =>
              setCurrentHost({
                hostId: props.value,
                firstName: props.row._original.FirstName,
                mewsID: props.row._original.MewsId,
                avatar: props.row._original.Picture,
                gif: props.row._original.Gif
              })
            }
          >
            {props.value}
          </Button>
        );
      }
    },
    {
      Header: "Name",
      accessor: "FirstName",
      id: "name"
    },
    {
      Header: "Picture",
      accessor: "Picture",
      id: "picture",
      Cell: (props: RowRenderProps) => {
        return (
          <Button link onClick={() => window.open(props.value, "_blank")}>
            <FontAwesomeIcon className={styles.faIcon} icon={faImage} />
          </Button>
        );
      }
    },
    {
      Header: "GIF",
      accessor: "Gif",
      id: "gif",
      Cell: (props: RowRenderProps) => {
        return (
          <Button link onClick={() => window.open(props.value, "_blank")}>
            <FontAwesomeIcon className={styles.faIcon} icon={faImages} />
          </Button>
        );
      }
    },
    {
      Header: "Wristband",
      accessor: "token",
      id: "token",
      Cell: (props: RowRenderProps) => {
        return <span>{props.value ? "yes" : "no"}</span>;
      }
    },
    {
      Header: "Admin",
      accessor: "isAdmin",
      id: "isAdmin",
      Cell: (props: RowRenderProps) => {
        return <span>{props.value ? "yes" : "no"}</span>;
      }
    },
    {
      Header: "Mews",
      accessor: "MewsId",
      id: "mewsID",
      Cell: (props: RowRenderProps) => {
        return (
          <div>
            {props.value && (
              <a
                href={`${
                  config.REACT_APP_MEWS_BASE_URL
                }/Commander/Customer/Detail/${props.value}#/properties`}
                target="_blank"
                rel="noopener noreferrer"
              >
                View in Mews
              </a>
            )}
          </div>
        );
      }
    },
    {
      Header: "Delete",
      accessor: "id",
      id: "delete",
      Cell: (props: RowRenderProps) => {
        return (
          <Button
            link
            onClick={() => {
              if (
                window.confirm("Are you sure you want to delete this host?")
              ) {
                deleteHost(props.value);
              }
            }}
          >
            <FontAwesomeIcon className={styles.faIcon} icon={faTrash} />
          </Button>
        );
      }
    }
  ];
};

const HostList: React.FC<Props> = props => {
  const { state: hostState, load, setCurrentHost, deleteHost } = useContext(
    HostContext
  );
  const { state: authorizationState } = useContext(AuthorizationContext);
  const { records, requests } = hostState;
  const { authorizedHost, authorizedHotelCode } = authorizationState;

  const loadingListError = requests.collection === "REQUEST_FAILED";
  useEffect(() => {
    if (!authorizedHost) {
      return;
    }
    load(authorizedHost.isAdmin);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!authorizedHost) {
    return null;
  }

  const loadingList = requests.collection === "REQUEST_ACTIVE";

  const data = Object.keys(records)
    .map((id: string) => records[id])
    .filter(item => item.DefaultHotelCode === authorizedHotelCode);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div>
          <H1>All CityHosts</H1>
        </div>
        <div>
          <Button onClick={() => load(authorizedHost.isAdmin)}>
            Refresh list
          </Button>
        </div>
      </div>
      {loadingListError && <Paragraph error>Error loading hosts</Paragraph>}
      <Table
        data={data}
        defaultSorted={[{ id: "name", desc: false }]}
        defaultPageSize={100}
        showPageSizeOptions={false}
        minRows={20}
        columns={getColumns(setCurrentHost, deleteHost)}
        loading={loadingList}
        getTdProps={() => ({
          style: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }
        })}
      />
    </div>
  );
};

export default HostList;
