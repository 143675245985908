import React, { useContext, useEffect, useState } from "react";
import styles from "./ScanWristband.module.scss";
import Sidebar from "components/layouts/Sidebar";
import Paragraph from "components/elements/Paragraph";
import { Context as AuthorizationContext } from "../../../store/authorization";
import { getWebSocketClient } from "services/webSocket";
import { hotelApi } from "services/api";
import Button from "components/elements/Button";
import { getMewsCustomerByTokenId } from "helpers/mews";

interface Props {}

const ScanWristband: React.FC<Props> = (props) => {
  const { state: authorizationState } = useContext(AuthorizationContext);
  const { authorizedHotelCode } = authorizationState;

  const [guest, setGuest] = useState({
    FirstName: "",
    LastName: "",
    ReservationId: "",
    Number: "",
    Hub: "",
    ItemsResult: "",
  });

  // const getMewsCustomerByTokenId = (TokenId: string) => {
  //   try {
  //     hotelApi({
  //       path: "/reservation/token/" + TokenId,
  //       params: {},
  //     }).then((token) => {
  //       hotelApi({
  //         path: "/reservation/byId",
  //         params: {
  //           ReservationId: token.Token.ReservationId,
  //           Encoder: token.Token.Encoder,
  //         },
  //       }).then((reservation) => {
  //         const reservationInfo = reservation.Reservations.find(
  //           (r: any) => r.Id === token.Token.ReservationId
  //         );

  //         hotelApi({
  //           path: "/reservation/customer/info",
  //           params: {
  //             CustomerId: token.Token.CustomerId,
  //           },
  //         }).then((info) => {
  //           const customerInfo = info.Customers.find(
  //             (c: any) => c.Id === token.Token.CustomerId
  //           );
  //           setGuest({
  //             ...guest,
  //             FirstName: customerInfo.FirstName ?? "",
  //             LastName: customerInfo.LastName ?? "",
  //             ReservationId: reservationInfo.Id ?? "",
  //             Number: reservationInfo.Number ?? "",
  //             Hub: reservation.Hub.Number ?? "",
  //             ItemsResult: reservation.ItemsResult ?? "",
  //           });
  //         });
  //       });
  //     });
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };

  const subscribeToScanner = (hotelCode: string) => {
    const onMessage = async (message: { TokenId?: string }) => {
      if (!("TokenId" in message)) {
        return;
      }

      const getGuest = await getMewsCustomerByTokenId(
        message.TokenId as string
      );

      if (getGuest) {
        setGuest({
          ...guest,
          FirstName: getGuest.FirstName,
          LastName: getGuest.LastName,
          ReservationId: getGuest.ReservationId,
          Number: getGuest.Number,
          Hub: getGuest.Hub,
          ItemsResult: getGuest.ItemsResult,
        });
      }
    };

    const socket = getWebSocketClient(hotelCode);
    socket.onmessage = (event) => {
      console.log("Received message from websocket", event);
      const message = JSON.parse(event.data);
      console.log("Passing message", message);
      onMessage(message);
    };
  };

  const unsubscribeFromScanner = (hotelCode: string) => {
    const socket = getWebSocketClient(hotelCode);
    socket.close();
  };

  /**
   * Dry run token without reader
   */
  // useEffect(() => {
  //   const fetchGuest = async () => {
  //     const getGuest = await getMewsCustomerByTokenId("0440D6B2DA5180");
  //     console.log(getGuest);

  //     if (getGuest) {
  //       setGuest({
  //         ...guest,
  //         FirstName: getGuest.FirstName,
  //         LastName: getGuest.LastName,
  //         ReservationId: getGuest.ReservationId,
  //         Number: getGuest.Number,
  //         Hub: getGuest.Hub,
  //         ItemsResult: getGuest.ItemsResult,
  //       });
  //     }
  //   };
  //   // call the function
  //   fetchGuest()
  //     // make sure to catch any error
  //     .catch(console.error);
  // }, []);

  useEffect(() => {
    if (authorizedHotelCode) {
      subscribeToScanner(authorizedHotelCode);
    }

    return () => {
      if (authorizedHotelCode) {
        unsubscribeFromScanner(authorizedHotelCode);
      }
    };
  }, [authorizedHotelCode]); // eslint-disable-line react-hooks/exhaustive-deps

  const enableScanner = () => {
    if (authorizedHotelCode) {
      subscribeToScanner(authorizedHotelCode);
    }

    return () => {
      if (authorizedHotelCode) {
        unsubscribeFromScanner(authorizedHotelCode);
      }
    };
  };

  return (
    <div className={styles.wrapper}>
      <Sidebar activeLink="wristband" />
      <div className={styles.contentWrapper}>
        <div className={styles.header}>
          <Paragraph medium strong>
            Wristband
          </Paragraph>
        </div>
        <div className={styles.content}>
          <div className={styles.buttons}>
            <div>
              <Button onClick={() => enableScanner()}>Enable scanner</Button>
            </div>
          </div>
          Name: {guest.FirstName} {guest.LastName}
          <br />
          ReservationId:{" "}
          <a
            href={`https://app.mews.com/Commander/Reservation/Detail/${guest.ReservationId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {guest.ReservationId}
          </a>
          <br />
          Number: {guest.Number}
          <br />
          Hub: {guest.Hub}
          <br />
          Open items: {guest.ItemsResult}
          <br />
        </div>
      </div>
    </div>
  );
};

export default ScanWristband;
