import axios, { AxiosRequestConfig } from "axios";
import config from "helpers/config";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response;

    return Promise.reject(status);
  }
);

export const invalidSessionErrorCodes = [401, 403];

interface ApiInterface extends AxiosRequestConfig {
  path: string;
}

let sessionToken = "";
let hotelApiKey = "";

export const setMobileApiSessionToken = (token: string) => {
  sessionToken = token;
};

export const setHotelApiKey = (apiKey: string) => {
  hotelApiKey = apiKey;
};

export const mobileApi = async ({
  method = "get",
  path,
  data,
  params = {},
}: ApiInterface) => {
  if (sessionToken) {
    params.SessionToken = sessionToken;
  }

  return axios({
    method,
    url: `${config.REACT_APP_MOBILE_API_ENDPOINT}${path}`,
    data,
    params,
  }).then((response) => response.data.result);
};

export const hotelApi = async ({
  method = "get",
  path,
  data,
  params = {},
}: ApiInterface) => {
  return axios({
    method,
    url: `${config.REACT_APP_HOTEL_API_ENDPOINT}${path}`,
    data,
    params,
    headers: {
      "x-api-key": hotelApiKey,
    },
  }).then((response) => response.data.result);
};

export const fcmApi = async ({
  method = "post",
  data,
  params = {},
}: AxiosRequestConfig) => {
  let authorizedData = null;
  const storageData = localStorage.getItem("console-authorized");

  if (storageData) {
    authorizedData = JSON.parse(storageData);
  }
  if (authorizedData && authorizedData?.authorizedCredentials?.Firebase) {
    const url =
      authorizedData.authorizedCredentials.Firebase.REACT_APP_FCM_API_ENDPOINT;
    const api_key =
      authorizedData.authorizedCredentials.Firebase.REACT_APP_FCM_API_KEY;
    return axios({
      method,
      url: `${url}`,
      data,
      params,
      headers: {
        Authorization: "Key=" + `${api_key}`,
      },
    })
      .then((response) => response.data.result)
      .catch((error) => console.log(error.message));
  }
};
