import React from "react";
import styles from "./SystemMessage.module.scss";
import { SystemMessage as SystemMessageInterface } from "store/system";
import Paragraph from "components/elements/Paragraph";
import Avatar from "components/elements/Avatar";
import ArrowUpIcon from "assets/svg/pullup.svg";
import ArrowDownIcon from "assets/svg/pulldown.svg";
import Button from "components/elements/Button";
import { getCountryNameFromCode } from "assets/data/countries";
import Tag from "components/elements/Tag";
import { fromNowOrNow } from "helpers/date";
import { Reservation, Customer } from "store/reservation";
import config from "helpers/config";
import { firestore } from "services/firebase";
import { updateKioskPlaces } from "helpers/firebase";
import { hotelCode } from "store/interface/chat";

interface Props {
  message: SystemMessageInterface;
  expanded: boolean;
  toggleExpanded: (expanded: boolean) => void;
  hotelCode: string | null;
  // addWifiBox: (
  //   reservation: Reservation,
  //   customers: Customer[],
  //   hub: string
  // )
  // => void;
}

const SystemMessage: React.FC<Props> = (props) => {
  const {
    message,
    expanded,
    toggleExpanded,
    hotelCode,
    // addWifiBox
  } = props;

  return (
    <div key={message.timetoken} className={styles.wrapper}>
      <div className={styles.header} onClick={() => toggleExpanded(!expanded)}>
        <div>
          <Avatar
            alt={message.reservation.Hub}
            number={message.reservation.Hub}
            type={
              message.MessageType === "SYSTEM_MESSAGE_CHECKIN"
                ? "default"
                : message.MessageType === "SYSTEM_MESSAGE_CHECKOUT"
                ? "alternative"
                : message.MessageType === "SYSTEM_MESSAGE_CHECKINGOUT"
                ? "secondary"
                : message.MessageType === "SYSTEM_MESSAGE_CHECKGUEST"
                ? "special"
                : undefined
            }
            size="medium"
          />
        </div>
        <div>
          <Paragraph medium>
            {message.reservation.Customers.map(
              (customer) => customer.FirstName
            ).join(" & ")}{" "}
            {message.MessageType === "SYSTEM_MESSAGE_CHECKIN"
              ? "checked in"
              : message.MessageType === "SYSTEM_MESSAGE_CHECKOUT"
              ? "checked out"
              : message.MessageType === "SYSTEM_MESSAGE_CHECKINGOUT"
              ? "checking out"
              : message.MessageType === "SYSTEM_MESSAGE_CHECKGUEST"
              ? "at front desk"
              : ""}
          </Paragraph>
          {expanded && message.reservation.thisReservationNights > 6 && (
            <Paragraph className={styles.titleTags}>
              <Tag>LIVE FLEX!</Tag>
            </Paragraph>
          )}
          <Paragraph small className={styles.time}>
            {fromNowOrNow(message.timetoken)}
          </Paragraph>
        </div>
        <img
          alt={expanded ? "Show less" : "Show more"}
          className={styles.toggle}
          src={expanded ? ArrowUpIcon : ArrowDownIcon}
        />
      </div>
      {expanded && (
        <div className={styles.extraWrapper}>
          {message.reservation.Customers.map((customer) => (
            <div key={customer.Id} className={styles.person}>
              <Avatar
                src={customer.Picture}
                alt={customer.FirstName}
                size="medium"
              />
              <div>
                {customer.Title && (
                  <Paragraph small>{customer.Title}</Paragraph>
                )}
                <Paragraph>
                  {customer.FirstName} {customer.LastName}{" "}
                  {customer.Classifications.indexOf("Returning") !== -1 && (
                    <Tag contrast>🔄</Tag>
                  )}
                  {customer.Classifications.indexOf("LoyaltyProgram") !==
                    -1 && <Tag contrast>🧡</Tag>}
                </Paragraph>
                <Paragraph small>
                  {getCountryNameFromCode(customer.NationalityCode)}
                </Paragraph>
              </div>
            </div>
          ))}
          {message.reservation.thisReservation.Notes.map((note, index) => {
            if (
              note.Text.includes("payment_on_Booking.com") ||
              note.Text.includes("(Agoda Collect)")
            ) {
              return "";
            } else {
              return <Paragraph key={index}>{note.Text}</Paragraph>;
            }
          })}
          <div className={styles.meta}>
            <Paragraph>
              <span role="img" aria-label="Nights">
                💤
              </span>{" "}
              {message.reservation.thisReservationNights}
            </Paragraph>
            {message.reservation.thisReservationBikes > -1 && (
              <Paragraph>
                <span role="img" aria-label="Bikes">
                  🚲
                </span>{" "}
                {message.reservation.thisReservationBikes}
              </Paragraph>
            )}
            <div
              className={styles.toPlaces}
              onClick={() =>
                updateKioskPlaces(
                  hotelCode,
                  message.reservation.thisReservation.Id
                )
              }
              title="Show guest on CityHub Places"
            >
              🗺️
            </div>
            <Paragraph>{message.reservation.thisReservationHubType}</Paragraph>
            <Paragraph>
              <a
                href={`${config.REACT_APP_MEWS_BASE_URL}/Commander/Reservation/Detail/${message.reservation.thisReservation.Id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {message.reservation.thisReservation.Number}
              </a>
            </Paragraph>
            {message.reservation.thisReservation.HasBreakfast && (
              <Paragraph>
                <span role="img" aria-label="Has breakfast">
                  🍞
                </span>
              </Paragraph>
            )}
          </div>
          {/* {message.MessageType === "SYSTEM_MESSAGE_CHECKIN" && (
            <Button
              // to={{
              //   pathname: `/wifiBox`,
              //   state: {
              //     reservation: {
              //       ...message.reservation.thisReservation,
              //       Hub: message.reservation.Hub
              //     },
              //     customers: message.reservation.Customers
              //   }
              // }}
              onClick={() =>
                addWifiBox(
                  message.reservation.thisReservation,
                  message.reservation.Customers,
                  message.reservation.Hub
                )
              }
            >
              Add wifi box
            </Button>
          )} */}
        </div>
      )}
    </div>
  );
};

export default SystemMessage;
