import React, { useContext } from "react";
import styles from "./Notifications.module.scss";
import { Context as NotificationContext } from "../../../store/notification";
import Paragraph from "../Paragraph";
import CloseIcon from "assets/svg/close-green.svg";

interface Props {}

const Notifications: React.FC<Props> = props => {
  const { state: notificationState, hideNotification } = useContext(
    NotificationContext
  );
  const { notification, showNotification } = notificationState;
  const wrapperClassNames = [
    styles.wrapper,
    showNotification && notification ? styles.wrapperShow : styles.wrapperHide
  ];

  if (!notification) {
    return null;
  }

  return (
    <div className={wrapperClassNames.join(" ")}>
      <Paragraph>{notification ? notification.title : "Test"}</Paragraph>
      <img
        className={styles.close}
        alt="Close"
        src={CloseIcon}
        onClick={() => hideNotification()}
      />
    </div>
  );
};

export default Notifications;
