import React, { useContext } from "react";
import Paragraph from "components/elements/Paragraph";
import { Context, AuthorizationContext } from "../../../store/authorization";
import Button from "components/elements/Button";
import styles from "./Unauthorized.module.scss";

const Unauthorized = () => {
  const { signOut } = useContext<AuthorizationContext>(Context);

  return (
    <div className={styles.container}>
      <Paragraph error>You are not authorized to view this page</Paragraph>
      <Button onClick={signOut}>Sign out</Button>
    </div>
  );
};

export default Unauthorized;
