import { Action, ActionType } from "./types";
import { mobileApi } from "services/api";
import { Reservation } from "store/reservation";

interface fetchActiveReservations {
  (hotelCode: string): void;
}

export interface ReservationActionTypes {
  fetchActiveReservations: fetchActiveReservations;
}

export const actionTypes = {
  RESERVATIONS_FETCHING: "reservation/RESERVATIONS_FETCHING",
  RESERVATIONS_FETCHING_ERROR: "reservation/RESERVATIONS_FETCHING_ERROR",
  RESERVATIONS_FETCHED: "reservation/RESERVATIONS_FETCHED",
  CUSTOMERS_FETCHED: "reservation/CUSTOMERS_FETCHED"
};

const fetchActiveReservations: Action = (
  dispatch
): fetchActiveReservations => async hotelCode => {
  dispatch({
    type: actionTypes.RESERVATIONS_FETCHING
  });

  try {
    const response = await mobileApi({
      path: "/host/activeReservations",
      params: {
        HotelCode: hotelCode
      }
    });

    let reservations: Reservation[] =
      "Reservations" in response ? response.Reservations : [];
    const notes: Reservation["Notes"] =
      "Notes" in response ? response.Notes : [];
    reservations = reservations.map(reservation => {
      reservation.Notes = notes
        ? notes.filter(note => note.OrderId === reservation.Id)
        : [];
      return reservation;
    });

    dispatch({
      type: actionTypes.RESERVATIONS_FETCHED,
      reservations
    });
    dispatch({
      type: actionTypes.CUSTOMERS_FETCHED,
      customers: ("AppUsers" in response ? response.AppUsers : []).map(
        (customer: any) => ({ ...customer, Id: customer.CustomerId })
      )
    });
  } catch (errorCode) {
    dispatch({
      type: actionTypes.RESERVATIONS_FETCHING_ERROR,
      errorCode
    });
  }
};

const actions = (
  dispatch: React.Dispatch<ActionType>
): ReservationActionTypes => ({
  fetchActiveReservations: fetchActiveReservations(dispatch)
});

export default actions;
